import React from 'react'
import { Card, Button, Typography } from 'antd'
import BadgeGenerator from '../../utils/badgeGenerator'

const { Text, Title } = Typography

const WantedItemCard = ({
  _id,
  title,
  mainImageUris = [],
  targetPrice,
  createdAt,
  inspectionStatus,
  onApply = () => {},
}) => {
  const inspectionBadgeOption = BadgeGenerator.getInspectionBadge({
    inspectionStatus,
  })
  const mainImage =
    mainImageUris.length >= 2
      ? mainImageUris[1]
      : mainImageUris.length === 1
      ? mainImageUris[0]
      : null
  const formattedDate = new Date(createdAt).toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  const formattedTitle = title?.replace(/^곰곰\s*/, '').trim()

  const cardCover = mainImage ? (
    <div className='relative h-[180px] overflow-hidden'>
      <img alt={title} src={mainImage} className='w-full h-full object-cover' />
    </div>
  ) : (
    <div className='h-[200px] flex items-center justify-center bg-gray-100'>
      <Text type='secondary'>이미지 없음</Text>
    </div>
  )

  return (
    <Card
      hoverable
      className='w-[240px]'
      cover={cardCover}
      actions={[
        <Button
          type='link'
          disabled={
            inspectionStatus === 'pending' || inspectionStatus === 'completed'
          }
          onClick={() => onApply(_id)}
        >
          연동 신청하기
        </Button>,
      ]}
    >
      <div className='space-y-2 min-h-[90px]'>
        <div
          className={`z-10 top-0 right-0 px-2 py-0.5 items-center justify-center flex text-xs text-white rounded-full ${inspectionBadgeOption.className}`}
        >
          {inspectionBadgeOption.text}
        </div>
        <Title level={5} ellipsis={{ rows: 2 }} className='mb-2 min-h-[48px]'>
          {formattedTitle}
        </Title>
        <Text type='secondary' className='text-sm block'>
          등록일: {formattedDate}
        </Text>
        {targetPrice ? (
          <div className='mt-2'>
            <span className='bg-orange-100 text-orange-600 px-2 py-1 rounded text-sm'>
              희망가격: {targetPrice.toLocaleString()}원
            </span>
          </div>
        ) : (
          <div className='mt-2'>가격무관</div>
        )}
      </div>
    </Card>
  )
}

export default WantedItemCard
