import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { Input, DatePicker, Pagination, message, Checkbox } from 'antd'
import { LeftOutlined, DeleteOutlined } from '@ant-design/icons'
import backendApis from '../../../utils/backendApis'
import RowComponent from './components/RowComponent'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import ItemSearchBox from '../../../components/molecules/itemSearchBox'
import CandidateItemCard from './components/CandidateItemCard'
import resize from '../../../utils/resize'
import Button from '../../../components/atoms/button'
import AdInfoModal from './components/AdInfoModal'

dayjs.extend(customParseFormat)

const today = moment().format('YYYY-MM-DD')
const initialState = {
  campaignId: '',
  campaignName: `캠페인_${today}`,
  adSetId: '',
  adSetName: `광고세트_${today}`,
  adSetStartDate: today,
  adSetEndDate: null,
  adSetDailyBudget: 200000,
  adSetBiddingPrice: 120,
}

const AlfarmClickAdApplyScreen = () => {
  const history = useHistory()
  const location = useLocation()
  const receivedInfo = location.state
  const method = receivedInfo?.method || 'NEW_CAMPAIGN'

  const [isLoading, setIsLoading] = useState(false)
  const [adInfo, setAdInfo] = useState(initialState)
  const [noEndDate, setNoEndDate] = useState(true)
  const [candidateItems, setCandidateItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [existAdUnits, setExistAdUnits] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [showAdInfoModal, setShowAdInfoModal] = useState(false)
  const [modalText, setModalText] = useState('')

  let title = '신규 광고 신청'
  if (method === 'NEW_AD_SET') {
    title = '광고세트 추가'
  }
  if (method === 'EDIT_AD_SET') {
    title = '광고세트 수정'
  }

  const updateAdInfo = (key, value) => {
    setAdInfo({ ...adInfo, [key]: value })
  }

  const disabledStartDate = (current) => {
    const threeMonthsLater = dayjs().add(3, 'month').endOf('day')
    return (
      current < dayjs().subtract(1, 'day').endOf('day') ||
      current > threeMonthsLater
    )
  }

  const disabledEndDate = (current) => {
    const threeMonthsLater = dayjs().add(3, 'month').endOf('day')
    if (adInfo?.adSetStartDate) {
      return (
        current < dayjs(adInfo.adSetStartDate).startOf('day') ||
        current > threeMonthsLater
      )
    }
    return (
      current < dayjs().subtract(1, 'day').endOf('day') ||
      current > threeMonthsLater
    )
  }

  const createAdUnitChanges = () => {
    const existAdUnitsMap = new Map(
      existAdUnits?.map((item) => [item.itemId, item]),
    )

    const adUnitChanges = selectedItems?.map((item) => {
      if (existAdUnitsMap.has(item.itemId)) {
        existAdUnitsMap.delete(item.itemId)
        return { ...item, biddingPrice: adInfo?.adSetBiddingPrice }
      }
      return { ...item, isNew: true, biddingPrice: adInfo?.adSetBiddingPrice }
    })

    existAdUnitsMap.forEach((item) => {
      adUnitChanges.push({ adUnitId: item.adUnitId, isDeleted: true })
    })

    return adUnitChanges
  }

  const handleAdSubmit = async () => {
    if (isLoading) return
    setIsLoading(true)

    // 유효성 검사
    const validationError = validateAdInfo()
    if (validationError) {
      setModalText(validationError)
      setShowAdInfoModal(true)
      setIsLoading(false)
      return
    }

    let editAdInfo
    let resultMessage
    let result

    switch (method) {
      default:
        break

      case 'NEW_CAMPAIGN':
        editAdInfo = {
          campaignName: adInfo.campaignName,
          campaignType: 'ALFARM_CLICK',
          adSets: [
            {
              name: adInfo.adSetName,
              dailyBudget: adInfo.adSetDailyBudget,
              biddingPrice: adInfo.adSetBiddingPrice,
              startDate: adInfo.adSetStartDate,
              endDate: noEndDate ? null : adInfo?.adSetEndDate || null,
              adUnits: selectedItems.map((item) => ({
                itemId: item.itemId,
                itemImageUri: item.itemImageUri,
                itemIndex: item.itemIndex,
                itemTitle: item.itemTitle,
                biddingPrice: adInfo?.adSetBiddingPrice,
              })),
            },
          ],
        }
        resultMessage = '광고 신청이 완료되었습니다.'
        result = await backendApis.createAdCampaign(editAdInfo)
        break

      case 'NEW_AD_SET':
        editAdInfo = {
          adSets: [
            {
              isNew: true,
              name: adInfo.adSetName,
              dailyBudget: adInfo.adSetDailyBudget,
              biddingPrice: adInfo.adSetBiddingPrice,
              startDate: adInfo.adSetStartDate,
              endDate: noEndDate ? null : adInfo?.adSetEndDate || null,
              adUnits: selectedItems.map((item) => ({
                isNew: true,
                itemId: item.itemId,
                itemImageUri: item.itemImageUri,
                itemIndex: item.itemIndex,
                itemTitle: item.itemTitle,
                biddingPrice: adInfo?.adSetBiddingPrice,
              })),
            },
          ],
        }
        resultMessage = '광고세트 추가가 완료되었습니다.'
        result = await backendApis.updateAdCampaign(
          adInfo.campaignId,
          editAdInfo,
        )
        break

      case 'EDIT_AD_SET':
        editAdInfo = {
          adSets: [
            {
              adSetId: adInfo?.adSetId,
              name: adInfo?.adSetName,
              dailyBudget: adInfo?.adSetDailyBudget,
              biddingPrice: adInfo?.adSetBiddingPrice,
              startDate: adInfo?.adSetStartDate,
              endDate: noEndDate ? null : adInfo?.adSetEndDate || null,
              adUnits: createAdUnitChanges(),
            },
          ],
        }
        resultMessage = '광고세트 수정이 완료되었습니다.'
        result = await backendApis.updateAdCampaign(
          adInfo.campaignId,
          editAdInfo,
        )
        break
    }

    if (result?.status === 200) {
      history.push('/ad/alfarm-click-ad')
      message.success(resultMessage)
    }

    setIsLoading(false)
  }

  // 유효성 검사 함수
  const validateAdInfo = () => {
    if (method === 'NEW_CAMPAIGN' && adInfo?.campaignName === '') {
      return '캠페인명을 입력해주세요.'
    }

    if (adInfo?.adSetName === '') {
      return '광고세트명을 입력해주세요.'
    }

    if (adInfo?.adSetStartDate === null || adInfo?.adSetStartDate === '') {
      return '광고 캠페인 시작일을 선택해주세요.'
    }

    if (adInfo?.adSetEndDate && !noEndDate) {
      const startDate = dayjs(adInfo.adSetStartDate)
      const endDate = dayjs(adInfo.adSetEndDate)
      if (endDate.isBefore(startDate)) {
        return '종료일은 시작일보다 이후여야 합니다.'
      }
    }

    if (
      !(
        adInfo?.adSetDailyBudget >= 10000 &&
        adInfo?.adSetDailyBudget <= 100000000
      )
    ) {
      return '일 예산은 1만원 이상 1억원 이하로 입력해주세요.'
    }

    if (
      !(adInfo?.adSetBiddingPrice >= 100 && adInfo?.adSetBiddingPrice <= 10000)
    ) {
      return '입찰가는 100원 이상 1만원 이하로 입력해주세요.'
    }

    if (selectedItems?.length === 0) {
      return '광고할 상품을 1개 이상 선택해주세요.'
    }

    if (selectedItems?.length > 50) {
      return '선택된 상품이 너무 많습니다. 광고할 상품을 50개 이하로 선택해주세요.'
    }

    return null // 유효성 검사 통과
  }

  const handleItemSelect = (item) => {
    const selectedItemsIds = selectedItems.map((e) => e.itemId)
    if (selectedItemsIds.includes(item._id)) {
      setSelectedItems(selectedItems.filter((e) => e.itemId !== item._id))
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          itemTitle: item.itemTitle,
          itemImageUri: item?.mainImageUris?.[0],
          itemId: item._id,
          itemIndex: item.itemIndex,
        },
      ])
    }
  }

  const setupAdInfo = async () => {
    if (isLoading) return
    setIsLoading(true)

    if (method === 'NEW_AD_SET') {
      updateAdInfo('campaignId', receivedInfo?.campaignId)
    } else if (method === 'EDIT_AD_SET') {
      const adSetInfo = receivedInfo?.adSetInfo
      const receivedAdInfo = {
        campaignId: adSetInfo?.campaignId,
        adSetId: adSetInfo?._id,
        adSetName: adSetInfo?.name,
        adSetStartDate: adSetInfo?.startDate,
        adSetEndDate: adSetInfo?.endDate || null,
        adSetDailyBudget: parseInt(adSetInfo?.dailyBudget, 10),
        adSetBiddingPrice: parseInt(adSetInfo?.biddingPrice, 10),
      }
      setAdInfo(receivedAdInfo)
      setNoEndDate(adSetInfo?.endDate == null)

      const adSetAdUnitsInfo =
        await backendApis.getAlfarmClickAdUnitsSelectedAdSet(adSetInfo?._id)
      if (adSetAdUnitsInfo?.status === 200) {
        const adSetAdUnits = adSetAdUnitsInfo?.data?.adUnits?.map((item) => ({
          itemTitle: item?.itemTitle,
          itemImageUri: item?.itemImageUri,
          itemId: item?.itemId,
          itemIndex: item?.itemIndex,
          biddingPrice: parseInt(item?.biddingPrice, 10),
          adUnitId: item?._id,
        }))
        setSelectedItems(adSetAdUnits)
        setExistAdUnits(adSetAdUnits)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setupAdInfo()
  }, [])

  return (
    <div className='w-[85vw] pl-10 pr-20 py-10 mt-10 max-w-[1640px]'>
      {/* 캠페인 입력 */}
      <div className='mb-5 text-3xl font-bold select-none'>
        올팜 클릭 광고 - {title}
      </div>
      {title === '신규 광고 신청' && (
        <div className='flex flex-col rounded-2xl p-8 bg-white min-w-[405px] mb-3'>
          <RowComponent title='캠페인명' className='mb-4'>
            <Input
              showCount
              maxLength={50}
              onChange={(e) => updateAdInfo('campaignName', e.target.value)}
              placeholder='캠페인명을 입력해주세요'
              value={adInfo?.campaignName}
              size='large'
              status={
                adInfo?.campaignName == null || adInfo?.campaignName !== ''
                  ? ''
                  : 'error'
              }
            />
          </RowComponent>
          <RowComponent title='캠페인 목표'>
            <div className='flex flex-row items-center w-fit'>
              <div className='text-base font-semibold text-[#464953] mr-1'>
                올팜 클릭 광고
              </div>
              <div className='text-[#464953] font-sm'>- 판매량 증대</div>
            </div>
          </RowComponent>
        </div>
      )}

      {/* 광고세트 입력 */}
      <div className='flex flex-col rounded-2xl p-8 bg-white min-w-[405px] mb-3'>
        <RowComponent title='광고세트명' className='mb-4'>
          <Input
            key='adSetName'
            showCount
            maxLength={50}
            onChange={(e) => updateAdInfo('adSetName', e.target.value)}
            placeholder='광고세트명을 입력해주세요'
            value={adInfo?.adSetName}
            size='large'
            status={adInfo?.adSetName == null ? 'error' : ''}
          />
        </RowComponent>
        <RowComponent title='기간' className='mb-4'>
          <div className='flex flex-row'>
            <div className='flex flex-col flex-1 mr-4'>
              <div className='text-base text-[#30333D] mb-2'>시작일</div>
              <DatePicker
                disabledDate={disabledStartDate}
                size='large'
                onChange={(date, dateString) =>
                  updateAdInfo('adSetStartDate', dateString)
                }
                placeholder='시작일'
                value={
                  adInfo?.adSetStartDate ? dayjs(adInfo?.adSetStartDate) : null
                }
                status={
                  adInfo?.adSetStartDate == null ||
                  adInfo?.adSetStartDate === ''
                    ? 'error'
                    : ''
                }
              />
            </div>

            <div className='flex flex-col flex-1'>
              <div className='flex flex-row justify-between'>
                <div className='text-base text-[#30333D] mb-2'>종료일</div>
                <div className='flex flex-row items-start ml-1'>
                  <Checkbox
                    checked={noEndDate}
                    onChange={(e) => setNoEndDate(e.target.checked)}
                  />
                  <div className='ml-1'>종료일 없음</div>
                </div>
              </div>
              <DatePicker
                disabled={noEndDate}
                disabledDate={disabledEndDate}
                size='large'
                onChange={(date, dateString) =>
                  updateAdInfo('adSetEndDate', dateString)
                }
                placeholder='종료일'
                value={
                  adInfo?.adSetEndDate ? dayjs(adInfo?.adSetEndDate) : null
                }
              />
            </div>
          </div>
        </RowComponent>
        <RowComponent title='일 예산' className='mb-4'>
          <Input
            placeholder='1만원 이상 1억원 이하로 설정해주세요.'
            size='large'
            type='number'
            min={10000}
            step={5000}
            max={100000000}
            suffix='원'
            onChange={(e) => updateAdInfo('adSetDailyBudget', e.target.value)}
            value={adInfo?.adSetDailyBudget}
            status={
              adInfo?.adSetDailyBudget == null ||
              (adInfo?.adSetDailyBudget >= 10000 &&
                adInfo?.adSetDailyBudget <= 100000000)
                ? ''
                : 'error'
            }
          />
        </RowComponent>
        <RowComponent title='입찰가'>
          <Input
            placeholder='100원 이상 1만원 이하로 입찰가를 입력해주세요'
            size='large'
            type='number'
            min={100}
            step={10}
            max={10000}
            suffix='원'
            onChange={(e) => updateAdInfo('adSetBiddingPrice', e.target.value)}
            value={adInfo?.adSetBiddingPrice}
            status={
              adInfo?.adSetBiddingPrice == null ||
              (adInfo?.adSetBiddingPrice >= 100 &&
                adInfo?.adSetBiddingPrice <= 10000)
                ? ''
                : 'error'
            }
          />
        </RowComponent>
      </div>

      {/* 상품 선택 */}
      <div className='flex flex-row mb-3'>
        <div className='flex flex-col flex-1 max-w-[640px] min-w-[440px] items-start rounded-2xl p-8 bg-white mr-4'>
          <div className='mb-4 text-lg font-bold'>광고할 상품 선택</div>
          <ItemSearchBox
            page={page}
            setPage={setPage}
            limit={6}
            onSearch={(e) => setCandidateItems(e)}
            setTotalCount={setTotalCount}
            initialSearch
          />
          <div className='flex flex-col flex-1 w-full mt-4'>
            {candidateItems.map((item) => (
              <CandidateItemCard
                key={item._id}
                itemInfo={item}
                isSelected={selectedItems
                  .map((e) => e.itemId)
                  .includes(item._id)}
                onSelect={() => handleItemSelect(item)}
              />
            ))}

            <Pagination
              className='flex flex-row items-center justify-center flex-1 mt-8'
              total={totalCount}
              pageSize={6}
              current={page}
              showSizeChanger={false}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>

        <div className='flex flex-col flex-1 rounded-2xl p-8 bg-white min-w-[400px]'>
          <div className='flex flex-row items-center mb-4'>
            <div className='text-lg font-bold'>선택한 상품</div>
            {selectedItems?.length > 0 && (
              <div
                className='text-[#5D5F68] font-semibold ml-3'
                key='selected-items'
              >
                {selectedItems?.length}개 선택
              </div>
            )}
          </div>

          <div className='flex flex-col max-h-[880px] overflow-y-auto'>
            {selectedItems?.length > 0 &&
              selectedItems.map((item) => {
                return (
                  <div
                    className='flex flex-row items-center flex-1 mb-10'
                    key={item.itemId}
                  >
                    <div className='flex flex-row flex-1'>
                      {item.itemImageUri && item.itemImageUri !== '' ? (
                        <img
                          alt='itemImage'
                          src={resize(item.itemImageUri)}
                          className='w-[84px] h-[84px] mr-3 rounded-lg border border-[#000000F]'
                        />
                      ) : (
                        <div className='w-[84px] h-[84px] mr-3 rounded-lg border bg-gray-100 border-[#000000F]' />
                      )}
                      <div className='flex flex-col'>
                        <div className='text-lg font-semibold'>
                          {item.itemTitle}
                        </div>
                        <div className='mb-0.5 text-base text-[#9C9DA4]'>
                          {item.itemId}
                        </div>
                        {/* <Input
                          placeholder='입찰가를 입력해주세요'
                          size='middle'
                          Type='number'
                          suffix='원'
                          onChange={onChange}
                          // status='error'
                        /> */}
                      </div>
                    </div>
                    <button
                      type='button'
                      className='px-2 py-1 ml-2 text-gray-500 bg-gray-100 rounded-lg hover:bg-gray-200'
                      onClick={() => {
                        setSelectedItems(
                          selectedItems.filter(
                            (selectedItem) =>
                              selectedItem.itemId !== item.itemId,
                          ),
                        )
                      }}
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      {/* CTA 버튼 */}
      <div className='flex flex-row items-center justify-end'>
        <Button
          onClick={() => {
            history.goBack()
          }}
          appearance='subBlue'
          size='md'
          className='px-6 py-3 mr-2.5 leading-5 whitespace-nowrap min-w-[140px]'
        >
          <LeftOutlined className='mr-1' />
          이전
        </Button>
        <Button
          onClick={handleAdSubmit}
          appearance='blueV2'
          size='md'
          className='px-6 py-3 leading-5 whitespace-nowrap min-w-[140px]'
        >
          신청하기
        </Button>
      </div>

      <AdInfoModal
        showModal={showAdInfoModal}
        setShowModal={setShowAdInfoModal}
        title='광고 집행을 위해 필요한 정보를 입력해주세요'
        text={modalText}
      />
    </div>
  )
}
export default AlfarmClickAdApplyScreen
