import moment from 'moment'
import 'moment-timezone'

const {
  REACT_APP_NODE_ENV_USER,
  REACT_APP_BACKEND_URL_DEVELOPMENT,
  REACT_APP_BACKEND_URL_PRODUCTION,
  REACT_APP_PRICE_BACKEND_URL_DEVELOPMENT,
  REACT_APP_PRICE_BACKEND_URL_PRODUCTION,
  REACT_APP_SENTRY_DSN,
} = process.env

const configs = {
  nodeEnv: null,
  backendUrl: null,
  priceBackendUrl: null,
  sentryDSN: null,
  getDefaultCommissionRate: () =>
    moment().tz('Asia/Seoul').year() >= 2025 ? 0.05 : 0.035,
}
configs.sentryDSN = REACT_APP_SENTRY_DSN

//
if (REACT_APP_NODE_ENV_USER === 'development') {
  configs.nodeEnv = 'development'
  configs.backendUrl = REACT_APP_BACKEND_URL_DEVELOPMENT
  configs.priceBackendUrl = REACT_APP_PRICE_BACKEND_URL_DEVELOPMENT
} else if (REACT_APP_NODE_ENV_USER === 'production') {
  configs.nodeEnv = 'production'
  configs.backendUrl = REACT_APP_BACKEND_URL_PRODUCTION
  configs.priceBackendUrl = REACT_APP_PRICE_BACKEND_URL_PRODUCTION
}

export default configs
