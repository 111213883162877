class BadgeGenerator {
  static getInspectionBadge({ inspectionStatus }) {
    switch (inspectionStatus) {
      case 'pending':
        return {
          text: '검수 대기',
          className: 'bg-orange-500',
        }
      case 'completed':
        return {
          text: '검수 완료',
          className: 'bg-green-500',
        }
      case 'rejected':
        return {
          text: '검수 반려',
          className: 'bg-red-500',
        }
      default:
        return {
          text: '지금 신청해보세요',
          className: 'bg-gray-300',
        }
    }
  }

  static getCompetitionBadge({ compRatio }) {
    if (compRatio >= 5) {
      return {
        text: 'HOT',
        className: 'bg-red-500',
      }
    }
    if (compRatio >= 3) {
      return {
        text: 'MID',
        className: 'bg-orange-500',
      }
    }
    return {
      text: 'LOW',
      className: 'bg-green-500',
    }
  }
}

export default BadgeGenerator
