import {
  Image,
  Radio,
  Input,
  Form,
  Tooltip,
  Button,
  message,
  Modal,
  Divider,
} from 'antd'
import { useState, useReducer } from 'react'
import { ExclamationCircleTwoTone } from '@ant-design/icons'
import commaNumber from 'comma-number'
import BiddingScheduleSection from '../../unitOption/bidding/biddingScheduleSection'
import AutoBiddingNotice from './AutoBiddingNotice'
import BiddingStatusSection from './BiddingStatusSection'
import LiveBiddingRankingSection from './LiveBiddingRankingSection'
import Space from '../../atoms/Space'
import moment from 'moment'
import backendApis from '../../../utils/backendApis'

const help = {
  auto: (
    <div className='w-full'>
      <div>
        <ExclamationCircleTwoTone className='mr-2' />
        자동 입찰 기능은 최적의 가격으로 입찰을 진행합니다.
      </div>
      <div className='text-gray-500'>
        입력해주신 최저 입찰가를 기준으로 가격을 낮췄을 때 최상위 노출 확보가
        가능할 경우 자동으로 가격을 낮춥니다.
      </div>
    </div>
  ),
  fixed: (
    <div className='w-full'>
      <ExclamationCircleTwoTone className='mr-2' />
      고정가 입찰을 선택하면 입력해주신 금액으로 입찰을 진행합니다.
    </div>
  ),
  biddingPrice: '입찰하려는 금액을 입력하세요.',
  minBiddingPrice: '자동 입찰 시 사용할 최소 입찰가를 입력하세요.',
}

const initialState = {
  biddingScheduleType: 'spot',
  biddingStockType: 'origin',
  biddingStart: null,
  biddingEnd: null,
  biddingStock: 100,
  minBiddingPrice: null,
}

const formatDate = (date) => {
  return date ? moment(date).format('YYYY년 MM월 DD일 HH:mm') : '-'
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_BIDDING_SCHEDULE_TYPE':
      return { ...state, biddingScheduleType: action.payload }
    case 'SET_STOCK_OPTION':
      return { ...state, biddingStockType: action.payload }
    case 'SET_START_DATE_TIME':
      return { ...state, biddingStart: action.payload }
    case 'SET_END_DATE_TIME':
      return { ...state, biddingEnd: action.payload }
    case 'SET_STOCK_AMOUNT':
      return { ...state, biddingStock: action.payload }
    case 'RESET':
      return initialState
    default:
      throw new Error()
  }
}

function scheduleOptionParser(scheduleOption) {
  const result = {}
  if (scheduleOption?.biddingScheduleType === 'schedule') {
    result.biddingScheduleType = 'schedule'
    result.biddingStart = new Date(scheduleOption?.biddingStart)
    result.biddingEnd = new Date(scheduleOption?.biddingEnd)
  }
  if (scheduleOption?.biddingStockType === 'limited') {
    result.biddingStockType = 'limited'
    result.biddingStock = scheduleOption?.biddingStock
  } else if (scheduleOption?.biddingStockType === 'origin') {
    result.biddingStockType = 'origin'
  }
  if (scheduleOption?.biddingScheduleType === 'spot') {
    return {}
  }
  return result
}

function validateScheduleOption(parsedScheduleOption, scheduleOption) {
  const missingFields = []

  for (const [key, value] of Object.entries(parsedScheduleOption)) {
    if (!value) {
      switch (key) {
        case 'biddingStart':
          if (scheduleOption.biddingScheduleType === 'schedule') {
            missingFields.push('시작 날짜')
          }
          break
        case 'biddingEnd':
          if (scheduleOption.biddingScheduleType === 'schedule') {
            missingFields.push('종료 날짜')
          }
          break
        case 'biddingStock':
          if (scheduleOption.biddingStockType === 'limited') {
            missingFields.push('재고 수량')
          }
          break
        default:
          // 기본 케이스 처리
          break
      }
    }
  }

  return missingFields
}

const biddingTypeKor = {
  auto: '자동 입찰',
  fixed: '고정가 입찰',
}

const SkuCatalogBiddingSection = ({
  skuCatalogData,
  selectedItemTitle,
  selectedOptionTitle,
  selectedItemId,
  selectedOptionId,
  currentOptionPrice,
  skuId,
  initialStage,
  onSubmit = () => {},
  onPrev = () => {},
  onClose = () => {},
}) => {
  const [form] = Form.useForm()
  const [biddingType, setBiddingType] = useState('auto')
  const [scheduleOption, dispatch] = useReducer(reducer, initialState)
  const [biddingOptionBiddingInfo] = useState({})
  const scheduleBiddingAppliedTime = biddingOptionBiddingInfo?.appliedTime || []
  const lowestPrice = skuCatalogData?.price
  const MIN_STOCK_AMOUNT = 100
  const range = 0.3
  const lowestPriceCondition = Boolean(lowestPrice && lowestPrice !== 0)
  const confirm = Modal.confirm

  const [formValues, setFormValues] = useState({
    biddingPrice: currentOptionPrice,
    minBiddingPrice: undefined,
  })

  const handleStateChange = (action) => {
    dispatch(action)
  }

  const handleBiddingTypeChange = (e) => {
    setBiddingType(e.target.value)
    form.setFieldsValue({ biddingType: e.target.value })
  }

  const registerSkuCatalogBidding = async ({ formValues }) => {
    const result = await backendApis.registerSkuCatalogBidding({
      skuCatalogId: skuCatalogData.skuCatalogId,
      itemId: selectedItemId,
      optionId: selectedOptionId,
      ...formValues,
    })
    if (result.status === 200) {
      message.success('입찰이 등록되었습니다.')
      onSubmit()
      onClose()
    } else {
      message.error('입찰 등록 실패')
    }
  }

  const handleOk = () => {
    const parsedScheduleOption = scheduleOptionParser(scheduleOption)
    const missingFields = validateScheduleOption(
      parsedScheduleOption,
      scheduleOption,
    )
    const stockAmount = parsedScheduleOption?.biddingStock
    if (missingFields.length > 0) {
      message.warning(`다음 필드를 입력해주세요: ${missingFields.join(', ')}`)
      return // 함수 실행 중단
    }
    if (parseInt(stockAmount, 10) < MIN_STOCK_AMOUNT) {
      message.warning(`최소 재고량은 ${MIN_STOCK_AMOUNT}개입니다.`)
      return
    }

    form
      .validateFields()
      .then(async (values) => {
        try {
          const updatedValues = {
            ...values,
            ...parsedScheduleOption,
          }
          confirm({
            title: '입찰을 진행하시겠습니까?',
            content: (
              <div>
                <div>{selectedItemTitle}</div>
                <div>{selectedOptionTitle}</div>
                <div>
                  입찰 유형: {biddingTypeKor?.[updatedValues?.biddingType]}
                </div>
                <div>입찰가: {commaNumber(updatedValues.biddingPrice)}원</div>
                <div>
                  {biddingType === 'auto' && (
                    <div>
                      최저 입찰가: {commaNumber(updatedValues.minBiddingPrice)}
                      원
                    </div>
                  )}
                </div>
                <Divider className='my-2' />
                <div>
                  예약 입찰 여부:
                  {updatedValues?.biddingScheduleType === 'schedule'
                    ? 'Y'
                    : 'N'}
                </div>
                <div>
                  시작 날짜:{' '}
                  {updatedValues?.biddingScheduleType === 'schedule'
                    ? formatDate(updatedValues?.biddingStart)
                    : '없음'}
                </div>
                <div>
                  종료 날짜:{' '}
                  {updatedValues?.biddingScheduleType === 'schedule'
                    ? formatDate(updatedValues?.biddingEnd)
                    : '없음'}
                </div>
                <div>
                  한정 재고 수량:{' '}
                  {updatedValues?.biddingStockType === 'limited'
                    ? updatedValues?.biddingStock
                    : '없음'}
                </div>
              </div>
            ),
            okButtonProps: { type: 'primary', className: 'bg-blue-500' },
            okText: '입찰하기',
            cancelText: '취소',
            onOk: async () => {
              await registerSkuCatalogBidding({
                formValues: { ...initialState, ...updatedValues },
              })
            },
          })
        } catch (error) {
          message.error('입찰 중 오류가 발생했습니다.')
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  return (
    <div className='flex flex-col flex-1'>
      <div className='flex flex-1 mb-4 flex-row w-full mr-8 items-center justify-center'>
        <Image
          src={skuCatalogData.mainImageUris[0]}
          alt={skuCatalogData.title}
          width={80}
          height={80}
        />
        <div className='font-bold flex flex-col items-end line-clamp-2 mx-4'>
          {skuCatalogData.title}
        </div>
        <div className='flex-1 flex flex-col w-full justify-center'>
          <div className='bg-gray-50 py-2 px-4 justify-center flex flex-1 flex-col items-start rounded-lg w-full'>
            <div className='mb-2'>
              <span className='font-bold'>상품명:</span> {selectedItemTitle}
            </div>
            <div className='mb-2 flex flex-row items-center justify-start'>
              <div className='mr-4'>
                <span className='font-bold'>선택 옵션:</span>{' '}
                {selectedOptionTitle}
              </div>
              <div>
                <span className='font-bold'>선택 옵션 가격:</span>{' '}
                <span className='text-blue-500 font-bold'>
                  {commaNumber(currentOptionPrice)}원
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row flex-1 mb-4'>
        <div className='flex flex-row flex-1'>
          <div className='flex flex-row flex-1 w-full gap-4'>
            <div className='p-4 rounded-lg border-[1px] w-full border-gray-200'>
              <div className='font-bold text-[16px] text-blue-500 mb-4'>
                예약 설정
              </div>
              <div>
                <BiddingScheduleSection
                  state={scheduleOption}
                  onStateChange={handleStateChange}
                  appliedTime={scheduleBiddingAppliedTime}
                  scheduleBidding={initialStage === 2}
                  MIN_STOCK_AMOUNT={MIN_STOCK_AMOUNT}
                />
              </div>
            </div>
            <div className='p-4 rounded-lg border-[1px] w-full border-gray-200'>
              <div className='font-bold text-[16px] text-blue-500 mb-2'>
                입찰 설정
              </div>
              <Form
                form={form}
                layout='vertical'
                onValuesChange={(_, allValues) => {
                  setFormValues(allValues)
                }}
              >
                <Form.Item
                  name='biddingType'
                  required
                  initialValue={biddingType}
                  className='mb-2'
                >
                  <div className='flex items-center'>
                    <span className='mr-4 font-medium'>
                      <span className='text-red-500 mr-1'>*</span>
                      입찰 유형
                    </span>
                    <Radio.Group
                      onChange={handleBiddingTypeChange}
                      value={biddingType}
                      defaultValue={biddingType}
                      className='flex items-center'
                    >
                      <Radio value='auto'>자동 입찰</Radio>
                      <Radio value='manual'>고정가 입찰</Radio>
                    </Radio.Group>
                  </div>
                </Form.Item>
                <Tooltip title={help.biddingPrice}>
                  <Form.Item
                    name='biddingPrice'
                    className='mb-2'
                    normalize={(value) => {
                      return value ? Number(value) : null
                    }}
                    rules={[
                      { required: true, message: '입찰가를 입력해주세요.' },
                      { pattern: /^[0-9]+$/, message: '숫자만 입력해주세요.' },
                      {
                        validator: (_, value) => {
                          if (value % 10 !== 0) {
                            return Promise.reject(
                              new Error('판매가를 10원 단위로 설정해주세요.'),
                            )
                          }
                          return Promise.resolve()
                        },
                      },
                      // {
                      //   validator: (_, value) => {
                      //     const maxAllowedPrice = lowestPrice * (1 + range)
                      //     if (lowestPriceCondition && value > maxAllowedPrice) {
                      //       return Promise.reject(
                      //         new Error(
                      //           `입찰가를 실시간 최저가 대비 최대 ${
                      //             (1 + range) * 100
                      //           }% 이내로 설정해주세요.`,
                      //         ),
                      //       )
                      //     }
                      //     return Promise.resolve()
                      //   },
                      // },
                      {
                        validator: (_, value) => {
                          const maxAllowedPrice = currentOptionPrice
                          if (value > maxAllowedPrice) {
                            return Promise.reject(
                              new Error(
                                `입찰가가 현재 옵션 가격보다 비쌉니다. 현재 옵션 가격: ${commaNumber(
                                  currentOptionPrice,
                                )}원`,
                              ),
                            )
                          }
                          return Promise.resolve()
                        },
                      },
                      {
                        validator: (_, value) => {
                          if (value <= 1000) {
                            return Promise.reject(
                              new Error(
                                '정상 판매가를 입력해주세요.(1,000원 이상)',
                              ),
                            )
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <div className='flex items-center'>
                      <span className='mr-4 font-medium w-32'>
                        <span className='text-red-500 mr-1'>*</span>
                        입찰가
                      </span>
                      <Input
                        type='number'
                        step={10}
                        className='h-8 text-sm rounded-md border-slate-400'
                        placeholder='입찰가를 입력해주세요.'
                        defaultValue={currentOptionPrice}
                        suffix='원'
                      />
                    </div>
                  </Form.Item>
                </Tooltip>
                {biddingType === 'auto' && (
                  <Tooltip title={help.minBiddingPrice}>
                    <Form.Item
                      name='minBiddingPrice'
                      className='mb-2'
                      normalize={(value) => {
                        return value ? Number(value) : null
                      }}
                      rules={[
                        {
                          required: biddingType === 'auto',
                          message: '최저 입찰가를 입력해주세요.',
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: '숫자만 입력해주세요.',
                        },
                        {
                          validator: (_, value) => {
                            if (value % 10 !== 0) {
                              return Promise.reject(
                                new Error('판매가를 10원 단위로 설정해주세요.'),
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                        {
                          validator: (_, value) => {
                            const maxAllowedPrice =
                              form.getFieldValue('biddingPrice')
                            if (value > maxAllowedPrice) {
                              return Promise.reject(
                                new Error(
                                  '최저 입찰가가 현재 입찰가보다 비쌉니다.',
                                ),
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                        {
                          validator: (_, value) => {
                            if (value <= 1000) {
                              return Promise.reject(
                                new Error(
                                  '정상 판매가를 입력해주세요.(1,000원 이상)',
                                ),
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <div className='flex items-center'>
                        <span className='mr-4 font-medium w-32'>
                          <span className='text-red-500 mr-1'>*</span>
                          최저 입찰가
                        </span>
                        <Input
                          type='number'
                          step={10}
                          className='h-8 text-sm rounded-md border-slate-400'
                          placeholder='하한가를 입력해주세요.'
                          disabled={biddingType === 'fixed'}
                          suffix='원'
                        />
                      </div>
                    </Form.Item>
                  </Tooltip>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
      {biddingType === 'auto' && (
        <div>
          <AutoBiddingNotice />
          <Space height={16} />
        </div>
      )}
      <div className='flex gap-4 h-[330px]'>
        <div className='w-1/3 h-full'>
          <BiddingStatusSection skuId={skuId} />
        </div>
        <div className='w-2/3 h-full'>
          <LiveBiddingRankingSection
            initialStage={initialStage}
            skuCatalogId={skuCatalogData.skuCatalogId}
            displayableCatalogPrice={skuCatalogData.price}
            biddingPrice={formValues.biddingPrice}
            minBiddingPrice={formValues.minBiddingPrice}
          />
        </div>
      </div>
      <div className='flex mt-4 justify-end items-center gap-4 flex-row flex-1 '>
        {initialStage === 1 && (
          <Button
            size='large'
            className='bg-gray-100 text-gray-500 py-4 px-16 font-bold'
            onClick={onPrev}
          >
            이전으로
          </Button>
        )}
        <div>
          <Button
            onClick={handleOk}
            type='primary'
            size='large'
            className='py-4 px-16 font-bold'
          >
            설정하기
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SkuCatalogBiddingSection
