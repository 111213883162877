import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ItemRankReportMini from '../molecules/itemRankReportMini'
import StarRating from '../atoms/starRating'
import ItemButtonList from '../molecules/itemButtonList'
import LineChart from '../molecules/lineChart'
import moment from 'moment'
import DealButtonHandler from '../molecules/dealButtonHandler'
import DealTimePanel from '../atoms/dealTimePanel'
import ToggleInput from '../atoms/toggleInput'
import Modal from '../atoms/modal'
import expandImpression from '../../images/expandImpression.jpeg'
import {
  FcApproval as BonusIcon,
  FcHighPriority as WarningIcon,
} from 'react-icons/fc'
import { BsQuestionSquare as AlwayzQuestion } from 'react-icons/bs'
import resize from '../../utils/resize'
import Button from '../atoms/button'
import { Tooltip } from '../ADS/atoms'
import DealApplicationStatusMini from '../molecules/dealApplicationStatusMini'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ExternalUrlModal from '../atoms/externalUrlModal'
import Gauge from '../atoms/gauge'
import { Spin } from 'antd'
import { BeatLoader } from 'react-spinners'

const externalReviewStatusText = (catalogId, externalUrlInfo) => {
  if (catalogId) return '*원클릭 자동 리뷰 연동 상품입니다'
  if (externalUrlInfo?.status === 'applied') return '리뷰 연동 심사 중'
  if (externalUrlInfo?.status === 'verified') return '리뷰 주소 확인 완료'
  if (externalUrlInfo?.status === 'rejected') return '리뷰 주소 거절됨'
  if (externalUrlInfo?.status === 'crawled') return '리뷰 크롤링 완료'
  if (externalUrlInfo?.status === 'complete') return '리뷰 연동 완료'
  return '외부 리뷰 연동하기'
}

function allElementsAreZero(array) {
  return array?.every((element) => element === 0)
}

const ItemComponent = observer(
  ({
    itemInfo, // 아이템 data.
    isRankItem = true,
    data,
    setData,
    dealType, // 딜 유형. deal_options_info 참고.
    dealRequest, // 딜 신청 버튼 클릭시 실행 될 함수.
    dealCancelRequest,
    dealRankLimit = 'none', // 딜 신청 최소 등급 조건 data
    dealApplyInfo, // 딜 신청 여부를 구분할 수 있는 data. true일 경우 신청완료.
    dealStatusInfo = { level: 1, message: '' },
    checkBox, // checkBox 유무 여부. true일 경우 아이템 카드 옆에 체크박스 생김.
    checkedItemIds = [],
    setCheckedItemIds = () => {},
    callbackFunction = () => {},
    itemsInfo = [], // 아이템 전체 data.
    setItemsInfo = () => {},
    modalType,
    isReApply = false,
  }) => {
    const [itemRankData, setItemRankData] = useState()
    const [urlModalOn, setUrlModalOn] = useState(false)
    const [expandImpressionMode, setExpandImpressionMode] = useState(
      itemInfo?.expandImpressionMode,
    )
    const isDealOnlyItem = itemInfo?.characteristicItem?.isDealOnlyItem || false
    const rankedAt = itemRankData?.rankedAt
    const pricedAt = itemInfo?.crawledItemTheLowest?.pricedAt
    const bonusScoreCondition =
      new Date(rankedAt).getTime() > new Date(pricedAt).getTime() || false
    const history = useHistory()
    const [alfarmPhotoReviewMode, setAlfarmPhotoReviewMode] = useState(
      itemInfo?.alfarmPhotoReviewMode,
    )
    const externalUrlInfo = itemInfo?.externalUrlInfo
    const [impChartData, setImpChartData] = useState({
      labels: [],
      datasets: [
        {
          label: '',
          data: '',
          fillColor: '',
          strokeColor: '',
          pointColor: '',
          pointStrokeColor: '',
          pointHighlightStroke: '',
          borderWidth: 2,
        },
      ],
    })
    const [chartOption, setChartOption] = useState({
      layout: {
        padding: {
          left: 40,
          right: 40,
          top: 20,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          display: false,
        },
      },
      cubicInterpolationMode: 'monotone',
      fill: true,
      scaleShowLabels: false,
      scaleShowGridLines: true,
    })

    const RenderChart = ({ itemInfo, impChartData }) => {
      if (itemInfo?.soldOut) {
        return null
      }
      if (
        !itemInfo?.categoryInfo?.minimumCategoryId &&
        dealType === undefined
      ) {
        return (
          <div className='flex flex-col items-center'>
            <Button appearance='gray' size='sm'>
              새로운 카테고리로 이전이 되지 않아 <br />
              노출량 혜택을 못받고 있어요!
            </Button>
            <div className='flex flex-col items-start justify-center flex-1 mt-3'>
              <div>
                어떻게 이전하나요?
                <Tooltip
                  text={`상품 수정에서 "이전 카테고리 선택 => 카테고리 검색" 을 통해 새로운 카테고리로 이동해주세요`}
                />
              </div>
            </div>
          </div>
        )
      }
      if (dealType === 'tenMinDeal') {
        return (
          <div className='text-sm '>
            <div className='mb-2 font-bold text-blue-500'>딜 신청 진행상황</div>
            <div className='flex flex-row'>
              <Gauge
                level={dealStatusInfo?.level}
                color={dealStatusInfo?.color}
              />
            </div>
            <div className='mt-2 text-slate-600'>{dealStatusInfo?.status}</div>
            <div className='text-slate-600'>{dealStatusInfo?.message}</div>
            {/* fix me DealReApply */}
            {/* <Button
              type='button'
              appearance='teritiary'
              size='none'
              className='mr-2'
              onClick={() => {
                history.push('/items/details', {
                  itemInfo: JSON.parse(JSON.stringify({ itemInfo })),
                  reApplyDeal: true,
                })
              }}
            >
              수정
            </Button> */}
          </div>
        )
      }
      return (
        <div className='flex flex-col items-center justify-center'>
          <LineChart chartData={impChartData} options={chartOption} />
        </div>
      )
    }

    useEffect(() => {
      async function fetchData() {
        const rankData = {
          itemRank: itemInfo?.rankInfo?.itemRank,
          bonusScore: itemInfo?.rankInfo?.bonusScore,
          rankScore:
            itemInfo?.rankInfo?.totalScore - itemInfo?.rankInfo?.bonusScore,
          rankedAt: new Date(itemInfo?.rankInfo?.rankedAt),
        }
        setItemRankData(rankData)

        let itemImpData = []
        if (Array.isArray(itemInfo?.impressionTrend?.impressionTrend)) {
          itemImpData = itemInfo?.impressionTrend?.impressionTrend
        } else {
          const itemImpDataRaw = await backendApis.loadItemImpTrend(
            itemInfo._id,
          )
          if (itemImpDataRaw?.status === 200) {
            itemImpData = itemImpDataRaw?.data?.impressionTrend
          }
        }
        const dateRange = []
        const impressionData = []
        for (let i = 7; i >= 1; i -= 1) {
          dateRange.push(moment().subtract(i, 'day').format('YYYY-MM-DD'))
        }
        dateRange.forEach((data) => {
          impressionData.push(
            itemImpData?.find((e) => e?._id === data)
              ?.shortImpressionResultIncludingBrowsing ||
              itemImpData?.find((e) => e?._id === data)
                ?.shortImpressionResult ||
              0,
          )
        })
        const isAllZero = allElementsAreZero(impressionData)

        setImpChartData({
          labels: dateRange,
          datasets: [
            {
              label: '상품노출도',
              data: impressionData,
              backgroundColor: '#f0f9ff',
              borderColor: '#60a5fa',
              hoverBackgroundColor: '#60a5fa',
              hoverBorderColor: '#34d399',
              borderWidth: 2,
            },
          ],
          isAllZero,
        })
        let legendDisplay = true
        if (
          ((itemInfo?.teamPurchasePrice -
            itemInfo?.crawledItemTheLowest?.price) /
            itemInfo?.teamPurchasePrice) *
            100 >
          0
        ) {
          legendDisplay = false
        }
        setChartOption({
          ...chartOption,
          plugins: {
            legend: {
              display: legendDisplay,
            },
            tooltip: {
              enabled: true,
            },
          },
        })
      }
      fetchData()
    }, [itemInfo])
    const ratingScore =
      itemInfo?.totalRatingScore / itemInfo?.totalRatingCount || 0

    const ItemActionComponentHandler = () => {
      if (dealType) {
        return (
          <div className='flex flex-row items-center justify-end flex-1 min-w-[35%] max-w-[35%]'>
            <DealButtonHandler
              itemInfo={itemInfo}
              itemRankData={itemRankData}
              data={data}
              setData={setData}
              dealType={dealType}
              dealRankLimit={dealRankLimit}
              dealRequest={dealRequest}
              dealCancelRequest={dealCancelRequest}
              dealApplyInfo={dealApplyInfo}
              isRankItem={isRankItem}
              isReApply={isReApply}
            />
          </div>
        )
      }
      if (!dealType && isRankItem) {
        return (
          <div className='flex flex-row items-center justify-center flex-1 min-w-[35%] max-w-[35%]'>
            {isDealOnlyItem ? (
              <DealApplicationStatusMini itemInfo={itemInfo} />
            ) : (
              <ItemRankReportMini
                itemInfo={itemInfo}
                itemRankData={itemRankData}
                callbackFunction={callbackFunction}
              />
            )}
          </div>
        )
      }
      return <></>
    }
    const ItemToggleHandler = (dealType, modalType) => {
      if (modalType === 'alfarmPhotoReview') {
        return (
          <div className='flex flex-row items-center'>
            <div className='mr-2'>
              <Modal
                modalTitle='사진 후기 신청이란?'
                imageSource={alfarmPhotoReviewMode}
                modalContent={
                  <>
                    <ol className='font-normal whitespace-normal list-decimal'>
                      <li>
                        상품 판매량을 빠르게 높이기 위해서는 사진 후기의 수가
                        많아야 합니다.{' '}
                        <span className='font-bold text-emerald-500'>
                          이를 위해 올웨이즈가 도와드립니다.
                        </span>
                      </li>
                      <li>
                        이벤트는 올팜 내에서 진행되며, 고객님께서 상품의 사진
                        후기를 남기면 일정양의 재화를 받습니다.
                      </li>
                      <li>
                        사진후기 1개당 정산에서 300원이 차감되며,{' '}
                        <span className='font-bold text-emerald-500'>
                          이외 금액은 올웨이즈가 부담합니다.
                        </span>
                      </li>
                      <li>
                        고객님께서 남기신 후기는 등록된 시점을 기준으로 청구가
                        진행될 수 있습니다. (ex. 고객님께서 구매하시면서
                        품절되었고, 해당 고객님이 후기를 남기신 경우, 현재
                        상품이 품절된 상태라도 차감될 수 있습니다.)
                      </li>
                    </ol>
                  </>
                }
                className='flex flex-row items-center pl-0 pr-0 font-bold'
              >
                <div className='mr-1 font-bold text-emerald-500'>사진 후기</div>
                <AlwayzQuestion
                  style={{ color: '#9CA3AF', width: 14, height: 14 }}
                />
              </Modal>
            </div>

            <ToggleInput
              state={alfarmPhotoReviewMode}
              setState={setAlfarmPhotoReviewMode}
              className='mr-2'
              color='positiveSub'
              turnOffMessage='사진 후기를 취소하시는 경우 노출과 더불어 판매량이 줄어들 수 있습니다. 사진 후기를 취소하시겠습니까?'
              callbackFunction={async (alfarmPhotoReviewMode) => {
                await backendApis.updateItemSingleField(
                  itemInfo?._id,
                  'alfarmPhotoReviewMode',
                  alfarmPhotoReviewMode,
                )
              }}
            />
          </div>
        )
      }
      if (dealType === 'timeDeal') {
        return (
          <div className='flex flex-row items-center'>
            <span className='mr-2'>
              <Modal
                modalTitle='노출도 확장이란?'
                imageSource={expandImpression}
                modalContent={
                  <>
                    <ol className='font-normal whitespace-normal list-decimal'>
                      <li>
                        타임특가에 신청하신 제품의 노출도를 보다 많이
                        보장해드리기 위해 타임특가 구좌가 아닌 딜 구좌에서도
                        노출하여
                        <span className='font-bold text-emerald-500'>
                          {' '}
                          판매량을 극대화
                        </span>
                        하는 기능입니다.
                      </li>
                      <li>
                        정산은 신청해주신{' '}
                        <span className='font-bold text-emerald-500'>
                          타임특가 희망가
                        </span>{' '}
                        기준으로 정확하게 이루어집니다.
                      </li>
                      <li>
                        올웨이즈는 폐쇄몰입니다. 판매되는 모든 상품의 가격은{' '}
                        <span className='font-bold text-emerald-500'>
                          오픈 마켓에 노출되지 않습니다.
                        </span>
                      </li>
                    </ol>
                  </>
                }
                className={`font-bold ${
                  expandImpressionMode ? 'text-emerald-500' : 'text-gray-500'
                }`}
              >
                노출도 확장
              </Modal>
            </span>

            <ToggleInput
              state={expandImpressionMode}
              setState={setExpandImpressionMode}
              className='mr-2'
              color='positiveSub'
              turnOffMessage='노출도 확장을 취소하시는 경우 노출과 더불어 판매량이 줄어들게 됩니다. 노출도 확장을 취소하시겠습니까?'
              callbackFunction={async (expandImpressionMode) => {
                await backendApis.updateItemSingleField(
                  itemInfo?._id,
                  'expandImpressionMode',
                  expandImpressionMode,
                )
              }}
            />
          </div>
        )
      }
      return <></>
    }

    return (
      <>
        <div className='mb-8 bg-white shadow-md select-none whitespace-nowrap rounded-xl'>
          <div className='flex items-center justify-between flex-1 px-8 py-8'>
            {checkBox ? (
              <div className='flex flex-row flex-1 items-center justify-start min-w-[3%] max-w-[3%] select-none'>
                <input
                  type='checkbox'
                  checked={checkedItemIds?.includes(itemInfo?._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedItemIds([
                        ...checkedItemIds?.filter(
                          (itemId) => itemId !== itemInfo?._id,
                        ),
                        itemInfo?._id,
                      ])
                    } else {
                      setCheckedItemIds(
                        checkedItemIds?.filter(
                          (itemId) => itemId !== itemInfo?._id,
                        ),
                      )
                    }
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            <div className='flex flex-row items-center flex-1 min-w-[37%] max-w-[37%] '>
              <div className='flex flex-col'>
                <div>
                  {!isDealOnlyItem &&
                    itemInfo?.crawledItemTheLowest?.price &&
                    bonusScoreCondition && (
                      <div className='absolute mt-1 ml-1'>
                        <Modal
                          className='shadow-none'
                          modalTitle='최저가 링크 인증 상품입니다'
                          modalContent={
                            <div className='whitespace-normal'>
                              {itemRankData?.bonusScore ? (
                                <div className='flex flex-row items-center flex-1 mb-5 font-bold'>
                                  <BonusIcon className='w-6 h-6 mr-2' />
                                  현재 보너스 점수: {itemRankData?.bonusScore}
                                  {itemInfo?.rankInfo?.bonusScore < 0 && (
                                    <span className='text-red-500'>
                                      *온라인 최저가 보다 비싼 제품입니다.
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                              <ol className='ml-5 list-[decimal]'>
                                <li>
                                  최저가 링크 인증 상품은 할인율에 따라 최대
                                  20점의{' '}
                                  <span className='font-bold text-blue-500'>
                                    보너스 점수
                                  </span>
                                  를 부여받습니다.
                                </li>
                                <li>
                                  <span className='font-bold text-blue-500'>
                                    보너스 점수
                                  </span>
                                  는 올웨이즈의 AI를 통해 자동으로 수집된 온라인
                                  최저가 정보를 바탕으로 산출되는 가산점입니다.
                                </li>
                                <li>
                                  보너스 점수는 등급 및 노출도에 큰 영향을 줄 수
                                  있습니다.
                                </li>
                                <li>
                                  <span className='font-bold text-blue-500'>
                                    보너스 점수
                                  </span>
                                  가{' '}
                                  <span className='font-bold text-red-500'>
                                    없음
                                  </span>
                                  인 경우,{' '}
                                  <span className='font-bold text-blue-500'>
                                    등급보고서
                                  </span>{' '}
                                  화면에서{' '}
                                  <span className='px-2 py-1 font-bold text-white border-b-2 border-r-2 rounded-md shadow-md border-sub-500 bg-gradient-to-r from-sub-400 via-sub-500 to-sub-600'>
                                    최저가 링크 확인 요청
                                  </span>{' '}
                                  버튼 을 통해 확인 신청이 가능합니다.
                                </li>
                                <li>
                                  신청된 링크가 유효할 경우{' '}
                                  <span className='font-bold text-blue-500'>
                                    보너스 점수
                                  </span>
                                  가 부여될 수 있습니다.
                                </li>
                              </ol>
                            </div>
                          }
                        >
                          <BonusIcon className='w-6 h-6' />
                        </Modal>
                      </div>
                    )}
                  {isDealOnlyItem && (
                    <div className='absolute px-2 py-1 mt-1 ml-1 text-sm text-white bg-red-500 rounded-lg'>
                      딜 전용상품
                    </div>
                  )}
                  <img
                    alt='mainImage'
                    src={resize(itemInfo?.mainImageUris[0])}
                    className='w-32 h-32 mb-2 mr-5 rounded-md'
                  />
                </div>
                {ItemToggleHandler(dealType, modalType)}
              </div>
              <div className='flex flex-col flex-1 px-2'>
                <a
                  target='_blank'
                  href={`https://alwayzweb.ilevit.com/?alwayzItemId=${itemInfo._id}`}
                  rel='noreferrer'
                >
                  <div
                    className={`text-base font-bold select-all whitespace-normal mb-1 ${
                      itemInfo?.soldOut ? 'text-gray-400' : 'text-black'
                    }`}
                  >
                    {itemInfo?.soldOut ? '(품절) ' : ''}
                    {!itemInfo?.soldOut && itemInfo?.outOfStock
                      ? '(재고 소진) '
                      : ''}
                    {itemInfo?.itemTitle}
                  </div>
                </a>
                <div className='flex flex-row items-center mb-1 text-sm font-normal text-gray-400 select-all'>
                  {itemInfo._id}
                  {/* <CopyIcon className='ml-1' /> */}
                </div>
                <div className='flex flex-row items-center mb-5'>
                  <StarRating score={ratingScore} />
                  <span className='ml-1 text-sm text-gray-400'>
                    {ratingScore.toFixed(1)}점
                  </span>
                </div>
                <div className='flex flex-row items-end flex-1'>
                  {dealType ? (
                    <DealTimePanel
                      dealType={dealType}
                      startDate={itemInfo?.displayStartDate}
                      endDate={itemInfo?.displayEndDate}
                    />
                  ) : (
                    <ItemButtonList
                      itemInfo={itemInfo}
                      itemsInfo={itemsInfo}
                      setItemsInfo={setItemsInfo}
                      isRankItem={isRankItem}
                      itemRankData={itemRankData}
                      callbackFunction={callbackFunction}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='flex flex-col juystify-center items-center flex-1 min-w-[25%] max-w-[25%]'>
              <div>
                <div>
                  {impChartData.isAllZero ? (
                    <div className='flex flex-row items-center justify-center flex-1 text-sm text-slate-500'>
                      <BeatLoader
                        size={5}
                        speedMultiplier={0.2}
                        color='#14b8a6'
                        className='mr-2'
                      />
                      노출 최적화 진행 중
                    </div>
                  ) : (
                    <RenderChart
                      itemInfo={itemInfo}
                      impChartData={impChartData}
                    />
                  )}
                </div>
              </div>
            </div>
            <ItemActionComponentHandler />
          </div>
        </div>
      </>
    )
  },
)

export default ItemComponent
