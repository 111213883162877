/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { FcIdea, FcMoneyTransfer, FcOk, FcHighPriority } from 'react-icons/fc'
import SellerStore from '../stores/SellerStore'
import Page from '../components/atoms/page'
import ImageManager from '../images/ImageManager'
import TodayDepartureStats from '../components/TodayDepartureStats'
import TodayDepartureItems from '../components/TodayDepartureItems'
import backendApis from '../utils/backendApis'
import moment from 'moment'

const TODAYDEPRARTURETHRESHOLD = 97
const LASTWEEKORDERCOUNTTHRESHOLD = 50
const LASTWEEKTODAYDEPARTUREPENALTYTHRESHOLD = 10

const TodayDepartureInfo = () => {
  return (
    <div className='flex flex-wrap justify-between'>
      <div className='shadow-lg rounded-2xl p-8 bg-white flex-auto mr-4 mb-4'>
        <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
          <FcIdea className='mr-2' />
          오늘출발이란?
        </span>
        <ul className='mx-4 list-disc'>
          <li>
            오늘출발 상품은 판매자가 설정한 기준 시간 이전에 결제완료가 이루어질
            경우, 당일 해당 주문의 발송이 이루어진다는 고객과의 약속입니다.
          </li>
        </ul>
        <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold'>
          <FcMoneyTransfer className='mr-2' />왜 오늘출발을 등록해야 하나요?
        </span>
        <ul className='mx-4 list-disc'>
          <li>
            오늘출발 상품에는{' '}
            <span className='text-purple-400'>오늘출발 배너 및 배지 </span>가
            붙어 고객의 관심을 끌 수 있어요.
          </li>
          <li>
            <span className='text-purple-400'>오늘출발 전용 상품매대</span>에
            상품이 노출되어요.
          </li>
        </ul>
        <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold'>
          <FcOk className='mr-2' />
          누가 참여할 수 있나요?
        </span>
        <ul className='mx-4 list-disc'>
          <li>
            최근 한달간 주간 판매량 {LASTWEEKORDERCOUNTTHRESHOLD}건 이상을
            기록한 적이 있으며, +1 영업일 발송 완료 비율이{' '}
            {TODAYDEPRARTURETHRESHOLD}% 이상인 판매자만 참여할 수 있습니다.
          </li>
        </ul>
        <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold'>
          <FcHighPriority className='mr-2' /> 유의사항
        </span>
        <ul className='mx-4 list-disc'>
          <li>
            오늘출발 상품의 신뢰도 향상을 위해 주문건 모니터링이 이루어지고
            있으며, 당일 발송에 실패한 주문건에 대해서는{' '}
            <a
              href='https://placid-hoverfly-3d1.notion.site/9a0d458e0a3e48baa216654a1abfcc59'
              className='text-blue-400'
            >
              올웨이즈 판매 이용 약관
            </a>
            에 따라{' '}
            <span className='text-red-500 font-bold'>
              패널티가 부과되며 최근 7일간{' '}
              {LASTWEEKTODAYDEPARTUREPENALTYTHRESHOLD}점의 패널티가 누적되면
              오늘출발 등록이 30일간 정지됩니다!
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

const TodayDeparturesScreen = () => {
  const [sellerTodayDepartureStatus, setSellerTodayDepartureStatus] =
    useState(0) // 0: 등록기준 미달 1: 정지됨 2: 등록 가능

  useEffect(() => {
    const validateSellerTodayDepartureStatus = async () => {
      const getSellerTodayDepartureStatusResult =
        await backendApis.getSellerTodayDepartureStatus()
      if (getSellerTodayDepartureStatusResult?.status === 200) {
        if (getSellerTodayDepartureStatusResult?.data?.todayDeparture) {
          // 이미 등록된 경우 -> 정지됬는지 체크
          if (
            getSellerTodayDepartureStatusResult?.data?.todayDeparture?.status
          ) {
            setSellerTodayDepartureStatus(2)
          } else {
            const suspensionDate = moment(
              getSellerTodayDepartureStatusResult.data.todayDeparture.updatedAt,
            )
            const today = moment()
            const diff = today.diff(suspensionDate, 'days')
            if (diff < 30) {
              setSellerTodayDepartureStatus(1)
            } else {
              setSellerTodayDepartureStatus(2)
            }
          }
        } else {
          setSellerTodayDepartureStatus(
            (SellerStore.sellerScores?.shippingDetailStats?.oneDayShippingNum /
              SellerStore.sellerScores?.shippingDetailStats?.totalOrderNum) *
              100 >=
              TODAYDEPRARTURETHRESHOLD && // +1 영업일 발송 완료 비율
              SellerStore.sellerScores?.maxWeeklyTotalOrdersLastMonth >=
                LASTWEEKORDERCOUNTTHRESHOLD // 최근 1개월 내 max 7일간 주문건
              ? 2
              : 0,
          )
        }
      } else {
        window.alert(
          '셀러 정보를 가져오는 중 문제가 발생했습니다. 나중에 다시 시도해 주세요.',
        )
      }
    }
    validateSellerTodayDepartureStatus()
  }, [])

  return (
    <div className='w-[1152px] max-w-6xl min-w-6xl'>
      <Page title='🚀 오늘출발'>
        <TodayDepartureInfo />
        <div className='flex w-full text-center'>
          {sellerTodayDepartureStatus === 2 ? (
            <ImageManager source='tickIcon' size={33} />
          ) : (
            <ImageManager source='crossIcon' size={33} />
          )}
          <h2 className='text-2xl font-bold '>
            {SellerStore.sellerInfo.sellerDisplayName} 님은 오늘출발 등록이{' '}
            {sellerTodayDepartureStatus === 2
              ? '가능해요'
              : sellerTodayDepartureStatus === 1
              ? '정지되었어요'
              : '불가능해요'}
          </h2>
        </div>
        <div className='flex justify-between flex-1 mt-4 mb-5'>
          {sellerTodayDepartureStatus !== 2 ? (
            <>
              <TodayDepartureStats
                title='최근 1달이내 최대 7일간 주문건'
                explanation={`최근 30일 이내 7일간 발송 건수가 ${LASTWEEKORDERCOUNTTHRESHOLD} 건 이상을 기록한 적이 있는 판매자만 참여할 수 있습니다.`}
                semiTitle='[최근1달 이내 최대 7일간 주문건]'
                icon={
                  SellerStore.sellerScores?.maxWeeklyTotalOrdersLastMonth >=
                  LASTWEEKORDERCOUNTTHRESHOLD ? (
                    <ImageManager source='tickIcon' size={55} />
                  ) : (
                    <ImageManager source='crossIcon' size={55} />
                  )
                }
                stat={
                  SellerStore.sellerScores?.maxWeeklyTotalOrdersLastMonth || 0
                }
                unit='건'
              />
              <TodayDepartureStats
                title='+1 영업일 발송 완료 비율'
                explanation={`+1 영업일 발송 완료 비율이 ${TODAYDEPRARTURETHRESHOLD}% 이상인 판매자만 참여할 수 있습니다`}
                semiTitle='[+1 영업일 발송 완료 비율 (7일 간)]'
                icon={
                  (SellerStore.sellerScores?.shippingDetailStats
                    ?.oneDayShippingNum /
                    SellerStore.sellerScores?.shippingDetailStats
                      ?.totalOrderNum) *
                    100 >=
                  TODAYDEPRARTURETHRESHOLD ? (
                    <ImageManager source='tickIcon' size={55} />
                  ) : (
                    <ImageManager source='crossIcon' size={55} />
                  )
                }
                stat={(
                  (SellerStore.sellerScores?.shippingDetailStats
                    ?.oneDayShippingNum /
                    SellerStore.sellerScores?.shippingDetailStats
                      ?.totalOrderNum) *
                    100 || 0
                ).toFixed(1)}
                unit='%'
              />
            </>
          ) : (
            <TodayDepartureStats
              title='최근 7일간 오늘출발 패널티'
              explanation={`최근 7일간 오늘출발 패널티가 ${LASTWEEKTODAYDEPARTUREPENALTYTHRESHOLD}점을 초과하면 오늘출발 등록이 30일간\n정지됩니다!`}
              semiTitle='최근 일주일 오늘출발 건으로 받은 패널티 점수'
              icon={
                SellerStore.sellerScores.recentTodayDeparturePenalty <
                LASTWEEKTODAYDEPARTUREPENALTYTHRESHOLD ? (
                  SellerStore.sellerScores.recentTodayDeparturePenalty <
                  LASTWEEKTODAYDEPARTUREPENALTYTHRESHOLD / 2 ? (
                    <ImageManager source='tickIcon' size={55} />
                  ) : (
                    <ImageManager source='warningIcon' size={55} />
                  )
                ) : (
                  <ImageManager source='crossIcon' size={55} />
                )
              }
              stat={SellerStore.sellerScores.recentTodayDeparturePenalty}
              unit='점'
            />
          )}
        </div>
        {sellerTodayDepartureStatus === 2 && (
          <>
            <TodayDepartureItems isRegistered />
            <TodayDepartureItems isRegistered={false} />
          </>
        )}
      </Page>
    </div>
  )
}

export default TodayDeparturesScreen
