import React from 'react'
import {
  Form,
  InputNumber,
  Radio,
  Space,
  Tooltip,
  message,
  DatePicker,
} from 'antd'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import {
  getRoundedNow,
  isDateDisabled,
  getDisabledTimes,
} from '../../../utils/calendarUtils'
import locale from 'antd/es/date-picker/locale/ko_KR'
import moment from 'moment'

const { RangePicker } = DatePicker

const BiddingScheduleSection = ({
  state,
  onStateChange,
  appliedTime = [],
  scheduleBidding = true,
  MIN_STOCK_AMOUNT = 100,
}) => {
  const [form] = Form.useForm()

  const validateDateTimeRange = (_, value) => {
    if (!value || !Array.isArray(value) || value.length !== 2) {
      return Promise.reject(new Error('올바른 날짜 범위를 선택해주세요.'))
    }

    const [start, end] = value
    const momentStart = moment(new Date(start))
    const momentEnd = moment(new Date(end))
    if (!start || !end || !start.$isDayjsObject || !end.$isDayjsObject) {
      return Promise.reject(new Error('올바른 날짜 형식이 아닙니다.'))
    }

    if (!start.isValid() || !end.isValid()) {
      return Promise.reject(new Error('유효하지 않은 날짜입니다.'))
    }

    if (start.isSame(end)) {
      return Promise.reject(
        new Error('시작 시간과 종료 시간이 같을 수 없습니다.'),
      )
    }

    if (start.isAfter(end)) {
      return Promise.reject(
        new Error('시작 시간은 종료 시간보다 늦을 수 없습니다.'),
      )
    }

    // 중간에 disable된 시간이 포함되는지 확인
    const isDisabled = appliedTime.some(({ startDateTime, endDateTime }) => {
      const rangeStart = moment(new Date(startDateTime))
      const rangeEnd = moment(new Date(endDateTime))

      return (
        momentStart.isBetween(rangeStart, rangeEnd, null, '[]') ||
        momentEnd.isBetween(rangeStart, rangeEnd, null, '[]') ||
        rangeStart.isBetween(momentStart, momentEnd, null, '[]') ||
        rangeEnd.isBetween(momentStart, momentEnd, null, '[]')
      )
    })
    if (isDisabled) {
      return Promise.reject(
        new Error('선택한 시간 범위에 사용 불가능한 시간이 포함되어 있습니다.'),
      )
    }

    return Promise.resolve()
  }

  const onValuesChange = async (changedValues, allValues) => {
    if ('biddingScheduleType' in changedValues) {
      onStateChange({
        type: 'SET_BIDDING_SCHEDULE_TYPE',
        payload: changedValues.biddingScheduleType,
      })
    }
    if ('biddingStockType' in changedValues) {
      onStateChange({
        type: 'SET_STOCK_OPTION',
        payload: changedValues.biddingStockType,
      })
    }
    if ('dateTimeRange' in changedValues) {
      try {
        await validateDateTimeRange(null, changedValues.dateTimeRange)
        const [start, end] = changedValues.dateTimeRange || []

        onStateChange({
          type: 'SET_START_DATE_TIME',
          payload: start?.toDate(),
        })
        onStateChange({ type: 'SET_END_DATE_TIME', payload: end?.toDate() })
      } catch (errorInfo) {
        // RangePicker 값 초기화
        form.setFieldsValue({ dateTimeRange: null })
        // 상태 업데이트
        onStateChange({
          type: 'SET_START_DATE_TIME',
          payload: null,
        })
        onStateChange({ type: 'SET_END_DATE_TIME', payload: null })

        // 오류 메시지 표시
        message.error(
          errorInfo.message || '날짜 범위 설정 중 오류가 발생했습니다.',
        )
      }
    }
    if ('biddingStock' in changedValues) {
      onStateChange({
        type: 'SET_STOCK_AMOUNT',
        payload: changedValues.biddingStock,
      })
    }
  }

  const disabledDate = (current) => {
    return isDateDisabled(new Date(current), appliedTime, getRoundedNow())
  }

  const disabledTime = (current) => {
    return getDisabledTimes(new Date(current), appliedTime, {
      minutesAhead: 30,
      roundToMinutes: 30,
    })
  }

  return (
    <Form form={form} layout='vertical' onValuesChange={onValuesChange}>
      <Space direction='vertical' size='small' style={{ display: 'flex' }}>
        <Form.Item name='biddingScheduleType' className='mb-0'>
          <Radio.Group defaultValue='spot'>
            <Radio value='spot' defaultChecked>
              상시
            </Radio>
            <Tooltip
              placement='bottom'
              open={!scheduleBidding}
              title='상품 연동이 완료된 이후에는 시간 한정 입찰을 이용하실 수 있습니다.'
            >
              <Radio disabled={!scheduleBidding} value='schedule'>
                시간 한정
              </Radio>
            </Tooltip>
          </Radio.Group>
        </Form.Item>
        {state.biddingScheduleType === 'schedule' && (
          <>
            <Form.Item
              name='dateTimeRange'
              label={
                <Space>
                  시작 종료 날짜/시간
                  <Tooltip title='특정 시간대에 입찰할 수 있습니다. 동일 시간대 중복 입찰은 불가합니다.'>
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </Space>
              }
              required
            >
              <RangePicker
                showTime={{
                  format: 'HH:mm',
                  minuteStep: 30,
                }}
                format='YYYY-MM-DD HH:mm'
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                locale={locale}
                minuteStep={30}
                defaultValue={[]}
              />
            </Form.Item>

            {/* <Form.Item
              name='biddingStockType'
              label={
                <Space>
                  재고 설정
                  <Tooltip title='한정 재고를 설정하지 않으면 기존 상품 재고를 기준으로 판매됩니다.'>
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </Space>
              }
            >
              <Radio.Group defaultValue='origin'>
                <Radio value='origin'>기존 상품 재고</Radio>
                <Radio value='limited'>한정 재고</Radio>
              </Radio.Group>
            </Form.Item>

            {state.biddingStockType === 'limited' && (
              <Form.Item
                name='biddingStock'
                label='재고 수량'
                required
                rules={[
                  {
                    required: true,
                    message: '재고 수량을 입력해주세요.',
                  },
                  {
                    type: 'number',
                    min: 100,
                    message: '최소 재고량은 100개입니다.',
                  },
                ]}
              >
                <InputNumber
                  defaultValue={MIN_STOCK_AMOUNT}
                  parser={(value) => {
                    return parseInt(value, 10)
                  }}
                />
              </Form.Item>
            )} */}
          </>
        )}
      </Space>
    </Form>
  )
}

export default BiddingScheduleSection
