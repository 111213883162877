import React from 'react'
import { Card, Button, Row, Image, Typography, Col } from 'antd'

const { Text } = Typography

function ItemCard({ item, isSelected = false, onSelect = () => {} }) {
  const cardStyle = isSelected ? { border: '2px solid #1890ff' } : {}
  return (
    <Card
      className='flex flex-1 flex-row w-full h-32 cursor-pointer'
      style={cardStyle}
      onClick={() => onSelect(item?._id)}
    >
      <div className='flex flex-1 flex-row w-full'>
        <Image
          style={{ borderRadius: 8, marginRight: 20 }}
          alt={item.itemTitle}
          src={item.mainImageUris[0]} // 첫 번째 이미지를 사용
          height={80}
          width={80}
          preview={false} // 이미지 미리보기 비활성화
        />
        <Card.Meta
          style={{ marginLeft: 20 }}
          title={
            <div className='line-clamp-2 text-sm whitespace-normal'>
              {item.itemTitle}
            </div>
          }
          description={
            <div className='flex flex-col'>
              <Text>
                {`팀구매가: ${item.teamPurchasePrice.toLocaleString()}원`}
              </Text>
              <Text type='secondary'>{`상품ID: ${item._id?.toString()}`}</Text>
            </div>
          }
        />
      </div>
    </Card>
  )
}

export default ItemCard
