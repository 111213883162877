import { useEffect, useState, useMemo } from 'react'
import backendApis from '../../../utils/backendApis'
import Space from '../../atoms/Space'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import commaNumber from 'comma-number'

const animalImages = [
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Luna?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Luna?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Luna?shapeColor=69d2e7',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sam?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sam?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sam?shapeColor=69d2e7',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sheba?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sheba?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sheba?shapeColor=69d2e7',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Maggie?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Maggie?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Maggie?shapeColor=69d2e7',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Salem?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Salem?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Salem?shapeColor=69d2e7',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Rascal?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Rascal?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Rascal?shapeColor=69d2e7',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Bear?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Bear?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Bear?shapeColor=69d2e7',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Muffin?shapeColor=0a5b83',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Muffin?shapeColor=1c799f',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Muffin?shapeColor=69d2e7',
]

const BiddingItem = ({
  biddingType,
  teamPurchasePrice,
  isMySku,
  rank,
  isDisplayGuide,
}) => {
  // 해시 함수를 통해 고유 인덱스 생성
  const getHash = (str) => {
    let hash = 5381
    for (let i = 0; i < str.length; i += 1) {
      hash = hash * 33 + str.charCodeAt(i)
    }
    return Math.abs(hash)
  }

  const getAnimalImage = (id) => {
    const hash = getHash(id)
    const index = hash % animalImages.length
    return animalImages[index]
  }

  // 문제: teamPurchasePrice가 숫자인데 문자열로 변환하지 않음
  const MOCK_THUMBNAIL = getAnimalImage(
    `${teamPurchasePrice}`, // 문자열로 명시적 변환
  )

  const biddingTypeLabel = biddingType === 'auto' ? '자동 입찰' : '고정가 입찰'

  return (
    <div
      className={`flex gap-4 p-4 bg-white rounded-lg shadow-sm border ${
        isMySku ? 'border-blue-500' : 'border-gray-100'
      }`}
    >
      <div className='w-14 h-14 bg-gray-200 rounded-lg overflow-hidden flex-shrink-0'>
        <img
          src={MOCK_THUMBNAIL}
          alt='상품 썸네일'
          className='w-full h-full object-cover'
        />
      </div>

      <div className='flex-1'>
        <div className='flex items-center gap-2 mb-2'>
          {isDisplayGuide ? (
            <div className='flex items-center gap-1'>
              <span className='px-2 py-1 bg-orange-100 text-orange-600 text-xs rounded-lg'>
                인터넷 최저가
              </span>
              <Tooltip title='인터넷 최저가보다 낮은 상품에 대해서 노출 부스팅을 진행하고 있습니다. 인터넷 최저가보다 낮은 가격으로 입찰을 진행해주세요.'>
                <QuestionCircleOutlined className='text-gray-400 cursor-help' />
              </Tooltip>
            </div>
          ) : (
            <span className='px-2 py-1 bg-gray-100 text-gray-600 text-xs rounded-lg'>
              {biddingTypeLabel}
            </span>
          )}
          {isMySku && (
            <span className='px-2 py-1 bg-blue-100 text-blue-600 text-xs rounded-lg'>
              내 상품
            </span>
          )}
        </div>

        <div className='flex justify-start'>
          {!isDisplayGuide && (
            <>
              <span className='font-medium'>{rank}위</span>
              <Space width={3} />
            </>
          )}
          <span className='text-blue-500 font-bold'>
            <span className='font-medium text-black'>
              {isDisplayGuide ? '인터넷 최저가' : '입찰가'}
            </span>
            {'  '}
            {commaNumber(teamPurchasePrice)}원
          </span>
        </div>
      </div>
    </div>
  )
}

const LiveBiddingRankingSection = ({
  skuCatalogId,
  displayableCatalogPrice,
  biddingPrice,
  initialStage,
}) => {
  const [catalogTopSkuList, setCatalogTopSkuList] = useState([])

  // displayableTeamPrice 계산
  const displayableTeamPrice = useMemo(() => {
    if (!catalogTopSkuList.length) return displayableCatalogPrice

    const lowestTeamPrice = Math.min(
      ...catalogTopSkuList.map((sku) => sku.teamPurchasePrice),
    )

    return Math.min(lowestTeamPrice, displayableCatalogPrice)
  }, [catalogTopSkuList, displayableCatalogPrice])

  const isDisplayableCatalog = catalogTopSkuList.some(
    (sku) => sku.teamPurchasePrice < displayableCatalogPrice,
  )

  const priceDifference = biddingPrice - displayableTeamPrice

  useEffect(() => {
    const fetchCatalogTopSkuList = async () => {
      const res = await backendApis.getCatalogTopSkuList({
        skuCatalogId,
      })
      if (res.status === 200) {
        setCatalogTopSkuList(res.data)
      }
    }

    if (initialStage !== 1) {
      fetchCatalogTopSkuList()
    }
  }, [])

  return (
    <div className='flex flex-row flex-1 w-full h-[330px] gap-4 '>
      <div className='p-4 rounded-lg w-full h-full bg-[#F6F6FA]'>
        <div className='flex flex-col w-full'>
          <div className='flex flex-row justify-between'>
            <div className='flex justify-center items-center'>
              <span className='text-[16px] font-bold '>실시간 순위</span>
              {/* MEMO(민국): 리스트 스냅샷 시기 정보가 지금 api에 없어서 주석처리 */}
              {/* <Space width={16} />
              <span className=' text-gray-600'>2025년 1월 6일 21:05 기준</span> */}
            </div>
            <div className='bg-white rounded-lg px-2 py-1'>
              <span className='text-gray-600'>전체 입찰 상품 </span>
              <span className='text-blue-500 font-bold'>
                {catalogTopSkuList.length}
              </span>
              <span className='text-gray-600'>개</span>
            </div>
          </div>
          <Space height={12} />
          <div className='flex justify-between'>
            <div className='relative w-3/5'>
              <div className='flex flex-col gap-2 pr-2 overflow-y-auto h-[250px]'>
                {initialStage === 1 ? (
                  <>
                    {/* MEMO: 목업정보임 */}
                    <BiddingItem
                      teamPurchasePrice={
                        Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000
                      }
                    />
                    <BiddingItem
                      teamPurchasePrice={
                        Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000
                      }
                    />
                    <BiddingItem
                      teamPurchasePrice={
                        Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000
                      }
                    />
                    <BiddingItem
                      teamPurchasePrice={
                        Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000
                      }
                    />
                    <div className='absolute inset-0 bg-white/70 backdrop-blur-sm flex items-center justify-center z-10 rounded-lg'>
                      <div className='text-center p-6'>
                        <p className='text-lg font-bold text-gray-800 mb-2'>
                          입찰 정보 확인
                        </p>
                        <p className='text-gray-600'>
                          입찰 설정을 완료하면 실시간 입찰 정보를 확인할 수
                          있습니다.
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {!isDisplayableCatalog && displayableCatalogPrice !== 0 && (
                      <BiddingItem
                        key={`${displayableCatalogPrice}`}
                        isDisplayGuide
                        biddingType={null}
                        teamPurchasePrice={displayableCatalogPrice}
                        isMySku={false}
                        rank={1}
                      />
                    )}
                    {catalogTopSkuList.map((bidding, index) => {
                      return (
                        <BiddingItem
                          key={`${
                            bidding.teamPurchasePrice
                          }_${index?.toString()}`}
                          biddingType={bidding.biddingType}
                          teamPurchasePrice={bidding.teamPurchasePrice}
                          isMySku={bidding.isMySku}
                          rank={index + 1}
                        />
                      )
                    })}
                  </>
                )}
              </div>
            </div>
            <Space width={16} />
            <div className='flex flex-col gap-4 w-2/5'>
              <div className='p-4 bg-white rounded-lg shadow-sm border border-gray-100'>
                <div className='font-bold text-[16px] mb-4'>입찰 분석</div>
                <div className='space-y-3'>
                  <div className='flex flex-col items-start'>
                    <span className='text-gray-600 text-medium'>
                      노출 가능 입찰가
                    </span>
                    <span className='text-blue-500 font-bold text-[16px]'>
                      {catalogTopSkuList?.[0]?.teamPurchasePrice
                        ? Math.min(
                            catalogTopSkuList?.[0]?.teamPurchasePrice,
                            displayableCatalogPrice,
                          )?.toLocaleString()
                        : displayableCatalogPrice?.toLocaleString()}
                      원
                    </span>
                  </div>

                  <div className='flex flex-col items-start'>
                    <span className='text-gray-600'>내 고정 입찰가</span>
                    <span className='text-blue-500 font-bold text-[16px]'>
                      {biddingPrice?.toLocaleString() ?? '- '}원
                    </span>
                  </div>

                  {priceDifference > 0 && (
                    <div className='mt-2 text-sm text-gray-500'>
                      {priceDifference}원만 낮추면 노출이 가능해요!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveBiddingRankingSection
