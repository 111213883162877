import React from 'react'
import { Modal } from 'antd'
import Button from '../../../../components/atoms/button'

const AdInfoModal = ({ showModal, setShowModal, title, text }) => {
  const handleOk = () => {
    setShowModal(false)
  }

  return (
    <Modal
      open={showModal}
      closable={false}
      centered
      footer={null}
      styles={{
        content: { borderRadius: '20px' },
      }}
    >
      <div className='text-xl font-bold'>{title}</div>
      <div className='flex flex-col justify-start my-4 max-h-[720px] overflow-y-auto'>
        <div className='text-base text-[#71737C] mb-4'>{text}</div>
      </div>
      <div className='flex flex-row justify-end'>
        <Button
          onClick={handleOk}
          appearance='blueV2'
          size='md'
          className='px-6 py-3 ml-2 leading-5 whitespace-nowrap'
        >
          확인
        </Button>
      </div>
    </Modal>
  )
}

export default AdInfoModal
