import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Checkbox } from 'antd'
import Button from '../../../../components/atoms/button'
import backendApis from '../../../../utils/backendApis'

const AddAdSetModal = ({ showModal, setShowModal }) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCampaignId, setSelectedCampaignId] = useState(null)
  const [campaignsInfo, setCampaignsInfo] = useState([])

  // fetch해서 캠페인 가져와야함
  const fetchData = async () => {
    if (isLoading) return
    setIsLoading(true)

    const params = { size: 1000, page: 1 }
    const result = await backendApis.getAdCampaigns(params)
    if (result?.status === 200) {
      setCampaignsInfo(result?.data?.campaigns)
    }
    if (result?.data?.campaigns.length === 0) {
      // 캠페인 없는 경우 방어로직
      history.push('/ad/alfarm-click-ad/edit')
    }

    setIsLoading(false)
  }

  const handleOk = () => {
    setShowModal(false)
    history.push(
      '/ad/alfarm-click-ad/edit',
      JSON.parse(
        JSON.stringify({
          campaignId: selectedCampaignId,
          method: 'NEW_AD_SET',
        }),
      ),
    )
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Modal
      open={showModal}
      closable={false}
      isLoading
      centered
      footer={null}
      okText='광고세트 만들기'
      onOk={handleOk}
      styles={{
        content: { borderRadius: '20px' },
      }}
    >
      <div className='text-xl font-bold'>광고세트 만들기</div>
      <div className='text-base text-[#71737C] mb-4'>
        광고세트를 만들 캠페인을 선택해주세요
      </div>
      <div className='flex flex-col justify-start mb-4 max-h-[720px] overflow-y-auto'>
        {campaignsInfo.length > 0 &&
          campaignsInfo.map((campaign) => (
            <button
              type='button'
              className='flex flex-row flex-1 p-4 rounded-xl hover:bg-[#F6F6FA]'
              onClick={() => {
                setSelectedCampaignId(
                  selectedCampaignId === campaign._id ? null : campaign._id,
                )
              }}
            >
              <Checkbox
                checked={selectedCampaignId === campaign._id}
                className='mr-3'
              />
              <div className='flex flex-col text-left'>
                <div className='text-base font-semibold'>{campaign.name}</div>
                <div className='text-xs text-gray-500'>ID: {campaign._id}</div>
              </div>
            </button>
          ))}
      </div>
      <div className='flex flex-row justify-end'>
        <Button
          onClick={() => setShowModal(false)}
          appearance='subBlue'
          size='md'
          className='px-6 py-3 leading-5 whitespace-nowrap'
        >
          취소
        </Button>
        <Button
          onClick={handleOk}
          appearance='blueV2'
          size='md'
          className='px-6 py-3 ml-2 leading-5 whitespace-nowrap'
        >
          광고세트 만들기
        </Button>
      </div>
    </Modal>
  )
}

export default AddAdSetModal
