import { Drawer, Pagination } from 'antd'
import { StarFilled } from '@ant-design/icons'
import backendApis from '../../utils/backendApis'
import { useEffect, useState } from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
  LineChart,
} from 'recharts'

const SkuCatalogDrawer = ({ open, onClose, skuCatalogData, reviewCount }) => {
  const [priceTrend, setPriceTrend] = useState([])
  const [itemReviewTrend, setItemReviewTrend] = useState([])
  const [dataState, setDataState] = useState({
    isPriceTrendNull: false,
    isItemReviewTrendNull: false,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 5

  const {
    sku_catalog_id: skuCatalogId,
    main_image_uris: mainImageUris,
    title,
    price,
    bidding_count: biddingCount,
  } = skuCatalogData

  useEffect(() => {
    const getPriceTrend = async () => {
      // TODO: 직접 받은 skuCatalogData.id로 변경
      const res = await backendApis.getPriceTrend({
        skuCatalogIds: [skuCatalogId],
      })
      if (res.status === 204)
        setDataState((prev) => ({ ...prev, isPriceTrendNull: true }))
      if (res.status === 200 && res.data.status !== 500) {
        setPriceTrend(res.data)
      }
    }

    const getItemReviewTrend = async () => {
      const res = await backendApis.getItemReviewTrend({
        skuCatalogIds: [skuCatalogId],
        eachLimit: pageSize,
        nextCursor: (currentPage - 1) * pageSize,
      })
      if (res.status === 204)
        setDataState((prev) => ({ ...prev, isItemReviewTrendNull: true }))
      if (res.status === 200) {
        setItemReviewTrend(res.data)
      }
    }
    getPriceTrend()
    getItemReviewTrend()
  }, [skuCatalogData, currentPage])

  const priceCandleData = priceTrend?.map((item) => {
    const { day_of_month: dayOfMonth, month_number: monthNumber } =
      item.event_date
    const day = `${String(monthNumber).padStart(2, '0')}-${String(
      dayOfMonth,
    ).padStart(2, '0')}`
    return {
      day,
      price: [item.min_price, item.max_price],
      avgPrice: (item.max_price + item.min_price) / 2,
    }
  })

  const dailyImpCandleData = priceTrend?.map((item) => {
    const { day_of_month: dayOfMonth, month_number: monthNumber } =
      item.event_date
    const day = `${String(monthNumber).padStart(2, '0')}-${String(
      dayOfMonth,
    ).padStart(2, '0')}`
    return {
      day,
      dailyImp: item.imp_count,
    }
  })

  const PriceChart = ({ data }) => {
    const minPrice = Math.min(
      ...data.map((item) => Math.min(item.price[0], item.avgPrice)),
    )
    const maxPrice = Math.max(
      ...data.map((item) => Math.max(item.price[1], item.avgPrice)),
    )
    const buffer = (maxPrice - minPrice) * 0.7

    return (
      <ResponsiveContainer width='100%' height={300}>
        <ComposedChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 15 }}
        >
          <CartesianGrid
            strokeDasharray='3 3'
            stroke='#E5E7EB'
            vertical={false}
          />
          <XAxis
            dataKey='day'
            axisLine={false}
            tickLine={false}
            stroke='#4B5563'
          />
          <YAxis
            domain={[
              Math.floor(minPrice - buffer),
              Math.ceil(maxPrice + buffer),
            ]}
            axisLine={false}
            tickLine={false}
            stroke='#4B5563'
            tickFormatter={(value) => `${value.toLocaleString()}원`}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: 'white',
              borderRadius: '8px',
              border: '1px solid #E5E7EB',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                const priceRange = payload[0].value
                const avgPrice = payload[1]?.value

                return (
                  <div className='bg-white border rounded-lg p-3 shadow-lg'>
                    <p className='text-gray-600 mb-2'>{label} 기준</p>
                    <p className='text-blue-500'>
                      최저가: {priceRange[0].toLocaleString()}원
                    </p>
                    <p className='text-red-500'>
                      최고가: {priceRange[1].toLocaleString()}원
                    </p>
                    <p className='text-orange-500'>
                      평균가: {avgPrice?.toLocaleString()}원
                    </p>
                  </div>
                )
              }
              return null
            }}
          />
          <Bar dataKey='price' fill='#818CF8' radius={[0, 0, 0, 0]} />
          <Line
            type='monotone'
            dataKey='avgPrice'
            stroke='#EF4444'
            dot={{ fill: '#EF4444', r: 3 }}
            strokeWidth={2}
            activeDot={{ r: 5 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

  const ImpChart = ({ data }) => {
    return (
      <ResponsiveContainer width='100%' height={300}>
        <LineChart
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
        >
          <CartesianGrid
            strokeDasharray='3 3'
            stroke='#E5E7EB'
            vertical={false}
          />
          <XAxis
            dataKey='day'
            axisLine={false}
            tickLine={false}
            stroke='#4B5563'
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            stroke='#4B5563'
            tickFormatter={(value) => value.toLocaleString()}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: 'white',
              borderRadius: '8px',
              border: '1px solid #E5E7EB',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <div className='bg-white border rounded-lg p-3 shadow-lg'>
                    <p className='text-gray-600 mb-2'>{label} 기준</p>
                    <p className='text-blue-500'>
                      일간 노출량: {payload[0].value.toLocaleString()}
                    </p>
                  </div>
                )
              }
              return null
            }}
          />
          <Line
            type='monotone'
            dataKey='dailyImp'
            stroke='#3B82F6'
            strokeWidth={2}
            dot={{ fill: '#3B82F6', r: 3 }}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }

  const ReviewItem = ({ review, isLast }) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0',
      )}-${String(date.getDate()).padStart(2, '0')}`
    }

    return (
      <div className='px-4 bg-white rounded-lg space-y-3'>
        {/* 첫 번째 줄: 프로필 정보, 별점, 날짜 */}
        <div className='flex items-center gap-2'>
          <div className='flex items-center gap-2'>
            <div className='w-8 h-8 bg-gray-100 rounded-full overflow-hidden'>
              <img
                src={review.user_image_uri}
                alt={review.user_name}
                className='w-full h-full object-cover'
              />
            </div>
            <span className='font-bold text-[16px]'>
              {review.is_anonymous
                ? review.user_name.replace(/(?<=.{1})./g, '*')
                : review.user_name}
            </span>
            {review.nth_purchase > 1 && (
              <div className='px-2 py-1 rounded-[9px] bg-[#FFEFEA]'>
                <span className='text-sm text-[#FF6231] text-[16px]'>
                  {review.nth_purchase}번 재구매
                </span>
              </div>
            )}
          </div>
          <div className='flex items-center gap-2'>
            <div className='flex items-center gap-0.5'>
              {[1, 2, 3, 4, 5].map((star) => (
                <StarFilled
                  size={27}
                  key={star}
                  className={
                    star <= review.rating_score
                      ? 'text-yellow-400'
                      : 'text-gray-200'
                  }
                />
              ))}
            </div>
            <span className='text-gray-400 text-[16px]'>
              {formatDate(review.created_at.value$kotlinx_datetime)}
            </span>
          </div>
        </div>
        {/* 두 번째 줄: 이미지 갤러리 */}
        {review.review_image_uris.length > 0 && (
          <div className='flex gap-2'>
            {review.review_image_uris.map((uri, index) => (
              <div
                key={uri}
                className='w-[120px] h-[120px] bg-gray-100 rounded-lg overflow-hidden'
              >
                <img
                  src={uri}
                  alt={`review-${index}`}
                  className='w-full h-full object-cover'
                />
              </div>
            ))}
          </div>
        )}
        {/* 세 번째 줄: 리뷰 텍스트 */}
        {review.remark && (
          <div className='text-sm text-gray-600'>{review.remark}</div>
        )}
        {!isLast && <div className='w-full border-b border-[#EEEFF3]' />}
      </div>
    )
  }

  if (!skuCatalogData) return null

  return (
    <Drawer
      title={null}
      placement='right'
      width={720}
      onClose={onClose}
      open={open}
      destroyOnClose
    >
      <div className='space-y-8'>
        {/* 상품 기본 정보 */}
        <div className='flex gap-4'>
          <div className='w-24 h-24 bg-gray-100 rounded-lg overflow-hidden'>
            <img
              src={mainImageUris[0]}
              alt={title}
              className='w-full h-full object-cover'
            />
          </div>
          <div className='flex-1'>
            <h2 className='text-lg font-medium mb-2'>{title}</h2>
            <div className='flex items-center gap-4 text-sm'>
              <div className='flex justify-between items-center mb-2'>
                {biddingCount > 0 ? (
                  <>
                    <span className='text-gray-500 font-bold mr-2'>
                      현재 입찰 수
                    </span>
                    <span className='text-blue-500 font-bold'>
                      {biddingCount.toLocaleString()}
                    </span>
                  </>
                ) : (
                  <span className='text-gray-500'>
                    지금 처음으로 입찰하셔서 노출 부스팅을 받아보세요
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 가격 변동 Trend */}
        <div className='space-y-4'>
          <div className='flex items-center gap-2 text-white bg-blue-500 px-4 py-3 rounded-lg'>
            <span className='w-6 h-6 flex items-center justify-center bg-white text-blue-500 rounded-full font-bold'>
              1
            </span>
            <span>가격 변동 Trend</span>
            <span className='text-sm ml-2'>
              상품 가격변동 그래프를 보여드립니다.
            </span>
          </div>
          <div className='bg-white p-4 rounded-lg'>
            {priceCandleData.length > 0 ? (
              <PriceChart data={priceCandleData} />
            ) : (
              <div className='text-center py-8 h-[300px] flex flex-col items-center justify-center text-gray-500'>
                <div>현재 연동가능한 가격 변동 그래프가 없습니다</div>
              </div>
            )}
          </div>
        </div>

        {/* 노출량 Trend */}
        <div className='space-y-4'>
          <div className='flex items-center gap-2 text-white bg-blue-500 px-4 py-3 rounded-lg'>
            <span className='w-6 h-6 flex items-center justify-center bg-white text-blue-500 rounded-full font-bold'>
              2
            </span>
            <span>노출량 Trend</span>
            <span className='text-sm ml-2'>
              상품 카테고리의 노출량 변동 현황을 보여드립니다.
            </span>
          </div>
          <div className='bg-white p-4 rounded-lg'>
            {dailyImpCandleData.length > 0 ? (
              <ImpChart data={dailyImpCandleData} />
            ) : (
              <div className='text-center py-8 h-[300px] flex items-center justify-center text-gray-500'>
                현재 연동가능한 노출량 그래프가 없습니다
              </div>
            )}
          </div>
        </div>

        {/* 리뷰 정보 */}
        <div className='space-y-4'>
          <div className='flex items-center gap-2 text-white bg-blue-500 px-4 py-3 rounded-lg'>
            <span className='w-6 h-6 flex items-center justify-center bg-white text-blue-500 rounded-full font-bold'>
              3
            </span>
            <span>리뷰 정보 ({reviewCount.toLocaleString()}개)</span>
            <span className='text-sm ml-2'>
              현재 카탈로그에 묶여있는 리뷰 정보입니다.
            </span>
          </div>
          <div className='space-y-4'>
            {itemReviewTrend?.item_reviews?.length > 0 ? (
              <>
                <div className='space-y-4 mb-5'>
                  {itemReviewTrend.item_reviews.map((review, index) => (
                    <ReviewItem
                      key={review.id}
                      review={review}
                      isLast={index === itemReviewTrend.item_reviews.length - 1}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className='text-center py-8 h-[400px] flex items-center justify-center text-gray-500'>
                현재 연동가능한 리뷰가 없습니다
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default SkuCatalogDrawer
