import { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'

const useCategoryData = () => {
  // 카테고리 데이터를 배열로 관리
  const [categoryLevels, setCategoryLevels] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  // 초기 카테고리 로드
  useEffect(() => {
    const loadInitialCategories = async () => {
      try {
        const response = await backendApis.getLargeCategoryInfos()
        if (response?.status === 200 && response?.data?.length > 0) {
          setCategoryLevels([response.data]) // 첫 번째 레벨만 초기화

          // 첫 번째 카테고리 자동 선택
          const firstCategory = response.data[3]
          setSelectedCategories([firstCategory])

          // 선택된 카테고리의 하위 카테고리 로드
          if (firstCategory.children?.length > 0 || !firstCategory.endNode) {
            await loadChildCategories(firstCategory._id, 0)
          }
        }
      } catch (error) {
        console.error('Failed to load initial categories:', error)
      }
    }

    loadInitialCategories()
  }, [])

  // 특정 레벨의 하위 카테고리 로드
  const loadChildCategories = async (categoryId, level) => {
    try {
      const parent = await backendApis.getCategoryInfoById(categoryId)
      if (parent?.status === 200 && parent?.data?.children) {
        // level 이후의 모든 카테고리 제거하고 새로운 하위 카테고리 추가
        setCategoryLevels((prev) => {
          const newLevels = prev.slice(0, level + 1)
          if (parent.data.children.length > 0) {
            newLevels.push(parent.data.children)
          }
          return newLevels
        })
      }
    } catch (error) {
      console.error('Failed to load child categories:', error)
    }
  }

  const handleCategorySelect = async (category, level) => {
    // 선택된 카테고리 업데이트
    setSelectedCategories((prev) => {
      const newSelected = prev.slice(0, level + 1)
      newSelected[level] = category
      return newSelected
    })

    // 마지막 카테고리가 아니면 하위 카테고리 로드
    if (category.children?.length > 0 || !category.endNode) {
      await loadChildCategories(category._id, level)
    }
  }

  const resetCategories = () => {
    setCategoryLevels((prev) => [prev[0]]) // 첫 번째 레벨만 유지
    setSelectedCategories([])
  }

  return {
    categoryLevels,
    selectedCategories,
    handleCategorySelect,
    resetCategories,
    isLastCategory: (category) =>
      !category.children?.length || category.endNode,
  }
}

export default useCategoryData
