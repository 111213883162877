import React from 'react'
import Button from '../../../../components/atoms/button'

const AdCreateButton = ({ activeTab, onClickCampaign, onClickAdSet }) => {
  switch (activeTab) {
    case 'campaign':
      return (
        <Button
          onClick={onClickCampaign}
          appearance='blueV2'
          size='md'
          className='px-6 py-3 mr-3 leading-5 whitespace-nowrap'
        >
          + 캠페인 만들기
        </Button>
      )
    case 'adSet':
      return (
        <Button
          onClick={onClickAdSet}
          appearance='blueV2'
          size='md'
          className='px-6 py-3 mr-3 leading-5 whitespace-nowrap'
        >
          + 광고세트 만들기
        </Button>
      )
    case 'adUnit':
      return <div />
    default:
      return <div />
  }
}

export default AdCreateButton
