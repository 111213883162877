import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'
import queryString from 'query-string'
import Button from '../components/atoms/button'

const TIME_TO_VERIFY = 180 // 인증만료시간 3분
const TIME_TO_RETRY = 30 // 인증 재시도 대기시간 30초

const RegisterSellerScreen = observer(() => {
  const history = useHistory()

  const [sellerName, setSellerName] = useState('') // 아이디
  const [password, setPassword] = useState('') // 비밀번호
  const [representorName, setRepresentorName] = useState('') // 이름
  const [email, setEmail] = useState('') // 이메일
  const [phoneNumber, setPhoneNumber] = useState('') // 휴대폰 번호

  // 휴대폰 번호 문자 인증
  const [isVerificationNumberSent, setIsVerificationNumberSent] =
    useState(false)
  const [leftTimeToVerify, setLeftTimeToVerify] = useState(TIME_TO_VERIFY)
  const [leftTimeToRetry, setLeftTimeToRetry] = useState(TIME_TO_RETRY)
  const [inputVerificationCode, setInputVerificationCode] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [showVerificationInput, setShowVerificationInput] = useState(false)
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)

  // 이용약관 동의
  const [isAdultAgreed, setIsAdultAgreed] = useState(false)
  const [isSellerTermsAgreed, setIsSellerTermsAgreed] = useState(false)
  const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false)

  useEffect(() => {
    let timer
    if (isVerificationNumberSent && leftTimeToVerify > 0) {
      timer = setInterval(() => {
        setLeftTimeToVerify((prev) => prev - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [isVerificationNumberSent, leftTimeToVerify])

  useEffect(() => {
    let timer
    if (isVerificationNumberSent && leftTimeToRetry > 0) {
      timer = setInterval(() => {
        setLeftTimeToRetry((prev) => prev - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [isVerificationNumberSent, leftTimeToRetry])

  function validateId(id) {
    const pattern = /^[a-z0-9_.-]{6,20}$/
    return pattern.test(id)
  }

  function validatePassword(password) {
    const pattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/
    return pattern.test(password)
  }

  const handleSendVerificationCode = async () => {
    setInputVerificationCode('')
    if (!phoneNumber) {
      alert('휴대폰 번호를 입력해주세요.')
      return
    }
    if (phoneNumber?.length > 8 && phoneNumber.slice(0, 2) === '01') {
      const result = await backendApis.sendVerificationCode(phoneNumber)
      if (result?.status === 200 && result?.data?.verificationCode) {
        alert('인증번호가 문자로 발송되었습니다.')
        setIsVerificationNumberSent(true)
        setLeftTimeToVerify(TIME_TO_VERIFY)
        setLeftTimeToRetry(TIME_TO_RETRY)
        setShowVerificationInput(true)
        setVerificationCode(result.data.verificationCode)
      } else {
        alert('인증번호 전송에 실패했습니다.\n잠시 후 다시 시도해주세요.')
      }
    } else {
      alert('정확한 휴대전화 번호를 입력해주세요.')
    }
  }

  return (
    <div className='flex flex-col items-center bg-gradient-to-r from-red-500 to-red-400 py-12'>
      <div className='flex flex-col items-center min-w-[480px] prose text-center bg-white rounded-xl'>
        <div className='flex flex-col justify-center'>
          <h2>
            <span className='text-theme-500'>올웨이즈</span>와 함께 하기 위해
            <br />
            입점사 신청을 부탁드려요
          </h2>

          {/* 아이디 */}
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            아이디
          </span>
          <input
            className='mb-1 bg-red-100 rounded-md h-[48px] w-full'
            type='text'
            placeholder='아이디'
            value={sellerName}
            onChange={(e) => {
              setSellerName(e.target.value)
            }}
          />
          {validateId(sellerName) ? (
            <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-blue-500'>
              사용 가능한 아이디입니다.
            </span>
          ) : (
            <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-red-500'>
              *6~20자의 영문 소문자, 숫자와 특수문자(_, -, .)만 사용 가능
            </span>
          )}

          {/* 비밀번호 */}
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            비밀번호
          </span>
          <input
            className='mb-1 bg-red-100 rounded-md h-[48px]'
            type='password'
            placeholder='비밀번호'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-red-500'>
            {validatePassword(password) ? (
              <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-blue-500'>
                사용 가능한 비밀번호입니다.
              </span>
            ) : (
              <span className='flex flex-row justify-start flex-1 mb-2 text-xs text-red-500'>
                *8-15자 사이 / 영문, 숫자, 특수문자를 모두 포함
              </span>
            )}
          </span>

          {/* 이름 */}
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            이름
          </span>
          <input
            className='mb-2 bg-red-100 rounded-md h-[48px]'
            type='text'
            value={representorName}
            placeholder='이름'
            onChange={(e) => {
              setRepresentorName(e.target.value)
            }}
          />

          {/* 이메일 */}
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            이메일
          </span>
          <input
            className='mb-2 bg-red-100 rounded-md h-[48px]'
            type='email'
            value={email}
            placeholder='이메일'
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />

          {/* 휴대폰 번호 */}
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            휴대폰 번호
          </span>
          <div className='flex gap-2 mb-2'>
            <input
              className='flex-1 bg-red-100 rounded-md h-[48px]'
              type='text'
              value={phoneNumber}
              placeholder='예시: 01012345678'
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
              disabled={isPhoneVerified}
            />
            {isPhoneVerified ? (
              <button
                className='w-[100px] text-white rounded-md bg-theme-500 opacity-60 text-sm'
                disabled
              >
                인증완료
              </button>
            ) : isVerificationNumberSent && leftTimeToRetry > 0 ? (
              <button
                className='w-[100px] text-white rounded-md bg-theme-500 opacity-60 text-sm'
                disabled
              >
                {`전송됨 (${
                  leftTimeToRetry < 10 ? `0${leftTimeToRetry}` : leftTimeToRetry
                }초)`}
              </button>
            ) : (
              <button
                className={`w-[100px] text-white rounded-md bg-theme-500 ${
                  !phoneNumber ? 'opacity-60' : ''
                } text-sm`}
                onClick={handleSendVerificationCode}
                disabled={!phoneNumber}
              >
                {isVerificationNumberSent ? '재전송' : '인증요청'}
              </button>
            )}
          </div>

          {/* 인증완료 전 */}
          {showVerificationInput && !isPhoneVerified && (
            <div className='flex gap-2 mb-2'>
              <input
                className='flex-1 bg-red-100 rounded-md h-[48px]'
                type='text'
                value={inputVerificationCode}
                placeholder='인증번호 입력'
                onChange={(e) => setInputVerificationCode(e.target.value)}
              />
              <button
                className='w-[100px] text-white rounded-md bg-theme-500 whitespace-nowrap text-sm'
                onClick={() => {
                  if (leftTimeToVerify <= 0) {
                    alert('인증 시간이 만료되었습니다. 다시 시도해주세요.')
                    setInputVerificationCode('')
                    setVerificationCode('')
                    return
                  }
                  if (inputVerificationCode === verificationCode) {
                    setIsPhoneVerified(true)
                    setShowVerificationInput(false) // 인증 완료 시 입력칸 숨기기
                    alert('인증이 완료되었습니다.')
                  } else {
                    alert('인증번호가 일치하지 않습니다.')
                  }
                }}
              >
                {(() => {
                  if (leftTimeToVerify <= 0) return '인증 만료'

                  const minutes = Math.floor(leftTimeToVerify / 60)
                  const seconds = leftTimeToVerify % 60
                  return `인증하기 (${minutes}:${
                    seconds < 10 ? `0${seconds}` : seconds
                  })`
                })()}
              </button>
            </div>
          )}

          {isPhoneVerified && (
            <span className='text-blue-500 text-sm'>
              휴대폰 인증이 완료되었습니다
            </span>
          )}

          {/* 이용약관 동의 */}
          <div className='flex flex-col gap-4 mb-4 mt-6'>
            <div className='flex items-center'>
              <input
                type='checkbox'
                className='mr-2'
                checked={
                  isAdultAgreed && isSellerTermsAgreed && isPrivacyAgreed
                }
                onChange={(e) => {
                  setIsAdultAgreed(e.target.checked)
                  setIsSellerTermsAgreed(e.target.checked)
                  setIsPrivacyAgreed(e.target.checked)
                }}
              />
              <span className='font-bold'>모두 동의합니다</span>
            </div>

            <div className='border rounded-md p-4 flex flex-col gap-3'>
              <div className='flex items-center'>
                <input
                  type='checkbox'
                  className='mr-2'
                  checked={isAdultAgreed}
                  onChange={(e) => {
                    setIsAdultAgreed(e.target.checked)
                  }}
                />
                <span>[필수] 만 19세 이상입니다</span>
              </div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    className='mr-2'
                    checked={isSellerTermsAgreed}
                    onChange={(e) => setIsSellerTermsAgreed(e.target.checked)}
                  />
                  <span>[필수] 올웨이즈 판매자 이용약관 동의</span>
                </div>
                <button
                  type='button'
                  className='text-blue-500 text-xs underline ml-4'
                  onClick={() => {
                    window.open(
                      'https://placid-hoverfly-3d1.notion.site/9a0d458e0a3e48baa216654a1abfcc59',
                      '_blank',
                    )
                  }}
                >
                  전체보기
                </button>
              </div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    className='mr-2'
                    checked={isPrivacyAgreed}
                    onChange={(e) => setIsPrivacyAgreed(e.target.checked)}
                  />
                  <span>[필수] 개인정보 수집 및 이용 동의</span>
                </div>
                <button
                  type='button'
                  className='text-blue-500 text-xs underline ml-4'
                  onClick={() => {
                    window.open(
                      'https://levitinc.notion.site/80a85d0e23904e159b47c8cddbee2dac',
                      '_blank',
                    )
                  }}
                >
                  전체보기
                </button>
              </div>
            </div>
          </div>

          {/* 최종 가입 버튼 */}
          <button
            className='py-4 px-16 mb-10 text-xl font-black text-white rounded-xl bg-theme-500'
            type='button'
            onClick={async () => {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

              if (!sellerName) {
                alert('아이디를 입력해주세요.')
                return
              }
              if (!password) {
                alert('비밀번호를 입력해주세요.')
                return
              }
              if (!representorName) {
                alert('이름을 입력해주세요.')
                return
              }
              if (!email) {
                alert('이메일을 입력해주세요.')
                return
              }
              if (!phoneNumber) {
                alert('휴대폰 번호를 입력하고 인증해주세요.')
                return
              }
              if (!isPhoneVerified) {
                alert('휴대폰 번호 인증을 완료해주세요.')
                return
              }
              if (!validateId(sellerName)) {
                alert(
                  '아이디 형식이 올바르지 않습니다.\n공백 또는 한글은 허용되지 않습니다.',
                )
                return
              }
              if (!validatePassword(password)) {
                alert(
                  '비밀번호 형식이 올바르지 않습니다.\n8자 - 15자 사이, 영문, 숫자, 특수문자를 포함해주세요.',
                )
                return
              }
              if (!emailRegex.test(email)) {
                alert('이메일 형식이 올바르지 않습니다.')
                return
              }
              if (!isAdultAgreed || !isSellerTermsAgreed || !isPrivacyAgreed) {
                alert('이용약관에 모두 동의해주세요.')
                return
              }
              if (SellerStore.isLoading) {
                return
              }
              SellerStore.setIsLoading(true)

              const termsAgreed =
                isAdultAgreed && isSellerTermsAgreed && isPrivacyAgreed

              const result = await backendApis.registerSeller(
                sellerName, // 아이디
                password, // 비밀번호
                representorName, // 이름
                email, // 이메일
                phoneNumber?.replace(/[-\s]/g, ''), // 휴대폰 번호
                termsAgreed, // 이용약관 동의
              )
              if (result?.status === 200) {
                alert(
                  `올웨이즈 판매자센터 가입이 완료되었습니다.\n이메일에서 입점 절차 및 매출 활성화 가이드를 확인 부탁드립니다.`,
                )
                SellerStore.setIsLoading(false)
                setSellerName('')
                setPassword('')
                setRepresentorName('')
                setEmail('')
                setPhoneNumber('')

                AuthStore.setToken(result.data.token)
                localStorage.setItem('@alwayz@seller@token@', result.data.token)

                const loadSellerResult = await SellerStore.loadSellerInfo()
                if (loadSellerResult) {
                  SellerStore.setIsLoading(false)
                  history.replace('/register-basics')
                } else {
                  alert(
                    '정보를 불러오는데 실패했습니다.\n잠시 후 다시 시도해주세요.',
                  )
                }
              } else if (result.status === 401) {
                alert(
                  '이미 존재하는 아이디입니다.\n다른 아이디를 사용해주세요.',
                )
              } else {
                alert('등록에 실패했습니다.\n잠시 후 다시 시도해주세요.')
              }

              SellerStore.setIsLoading(false)
            }}
          >
            올웨이즈 판매자센터 가입하기
          </button>
        </div>
      </div>
    </div>
  )
})

export default RegisterSellerScreen
