import React from 'react'
import { Button, Row, Col, Pagination, InputNumber, message } from 'antd'
import RadioGroup from '../../../../components/ADS/molecules/radioGroup'
import ItemSearchBox from '../../../../components/molecules/itemSearchBox'
import SquareItemCard from '../../../../components/ADS/molecules/SquareItemCard'

const Stage2ProductSelection = ({
  dispatch,
  gender,
  customAge,
  minAge,
  maxAge,
  candidateItems,
  setCandidateItems,
  totalCount,
  setTotalCount,
  page,
  setPage,
  selectedItems,
  handleItemSelect,
  renderSelectedItems,
  handleNextStage,
  handlePrevStage,
}) => {
  const selectedItemIds = selectedItems.map((item) => item._id)
  const limit = 9
  const genderOptions = [
    { label: '전체', value: 'all' },
    { label: '남자', value: 'male' },
    { label: '여자', value: 'female' },
  ]

  const ageOptions = [
    {
      label: '전체',
      value: 'all',
    },
    {
      label: '지정',
      value: 'custom',
    },
  ]

  const handleGenderChange = (e) => {
    dispatch({ type: 'SET_GENDER', payload: e.target.value })
  }

  const handleCustomAgeChange = (e) => {
    dispatch({ type: 'SET_CUSTOM_AGE', payload: e.target.value })
  }

  const handleMinAgeChange = (value) => {
    dispatch({ type: 'SET_MIN_AGE', payload: value })
  }

  const handleMaxAgeChange = (value) => {
    dispatch({ type: 'SET_MAX_AGE', payload: value })
  }

  const handleNext = () => {
    if (customAge === 'custom' && maxAge < minAge) {
      message.error('최대 연령이 최소 연령보다 커야 합니다')
    } else if (selectedItems.length < 3) {
      message.error('최소 3개 이상의 상품을 선택해주세요')
    } else {
      handleNextStage(3)
    }
  }

  return (
    <div className='mb-20 text-slate-700'>
      <div className='flex flex-col items-start flex-1 p-12 mb-5 bg-white shadow-lg rounded-xl'>
        <div className='mb-4 font-bold text-lg'>광고 노출 타겟팅 설정</div>
        <p className='mb-12'>
          상품을 노출할 성별을 지정할 수 있어요.
          <br />
          구매가능성이 있는 유저에게는 추가 노출이 될 수 있어요.
        </p>

        <div className='mb-6'>
          <h4 className='mb-2 font-bold'>성별</h4>
          <RadioGroup
            options={genderOptions}
            value={gender}
            onChange={handleGenderChange}
          />
        </div>

        {/* <div>
          <div className='flex flex-1 flex-row gap-8 items-end h-full'>
            <div>
              <div className='mb-2 font-bold'>연령</div>
              <RadioGroup
                options={ageOptions}
                value={customAge}
                onChange={handleCustomAgeChange}
              />
            </div>
            {customAge === 'custom' && (
              <>
                <div className='flex items-center gap-4'>
                  <div className='flex flex-col items-start'>
                    <div className='mb-2 font-bold'>최소 연령</div>
                    <InputNumber
                      min={0}
                      value={minAge}
                      onChange={handleMinAgeChange}
                      size='large'
                      placeholder='ex) 20'
                      className='w-24'
                    />
                  </div>
                  <div>
                    <div className='flex flex-col items-start'>
                      <div className='mb-2 font-bold'>최대 연령</div>
                      <InputNumber
                        min={minAge}
                        value={maxAge}
                        size='large'
                        onChange={handleMaxAgeChange}
                        placeholder='ex) 30'
                        className='w-24'
                      />
                    </div>{' '}
                  </div>
                </div>
              </>
            )}
          </div>
        </div> */}
      </div>
      <div className='flex flex-row items-start flex-1 gap-2'>
        <div className='flex flex-col items-start flex-1 p-12 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='grid grid-cols-2 gap-8 w-full mb-12'>
            <div>
              <div className='mb-4 font-bold text-lg'>
                광고를 집행할 상품을 선택해주세요
              </div>
              <p className='font-bold'>
                신청한 상품들은 각 고객에 맞춰 개별 상품 단위로 따로 노출돼요.
              </p>
              <p>
                <span className='text-red-600 font-bold'>
                  최소 3개, 최대 50개
                </span>
                의 상품을 선택할 수 있어요.
              </p>
              <p>성인 용품, 과장/과대 광고 상품은 신청할 수 없어요.</p>
            </div>
            <div>
              <ItemSearchBox
                page={page}
                setPage={setPage}
                limit={limit}
                onSearch={(v) => setCandidateItems(v)}
                setTotalCount={setTotalCount}
                initialSearch
              />
            </div>
          </div>
          <div className='flex flex-col  w-full flex-1'>
            <Row gutter={[16, 16]}>
              {candidateItems.map((item) => (
                <Col xs={24} sm={12} md={8} lg={8} key={item._id}>
                  <SquareItemCard
                    itemInfo={item}
                    isSelected={selectedItemIds.includes(item._id)}
                    onSelect={() => handleItemSelect(item)}
                  />
                </Col>
              ))}
            </Row>
            <Pagination
              className='mt-8 flex flex-1 flex-row justify-center items-center'
              total={totalCount}
              pageSize={limit}
              current={page}
              showSizeChanger={false}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>
        {renderSelectedItems()}
      </div>
      <div className='mt-4 gap-5 flex justify-end w-full'>
        <Button
          className='bg-white w-[150px]'
          size='large'
          onClick={() => handlePrevStage(1)}
        >
          이전
        </Button>
        <Button
          type='primary'
          size='large'
          className='w-[150px]'
          onClick={handleNext}
          disabled={selectedItems.length < 3}
        >
          다음
        </Button>
      </div>
    </div>
  )
}

export default Stage2ProductSelection
