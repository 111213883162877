import Button from '../atoms/button'
import backendApis from '../../utils/backendApis'
import React, { useEffect, useState } from 'react'
import Format from '../../utils/format'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import adSettingByTab from '../../data/adSettingByTab'
import RankIconSetter from './rankIconSetter'
import commaNumber from 'comma-number'
import Tooltip from '../ADS/atoms/tooltip'
import { GrClose as CloseIcon } from 'react-icons/gr'
import { FcHighPriority as WarnIcon } from 'react-icons/fc'
import SellerQuest from '../../utils/sellerQuest'

let isNLPRequestPending = false

const roasSetter = (itemRank) => {
  if (itemRank === 'S') {
    return 700
  }
  if (itemRank === 'A') {
    return 400
  }
  if (itemRank === 'B') {
    return 300
  }
  return 300
}

const limitSetter = (itemRank) => {
  if (itemRank === 'S') {
    return 800
  }
  if (itemRank === 'A') {
    return 600
  }
  if (itemRank === 'B') {
    return 350
  }
  return 350
}

const exporsureRatioSetter = (roas, recommendedRoas) => {
  if (roas === 0 || !roas || !Number.isSafeInteger(roas)) {
    return 0
  }
  if (parseInt((recommendedRoas / roas) * 100 * 0.5, 10) > 100) {
    return 100
  }
  return parseInt((recommendedRoas / roas) * 100 * 0.5, 10)
}

const barColorSetter = (ratio) => {
  if (ratio < 50) {
    return 'bg-red-500'
  }
  if (ratio < 80) {
    return 'bg-green-500'
  }
  return 'bg-blue-500'
}

const SearchAdApplyModal = observer(
  ({
    itemInfo,
    tab,
    status,
    showModal,
    setShowModal,
    callbackFunction = () => {},
  }) => {
    const itemRank = itemInfo?.rankInfo?.itemRank
    const [creditInfo, setCreditInfo] = useState({})
    const [isRecommendedRoas, setIsRecommendedRoas] = useState(true)
    const [roas, setRoas] = useState(() => roasSetter(itemRank))
    const [recommendedKeywords, setRecommendedKeywords] = useState([])
    const [keywords, setKeywords] = useState([])
    const [dailyCostLimit, setDailyCostLimit] = useState()
    const [adInfo, setAdInfo] = useState([])
    const cleanUp = () => {
      setKeywords([])
      setRecommendedKeywords([])
      setDailyCostLimit()
      setAdInfo([])
      setIsRecommendedRoas(true)
    }
    const adExposureRatio = exporsureRatioSetter(roas, roasSetter(itemRank))
    const barColor = barColorSetter(adExposureRatio)
    const wordSimAndAvgBiddingPriceChecker = async (itemTitle, keyword) => {
      const result = await backendApis.getWordNLPSimAndAvgBiddingPrice(
        itemTitle,
        keyword,
      )
      const nlpSimilarity = result?.data?.nlpSimilarity || 0
      return { nlpSimilarity }
    }

    const addKeyword = (keyword) => {
      if (SellerStore.isLoading) return
      SellerStore.setIsLoading(true)

      if (!keyword?.keyword) {
        alert('키워드를 입력해주세요.')
        SellerStore.setIsLoading(false)
        return
      }
      if (keywords.filter((e) => e.keyword === keyword?.keyword).length > 0) {
        alert('이미 추가한 키워드입니다.')
        SellerStore.setIsLoading(false)
        return
      }
      if (keywords.length >= 10) {
        alert('광고 키워드는 최대 10개까지 설정하실 수 있습니다.')
      } else {
        setKeywords([...keywords, keyword])
      }

      SellerStore.setIsLoading(false)
    }

    useEffect(() => {
      const getAdCreditInfo = async () => {
        const result = await backendApis.getAdCreditInfo()
        if (result?.status === 2000) {
          setCreditInfo(result?.data)
        }
      }
      getAdCreditInfo()
    }, [])

    useEffect(() => {
      if (!showModal || SellerStore?.isLoading) return
      SellerStore.setIsLoading(true)
      const roasTemp = roasSetter(itemInfo?.rankInfo?.itemRank)
      setRoas(roasTemp)
      const getRecommendedKeywords = async (itemTitle, smallCategoryId) => {
        const result = await backendApis.getRecommendedKeywords(
          itemTitle,
          smallCategoryId,
        )
        if (result?.status === 2000) {
          setRecommendedKeywords([...result?.data])
          if (status === 'candidate') {
            setKeywords([...result?.data])
          }
        } else {
          setRecommendedKeywords([])
        }
      }
      const getAdInfo = async (itemId, adType) => {
        const result = await backendApis.getItemAd(itemId, adType)
        if (result?.status === 200) {
          setIsRecommendedRoas(
            result?.data?.inputRoas ===
              roasSetter(itemInfo?.rankInfo?.itemRank),
          )
          setRoas(result?.data?.inputRoas)
          setKeywords(result?.data?.detailInfo?.keywords)
          setDailyCostLimit(result?.data?.detailInfo?.dailyCostLimit)
          setAdInfo(result?.data)
        } else {
          setIsRecommendedRoas(true)
          setRoas(roasSetter(itemInfo?.rankInfo?.itemRank))
          setKeywords([])
          setDailyCostLimit()
          setAdInfo([])
        }
      }

      if (itemInfo?.itemTitle && itemInfo?.categoryInfo?.smallCategoryId) {
        getRecommendedKeywords(
          itemInfo?.itemTitle,
          parseInt(itemInfo?.categoryInfo?.smallCategoryId, 10),
        )
      }

      if (itemInfo?._id && status === 'complete') {
        getAdInfo(itemInfo?._id, adSettingByTab?.[tab]?.type)
      }

      SellerStore.setIsLoading(false)
    }, [showModal, itemInfo?._id, itemInfo?.itemTitle, itemInfo?.rankInfo])

    return (
      <>
        {showModal ? (
          <>
            <div className='fixed inset-0 z-50 flex flex-col w-full max-w-xl max-h-screen mx-auto my-auto overflow-y-scroll bg-white border-0 rounded-lg shadow-lg outline-none h-fit'>
              {/* header */}
              <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                <h3 className='text-xl font-semibold text-black'>
                  {itemInfo?.itemTitle}
                </h3>
                <Button
                  appearance='transparent'
                  className='py-3 px-3 my-[-6px] rounded-md hover:bg-gray-100'
                  onClick={() => {
                    cleanUp()
                    setShowModal(false)
                    SellerStore.setIsLoading(false)
                  }}
                >
                  <CloseIcon className='w-4 h-4 ' />
                </Button>
              </div>

              {/* body */}
              <div className='flex flex-col px-10 py-5'>
                <div className='flex flex-row items-center justify-start flex-1 p-5 bg-slate-100 rounded-xl'>
                  <RankIconSetter
                    itemRank={itemRank}
                    className='w-10 h-10 mr-3'
                  />
                  <div className='flex flex-col items-start'>
                    <div className='flex flex-row items-center flex-1'>
                      {itemRank}등급 상품의 추천 ROAS는{' '}
                      <span className='ml-1 font-bold text-blue-500'>
                        {commaNumber(roasSetter(itemRank))}%
                      </span>
                      입니다.
                    </div>
                    <div className='text-sm text-gray-500'>
                      {itemRank}등급 상품은 최대{' '}
                      {commaNumber(limitSetter(itemRank))}% 까지 조정하실 수
                      있습니다.
                    </div>
                    <div className='text-sm text-gray-500'>
                      등급이 높을수록 광고효율이 좋아집니다.
                    </div>
                  </div>
                </div>

                <div className='flex flex-col justify-center flex-1 my-4 select-none items-between'>
                  <div className='flex flex-row items-center flex-1 mb-2 text-xl font-bold'>
                    1. ROAS 설정
                    <Tooltip
                      text='💡 ROAS란, 광고비 대비 매출을 의미합니다.<br/>(ex. ROAS
                            600%이면 투입한 광고 비용의 <br/>6배만큼 매출이 발생한 것입니다.)<br/>
                            광고 신청후 상품 등급이 변경되면, 변경된 등급의 ROAS 기준으로 크레딧 차감이 이루어질 수 있습니다.<br/>(ex. A등급 상품이 광고신청 후 B등급으로 바뀌면, B등급의 최대 ROAS인 350%을 기준으로 차감됩니다.)'
                    />
                  </div>
                  <div className='mb-2'>
                    추천 ROAS보다 낮게 설정할 경우{' '}
                    <span className='font-bold text-blue-500'>광고 노출도</span>
                    가 상승합니다.
                  </div>
                  <div className='flex flex-row items-center flex-1 '>
                    <div className='w-1/2 p-5 bg-slate-100 rounded-xl'>
                      <div className='flex flex-row justify-between flex-1'>
                        <div className=' whitespace-nowrap'>
                          판매당 광고비
                          <Tooltip text='상품 옵션 중 최저가를 기준으로 계산되었으며, 부가세(VAT)가 포함된 값입니다.' />
                        </div>
                        <div className='ml-2'>
                          ￦
                          {commaNumber(
                            Math.floor(
                              (100 / roas) *
                                parseInt(itemInfo?.teamPurchasePrice, 10),
                            ) +
                              Math.floor(
                                Math.floor(
                                  (100 / roas) *
                                    parseInt(itemInfo?.teamPurchasePrice, 10),
                                ) * 0.1,
                              ) || 0,
                          )}
                        </div>
                      </div>
                      <div className='flex flex-row items-center justify-between flex-1'>
                        <div>노출도</div>
                        <div className='flex flex-1 w-40 h-4 ml-5 overflow-hidden bg-white rounded-lg'>
                          <div
                            className={`items-center h-4 text-white transition-all duration-500 ease-in-out ${barColor} place-items-center`}
                            style={{
                              width: `${adExposureRatio}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col items-start justify-center ml-5'>
                      <div className='flex flex-row flex-1 mb-2'>
                        <label
                          htmlFor='recommendedRoas'
                          className='flex flex-row items-center justify-center mr-3'
                        >
                          <input
                            type='radio'
                            id='recommendedRoas'
                            checked={isRecommendedRoas}
                            onChange={() => {
                              setIsRecommendedRoas(true)
                              setRoas(roasSetter(itemRank))
                            }}
                          />
                          <span className='ml-2 mr-5'>추천 ROAS</span>
                        </label>
                        <div
                          className={`text-xl font-bold ${
                            isRecommendedRoas
                              ? 'text-blue-500'
                              : 'text-slate-200'
                          }`}
                        >
                          {commaNumber(roasSetter(itemRank))}%
                        </div>
                      </div>
                      <div className='flex flex-row items-center justify-center'>
                        <label
                          htmlFor='roas'
                          className='flex flex-row items-center justify-center mr-5'
                        >
                          <input
                            type='radio'
                            id='roas'
                            checked={!isRecommendedRoas}
                            onChange={() => {
                              setIsRecommendedRoas(false)
                            }}
                          />
                          <span className='ml-2'>직접 입력</span>
                        </label>
                        <input
                          type='number'
                          min={100}
                          className='block w-24 pl-2 pr-2 border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500 sm:text-sm'
                          onFocus={() => setIsRecommendedRoas(false)}
                          step={10}
                          placeholder={`ex.${commaNumber(
                            roasSetter(itemInfo?.rankInfo?.itemRank),
                          )}%`}
                          onChange={(e) => {
                            if (!isRecommendedRoas) {
                              if (e.target.value > limitSetter(itemRank)) {
                                setRoas(limitSetter(itemRank))
                                return
                              }
                              setRoas(parseInt(e.target.value, 10))
                            }
                          }}
                          value={isRecommendedRoas ? '' : roas}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-2 '>
                    {roas > roasSetter(itemInfo?.rankInfo?.itemRank) && (
                      <>
                        <div className='flex flex-row items-center flex-1 text-sm text-gray-500'>
                          <WarnIcon className='mr-1 ' />
                          신청 ROAS가 추천 ROAS보다 높습니다. 광고 노출도가
                          하락할 수 있습니다.
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className='flex flex-col justify-center flex-1 my-4'>
                  <div className='mb-2 text-xl font-bold'>
                    2. 광고 키워드 설정
                  </div>
                  <div className='mb-4'>
                    <div>
                      광고가 노출될 키워드를{' '}
                      <span className='font-bold text-blue-500'>최대 10개</span>
                      까지 입력해주세요.
                    </div>
                    <div>
                      미입력시에도 신청 가능하며 신청 후 변경이 가능합니다.
                    </div>
                    <div className='text-sm text-gray-500'>
                      * 관련도가 낮으면 빨간색으로 표시되며, 광고가 노출되지
                      않습니다.
                      <br /> 미리 입력되어 있는 키워드는 추천 키워드입니다.
                    </div>
                  </div>

                  <div className='flex flex-row flex-1'>
                    <form
                      onSubmit={async (e) => {
                        if (isNLPRequestPending) {
                          alert('이전 요청이 아직 완료되지 않았습니다.')
                          return
                        }
                        e.preventDefault()
                        const keyword = Format.Title(
                          e.target[0].value.normalize('NFC'),
                        )

                        if (!keyword) {
                          alert('키워드를 입력해주세요.')
                          SellerStore.setIsLoading(false)
                          return
                        }

                        try {
                          isNLPRequestPending = true
                          const { nlpSimilarity } =
                            await wordSimAndAvgBiddingPriceChecker(
                              itemInfo?.itemTitle,
                              keyword,
                            )
                          addKeyword({ keyword, nlpSimilarity })
                          e.target[0].value = ''
                        } catch (err) {
                          alert('알 수 없는 에러가 발생했어요.')
                        } finally {
                          isNLPRequestPending = false
                        }
                      }}
                    >
                      <div className='flex flex-row flex-1'>
                        <input
                          type='text'
                          disabled={keywords?.length > 10}
                          className='block w-full pl-2 pr-3 mr-3 border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500'
                          placeholder='키워드를 입력해주세요'
                        />
                        <Button
                          appearance='neutral'
                          disabled={keywords?.length > 10}
                          className='p-2 text-white whitespace-nowrap bg-slate-500 rounded-xl'
                          type='submit'
                        >
                          추가
                        </Button>
                      </div>
                    </form>
                  </div>

                  {recommendedKeywords?.filter((e) => !keywords.includes(e))
                    .length > 0 && (
                    <div className='flex-col items-start flex-1 p-5 mt-3 bg-slate-100 rounded-xl'>
                      <div className='text-gray-500 '>추천 키워드</div>
                      {recommendedKeywords
                        .filter((e) => !keywords.includes(e))
                        ?.map((item) => {
                          return (
                            <button
                              type='button'
                              className='px-2 py-1 my-1 mr-2 font-semibold text-white rounded-lg hover:bg-sub-400 bg-slate-400 whitespace-nowrap'
                              onClick={() => addKeyword(item)}
                            >
                              + {item?.keyword}
                            </button>
                          )
                        })}
                    </div>
                  )}

                  <div className='flex flex-row mt-2'>
                    <div className='items-start flex-1'>
                      {keywords?.length > 0 &&
                        keywords.map((item, i) => (
                          <button
                            type='button'
                            className={`px-2 py-1 my-1 mr-2 font-semibold text-white hover:bg-sub-400 border-0 rounded-lg  whitespace-nowrap inline-block
                                  ${
                                    item?.nlpSimilarity < 0.1
                                      ? 'bg-red-400'
                                      : 'bg-slate-400'
                                  } `}
                            onClick={() => {
                              setKeywords([
                                ...keywords.slice(0, i),
                                ...keywords.slice(i + 1),
                              ])
                            }}
                          >
                            {item?.keyword} ✕
                          </button>
                        ))}
                    </div>
                  </div>
                </div>

                <div className='flex flex-col justify-center flex-1 mt-4 select-none items-between'>
                  <div className='flex flex-row items-center flex-1 mb-2 text-xl font-bold'>
                    3. 일 광고 예산 설정
                    <Tooltip text='상품마다 일 광고 예산을 설정할 수 있으며, 광고 소진액이 이를 넘으면 광고 송출이 일시중지됩니다. 일시중지 반영까지 시간이 소요되어, 예산을 일부 초과해 소진될 수 있습니다. (일시중지된 광고는 다음날부터 다시 송출됩니다.)' />
                    <div className='text-sm font-normal text-gray-500'>
                      * 예산을 일부 초과하여 소진될 수 있습니다.
                    </div>
                  </div>
                  <div className='flex flex-row items-center justify-start flex-1'>
                    <label
                      htmlFor='limitless'
                      className='flex flex-row items-center justify-center mr-3'
                    >
                      <input
                        type='radio'
                        id='limitless'
                        checked={dailyCostLimit === undefined}
                        onChange={() => setDailyCostLimit()}
                      />
                      <span className='ml-2 mr-5'>제한 없음</span>
                    </label>
                    <label
                      htmlFor='limit'
                      className='flex flex-row items-center justify-center mr-10 '
                    >
                      <input
                        type='radio'
                        id='limit'
                        checked={dailyCostLimit !== undefined}
                        onChange={() => {
                          if (dailyCostLimit === undefined) {
                            setDailyCostLimit(10000)
                          }
                        }}
                      />
                      <div className='ml-2'>직접 입력</div>
                      <input
                        type='number'
                        min={100}
                        step={10}
                        className='w-24 px-2 ml-4 border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500 sm:text-sm'
                        placeholder='ex.10,000'
                        onFocus={() => {
                          if (dailyCostLimit === undefined) {
                            setDailyCostLimit(10000)
                          }
                        }}
                        onChange={(e) => {
                          setDailyCostLimit(e.target.value)
                        }}
                        value={dailyCostLimit !== undefined && dailyCostLimit}
                      />
                    </label>
                  </div>
                </div>
              </div>

              {/* footer */}
              <div className='flex items-center justify-end p-5 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                {adInfo?._id ? (
                  <>
                    <Button
                      className='mx-4 rounded-md hover:bg-gray-200'
                      appearance='teritiary'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        if (SellerStore.isLoading) return
                        SellerStore.setIsLoading(true)

                        const result = await backendApis.deleteItemAd(
                          adInfo?._id,
                          adInfo?.adType,
                          itemInfo?._id,
                        )
                        if (result?.status === 200) {
                          alert('광고가 취소되었습니다.')
                        }
                        cleanUp()
                        setShowModal(false)
                        SellerStore.setIsLoading(false)
                        callbackFunction()
                      }}
                    >
                      광고 삭제
                    </Button>
                    <Button
                      appearance='positiveSub'
                      size='lg'
                      type='button'
                      onClick={async () => {
                        if (SellerStore.isLoading) return
                        SellerStore.setIsLoading(true)

                        if (roas <= 100) {
                          alert('ROAS는 100% 이상으로 설정해주세요.')
                          SellerStore.setIsLoading(false)
                          return
                        }

                        if (
                          dailyCostLimit !== undefined &&
                          (dailyCostLimit === '' || dailyCostLimit < 100)
                        ) {
                          alert(
                            '일 광고 예산을 설정하려면 100원 이상으로 입력해주세요.',
                          )
                          SellerStore.setIsLoading(false)
                          return
                        }

                        const detailInfo = {
                          keywords: keywords?.map((e) => {
                            return {
                              keyword: e?.keyword,
                              nlpSimilarity: e?.nlpSimilarity,
                            }
                          }),
                          dailyCostLimit:
                            parseInt(dailyCostLimit, 10) || undefined,
                        }
                        const inputRoas = parseInt(roas, 10)
                        if (detailInfo && inputRoas) {
                          const result = await backendApis.updateItemAd(
                            adInfo?._id,
                            detailInfo,
                            inputRoas,
                          )
                          setShowModal(false)
                          if (result?.status === 200) {
                            alert(
                              `${adSettingByTab?.[tab].name} 수정이 완료되었습니다.`,
                            )
                            SellerStore.setIsLoading(false)
                            callbackFunction()
                            return
                          }
                        }
                        alert(
                          '광고 수정에 실패했습니다. 새로고침 후 다시 시도해주세요.',
                        )
                        SellerStore.setIsLoading(false)
                      }}
                    >
                      {adSettingByTab?.[tab].name} 수정
                    </Button>
                  </>
                ) : (
                  <Button
                    appearance={
                      (creditInfo?.adCreditInfo?.paidCredit || 0) +
                        (creditInfo?.adCreditInfo?.supportCredit || 0) >
                      0
                        ? 'positive'
                        : 'disabled'
                    }
                    disabled={
                      (creditInfo?.adCreditInfo?.paidCredit || 0) +
                        (creditInfo?.adCreditInfo?.supportCredit || 0) <=
                      0
                    }
                    size='lg'
                    type='button'
                    onClick={async () => {
                      if (SellerStore.isLoading === true) {
                        return
                      }
                      SellerStore.setIsLoading(true)
                      if (roas <= 100) {
                        alert('ROAS는 100% 이상으로 설정해주세요.')
                        SellerStore.setIsLoading(false)
                        return
                      }

                      if (
                        dailyCostLimit !== undefined &&
                        (dailyCostLimit === '' || dailyCostLimit < 100)
                      ) {
                        alert(
                          '일 광고 예산을 설정하려면 100원 이상으로 입력해주세요.',
                        )
                        SellerStore.setIsLoading(false)
                        return
                      }

                      const adType = 'searchAd'
                      const detailInfo = {
                        keywords,
                        dailyCostLimit:
                          parseInt(dailyCostLimit, 10) || undefined,
                      }
                      const inputRoas = parseInt(roas, 10)
                      if (adType && detailInfo && inputRoas) {
                        const result = await backendApis.registerItemAd(
                          itemInfo,
                          adType,
                          detailInfo,
                          inputRoas,
                        )
                        setShowModal(false)
                        SellerStore.setIsLoading(false)
                        if (result?.status === 200) {
                          alert(
                            `${adSettingByTab?.[tab].name} 신청이 완료되었습니다.`,
                          )
                          if (
                            !SellerQuest.questChecker(
                              'adQuest',
                              'registerAdItem',
                            )
                          ) {
                            await SellerQuest.questClearer(
                              'adQuest',
                              'registerAdItem',
                            )
                          }
                          callbackFunction()
                          return
                        }
                      }
                      alert(
                        '광고 신청에 실패했습니다. 새로고침 후 다시 시도해주세요.',
                      )
                    }}
                  >
                    {(creditInfo?.adCreditInfo?.paidCredit || 0) +
                      (creditInfo?.adCreditInfo?.supportCredit || 0) >
                    0
                      ? '광고 신청'
                      : '광고 신청 불가 (크레딧 부족)'}
                  </Button>
                )}
              </div>
            </div>
            <div className='fixed inset-0 z-40 bg-black opacity-25' />
          </>
        ) : (
          <></>
        )}
      </>
    )
  },
)
export default SearchAdApplyModal
