import { SearchOutlined } from '@ant-design/icons'
import { Button, Pagination, Spin } from 'antd'
import Search from 'antd/es/input/Search'
import CategorySelector from '../../components/molecules/categorySelector'
import { useEffect, useState } from 'react'
import SkuCatalogCard from '../../components/molecules/skuCatalogCard'
import SkuCatalogDrawer from '../../components/molecules/skuCatalogDrawer'
import SkuCatalogBiddingModal from '../../components/molecules/skuCatalogBiddingModal'
import backendApis from '../../utils/backendApis'
import useCategoryData from '../../hooks/useCategoryData'

const SkuCatalogBiddingScreen = () => {
  const [selectedTab, setSelectedTab] = useState('all') // 'all' | 'matched'
  const [selectedSubTab, setSelectedSubTab] = useState('all')
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedSkuCatalog, setSelectedSkuCatalog] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchText, setSearchText] = useState('')

  const [skuCatalogList, setSkuCatalogList] = useState([])
  const [totalElements, setTotalElements] = useState(0)
  const [loading, setLoading] = useState(false)
  const [reloadToggle, setReloadToggle] = useState(false)

  const {
    categoryLevels,
    selectedCategories,
    handleCategorySelect,
    resetCategories,
  } = useCategoryData()

  const getButtonStyle = (tabKey) => {
    const baseStyle =
      'flex flex-1 justify-start rounded-xl py-6 w-[300px] mt-8 px-8 text-lg transition-colors'
    const isSelected = selectedTab === tabKey

    if (isSelected) {
      return `${baseStyle} bg-blue-500 text-white font-bold`
    }

    return `${baseStyle} bg-white text-gray-500 `
  }

  const getSubTabStyle = (tabKey) => {
    const baseStyle = 'py-2 px-4 rounded-lg transition-colors'
    const isSelected = selectedSubTab === tabKey

    if (isSelected) {
      return `${baseStyle} bg-blue-500 text-white font-medium`
    }
    return `${baseStyle} text-gray-500 hover:bg-gray-100`
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleDrawerOpen = (skuCatalogData) => {
    setSelectedSkuCatalog(skuCatalogData)
    setIsDrawerOpen(true)
  }

  const handleModalOpen = (skuCatalogData) => {
    setSelectedSkuCatalog(skuCatalogData)
    setIsModalOpen(true)
  }

  const fetchSkuCatalogList = async ({ selectedTab, inspectionStatus }) => {
    setLoading(true)
    try {
      if (selectedTab === 'matched') {
        const res = await backendApis.getCompletedSkuCatalogList({
          inspectionStatus,
          pageNumber: currentPage - 1,
          pageSize,
        })
        if (res.status === 200) {
          setSkuCatalogList(res.data.content)
          setTotalElements(res.data.total_elements)
        }
        return
      }
      if (searchText) {
        const res = await backendApis.searchSkuCatalog({
          searchText,
          pageNumber: currentPage - 1,
          pageSize,
        })
        if (res.status === 200) {
          setSkuCatalogList(res.data.content)
          setTotalElements(res.data.total_elements)
        }
      }
      if (selectedCategories.length > 0) {
        const res = await backendApis.searchSkuCatalogWithCategoryId({
          id: selectedCategories[selectedCategories.length - 1]._id,
          pageNumber: currentPage - 1,
          pageSize,
        })
        if (res.status === 200) {
          setSkuCatalogList(res.data.content)
          setTotalElements(res.data.total_elements)
        }
      }
    } catch (error) {
      console.error('Failed to fetch sku catalog:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (value) => {
    setSearchText(value)
    resetCategories()
    setCurrentPage(1)
    fetchSkuCatalogList({ selectedTab, inspectionStatus: selectedSubTab }) // 검색 버튼 클릭 시에만 API 호출
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
    resetCategories()
  }

  useEffect(() => {
    fetchSkuCatalogList({ selectedTab, inspectionStatus: selectedSubTab })
  }, [
    currentPage,
    pageSize,
    selectedCategories?.[selectedCategories.length - 1]?._id,
    selectedTab,
    selectedSubTab,
    reloadToggle,
  ])

  return (
    <div className='w-full max-w-7xl relative'>
      {loading && (
        <div className='fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center flex-col'>
          <div className='text-white mb-2'>싱픔을 불러오는 중입니다</div>
          <Spin size='large' />
        </div>
      )}
      <div className='pt-12 flex flex-row flex-1 justify-between w-full'>
        <div className='font-bold text-xl'>올웨이즈 데이터랩</div>
        <div className='w-1/4'>
          <Search
            className='mb-5 w-full'
            styles={{
              input: {
                height: '32px',
                borderColor: '#cbd5e1',
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
              },
            }}
            value={searchText}
            onChange={handleSearchChange}
            placeholder='검색어를 입력하세요'
            onSearch={handleSearch}
            enterButton={
              <Button type='primary' className='bg-blue-500 text-white'>
                <SearchOutlined />
              </Button>
            }
          />
        </div>
      </div>
      <div className='w-full max-w-7xl bg-white p-8 rounded-xl shadow-sm'>
        <div className='text-lg font-medium mb-4'>카테고리를 선택해 주세요</div>
        <CategorySelector
          categoryLevels={categoryLevels}
          selectedCategories={selectedCategories}
          handleCategorySelect={(category, level) => {
            handleCategorySelect(category, level)
            setSearchText('')
          }}
          resetCategories={resetCategories}
        />
      </div>
      <div className='flex flex-row w-full justify-between items-center'>
        <div className='flex flex-row gap-2'>
          <Button
            className={getButtonStyle('all')}
            onClick={() => {
              setSelectedTab('all')
              setSelectedSubTab('all')
              setSkuCatalogList([])
            }}
          >
            전체
          </Button>
          <Button
            className={getButtonStyle('matched')}
            onClick={() => {
              setSelectedTab('matched')
              setSelectedSubTab('all')
              setSkuCatalogList([])
            }}
          >
            신청 완료
          </Button>
        </div>
      </div>

      {selectedTab === 'matched' && (
        <div className='flex flex-row gap-2 mt-4 bg-white p-4 rounded-xl'>
          <button
            className={getSubTabStyle('all')}
            onClick={() => setSelectedSubTab('all')}
            type='button'
          >
            전체
          </button>
          <button
            className={getSubTabStyle('completed')}
            onClick={() => setSelectedSubTab('completed')}
            type='button'
          >
            노출 진행중
          </button>
          <button
            className={getSubTabStyle('pending')}
            onClick={() => setSelectedSubTab('pending')}
            type='button'
          >
            검수 진행중
          </button>
          <button
            className={getSubTabStyle('rejected')}
            onClick={() => setSelectedSubTab('rejected')}
            type='button'
          >
            검수 반려
          </button>
        </div>
      )}

      <div className='flex flex-row justify-start gap-2 flex-wrap bg-white p-4 rounded-xl mt-4'>
        {skuCatalogList?.length > 0 ? (
          skuCatalogList?.map((item) => {
            return (
              <SkuCatalogCard
                key={item?.sku_catalog_id}
                title={item.title}
                mainImageUris={item.main_image_uris}
                lowestPrice={item.price}
                reviewCount={
                  item.aggregated_review_info?.[0]?.num_of_review ?? 0
                }
                reviewScore={
                  item.aggregated_review_info?.[0]?.avg_of_review_score ?? 0
                }
                externalPopularityScore={item.external_popularity_score ?? 0}
                biddingCount={item.bidding_count ?? 0}
                inspectionStatus={item.inspection_status}
                onDrawerOpen={() => handleDrawerOpen(item)}
                onModalOpen={() => handleModalOpen(item)}
              />
            )
          })
        ) : (
          <div className='flex flex-row justify-center flex-1 w-full items-center h-[200px]'>
            <div className='text-gray-500'>해당 조건의 상품이 없습니다.</div>
          </div>
        )}
      </div>
      <div className='flex justify-center my-8'>
        <Pagination
          current={currentPage}
          total={totalElements}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger={false}
          showQuickJumper={false}
        />
      </div>
      {selectedSkuCatalog && (
        <>
          <SkuCatalogDrawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            skuCatalogData={selectedSkuCatalog}
            reviewCount={
              selectedSkuCatalog.aggregated_review_info?.[0]?.num_of_review ?? 0
            }
          />
        </>
      )}
      {isModalOpen && (
        <SkuCatalogBiddingModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          skuCatalogData={selectedSkuCatalog}
          skuCatalogId={selectedSkuCatalog.sku_catalog_id}
          initialStage={
            selectedSkuCatalog.inspection_status === 'completed' ? 2 : 1
          }
          onSubmit={() => setReloadToggle(!reloadToggle)}
        />
      )}
    </div>
  )
}

export default SkuCatalogBiddingScreen
