import { Button, Divider, Image, message, Pagination, Tag } from 'antd'
import React, { useState } from 'react'
import itemInfoParser from '../../utils/itemInfoParser'
import ItemSearchBox from './itemSearchBox'
import ItemCard from '../unitOption/bidding/itemCard'
import OptionCard from '../unitOption/bidding/optionCard'
import { nanoid } from 'nanoid'
import commaNumber from 'comma-number'

const description = {
  help: (
    <div>
      <div className='font-bold'>상품 연동 안내</div>
      <div className='mb-2'>카탈로그 상품에 상품을 연동하실 수 있습니다.</div>
      <div>1. 검색어 탭에 검색어를 적어 주세요.</div>
      <div>
        2. <Tag className='bg-blue-500 text-white border-none'>검색</Tag>버튼을
        클릭해주세요.
      </div>
      <div>
        3. 검색 결과에서 카탈로그와 완전히 동일한 상품/옵션을 선택해주세요.
      </div>
      <div>4. 연동하기 버튼을 통해 연동하실 수 있습니다.</div>
      <Divider />
      <div className='font-bold'>주의사항</div>
      <div className='mt-2'>*제출해주신 연동 신청은 면밀하게 검수됩니다.</div>
      <div>*다른 상품을 연동할 경우 연동이 취소됩니다.</div>
    </div>
  ),
}

const SkuCatalogMatchingSection = ({
  skuCatalogData,
  onSelectItemId,
  onSelectOptionId,
  onNext = () => {},
  type = 'bidding',
}) => {
  const [candidateItems, setCandidateItems] = useState([])
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [selectedOptionId, setSelectedOptionId] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  const limit = 10
  const buttonText = {
    bidding: '다음으로',
    wanted: '연동하기',
  }

  const selectedItem = candidateItems.find(
    (item) => item._id === selectedItemId,
  )
  const parsedOptionsInfo = itemInfoParser.optionsInfoParser(
    selectedItem?.optionsInfo,
  )
  const parsedSelectedOption = parsedOptionsInfo.find(
    (option) => option._id === selectedOptionId,
  )

  const { title, mainImageUris, price } = skuCatalogData

  const onItemSelect = (itemId) => {
    if (itemId === selectedItemId) return
    const selectedItem = candidateItems.find((item) => item._id === itemId)
    setSelectedItemId(itemId)
    setSelectedOptionId(null)
    onSelectItemId({ itemId, itemTitle: selectedItem?.itemTitle })
  }
  const onOptionSelect = (optionId) => {
    const parsedOptionsInfo = itemInfoParser.optionsInfoParser(
      selectedItem?.optionsInfo,
    )
    const parsedSelectedOption = parsedOptionsInfo.find(
      (option) => option._id === optionId,
    )
    setSelectedOptionId(optionId)
    onSelectOptionId({
      optionId,
      selectedOptionTitle: parsedSelectedOption.option2
        ? `${parsedSelectedOption?.option1}|${parsedSelectedOption?.option2}`
        : parsedSelectedOption?.option1,
      currentOptionPrice: parsedSelectedOption?.teamPurchasePrice,
    })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const onReset = () => {
    setSelectedItemId(null)
    setSelectedOptionId(null)
    onSelectItemId({ itemId: null, itemTitle: null })
    onSelectOptionId({
      optionId: null,
      selectedOptionTitle: null,
      currentOptionPrice: null,
    })
  }

  return (
    <>
      <div className='flex flex-row flex-1 justify-end items-center mb-4'>
        <div className='flex items-center'>
          <ItemSearchBox
            page={currentPage}
            setPage={setCurrentPage}
            limit={limit}
            onSearch={(v) => setCandidateItems(v)}
            setTotalCount={setTotalCount}
            onReset={onReset}
          />
        </div>
      </div>
      <div className='flex gap-8'>
        {/* 왼쪽 영역 */}
        <div className='w-[200px]'>
          <div className='w-full mb-4' />
          <Image src={mainImageUris[0]} alt={title} width={200} height={200} />
          <div className='text-xl font-bold mb-4 line-clamp-2'>{title}</div>
          {/* {Boolean(price && price !== 0) && (
            <div className='bg-gray-50 rounded-lg p-4'>
              <div>
                현재 최저가{' '}
                <span className='font-bold text-blue-500'>
                  {commaNumber(price)}원
                </span>
              </div>
            </div>
          )} */}
        </div>

        {/* 오른쪽 영역 */}
        <div className='flex-1'>
          <div className='h-[400px] w-full max-w-[800px] flex-col flex flex-1 border-[1px] rounded-xl border-gray-200 bg-gray-100 px-8 p-4'>
            <div className='overflow-y-scroll'>
              {candidateItems?.length > 0 ? (
                candidateItems.map((item, idx) => {
                  return (
                    <div
                      key={nanoid() + idx?.toString()}
                      className='mb-4 flex flex-1 flex-col w-full'
                    >
                      <ItemCard
                        isSelected={
                          selectedItemId?.toString() === item?._id?.toString()
                        }
                        item={item}
                        onSelect={onItemSelect}
                      />

                      <div className='w-full flex flex-1'>
                        {selectedItemId?.toString() ===
                          item?._id?.toString() && (
                          <OptionCard
                            optionsInfo={item?.optionsInfo}
                            onSelect={onOptionSelect}
                            selectedOptionId={selectedOptionId}
                          />
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className='p-8 mb-4 bg-slate-100 rounded-lg flex-1 flex-row w-full'>
                  <div>{description?.help}</div>
                </div>
              )}
            </div>
          </div>
          <div>
            <Pagination
              className='flex justify-center mt-4'
              current={currentPage}
              total={totalCount}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper={false}
            />
          </div>
        </div>
      </div>

      {/* 하단 버튼 */}
      <div className='flex flex-col items-end mt-4 gap-4'>
        <div className='text-sm text-gray-500 flex flex-1 flex-col items-end'>
          <div>
            <span className='font-bold text-lg text-blue-500'>
              {selectedItem?.itemTitle || '선택해주세요'}
            </span>
          </div>
          <div>
            <span className='font-bold text-gray-500'>
              <span>{parsedSelectedOption?.option1 || '선택해주세요'}</span>
              {parsedSelectedOption?.option2 && (
                <span className='ml-2'>{parsedSelectedOption?.option2}</span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className='flex mt-5 flex-1 flex-row justify-end'>
        <Button
          type='primary'
          size='large'
          className=' py-4 px-16 font-bold'
          onClick={() => {
            if (selectedItemId && selectedOptionId) {
              onNext()
            } else {
              message.error('상품과 옵션을 선택해주세요.')
            }
          }}
        >
          {buttonText[type]}
        </Button>
      </div>
    </>
  )
}

export default SkuCatalogMatchingSection
