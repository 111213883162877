import React from 'react'
import { Badge } from '../../../../components/ADS/atoms'

const AdStatus = ({ status }) => {
  switch (status) {
    case 'ONGOING':
      return <Badge text='광고 진행중' color='green' />

    case 'PENDING':
      return (
        <Badge
          text='대기중'
          color='yellow'
          isTooltip
          tooltipText='광고가 대기중이에요. 30분 이내에 광고가 진행될 예정이에요.'
        />
      )

    case 'PAUSED':
      return (
        <Badge
          text='일시중지'
          color='yellow'
          isTooltip
          tooltipText='광고가 일시 중지되었어요. 광고를 진행하려면 ON/OFF 스위치를 켜주세요.'
        />
      )

    case 'COST_PAUSED':
      return (
        <Badge
          text='일시중지(예산 도달)'
          color='red'
          isTooltip
          tooltipText='설정한 하루 광고 예산을 모두 소진해 광고가 일시 중지되었어요. 내일부터 다시 광고가 노출돼요.'
        />
      )

    case 'CREDIT_PAUSED':
      return (
        <Badge
          text='일시중지(크레딧 부족)'
          color='red'
          isTooltip
          tooltipText='크레딧이 부족해 광고가 중지되었어요. 광고를 진행하려면 [광고 크레딧 관리] 메뉴에서 크레딧을 충전해주세요.'
        />
      )

    case 'SOLDOUT_PAUSED':
      return (
        <Badge
          text='일시중지(품절)'
          color='red'
          isTooltip
          tooltipText='상품이 품절되어 광고가 중지되었어요.'
        />
      )

    case 'EXPIRED':
      return (
        <Badge
          text='완료(기간 만료)'
          color='blue'
          isTooltip
          tooltipText='설정한 기간까지 광고 집행이 완료되었어요.'
        />
      )

    default:
      return <div />
  }
}

export default AdStatus
