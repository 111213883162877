import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Tabs, Table, Switch } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import AdCreateButton from './components/AdCreateButton'
import AlfarmClickAdDescription from './components/AlfarmClickAdDescription'
import AdStatus from './components/AdStatus'
import AddAdSetModal from './components/AddAdSetModal'
import backendApis from '../../../utils/backendApis'
import Button from '../../../components/atoms/button'
import { Badge, Tooltip } from '../../../components/ADS/atoms'
import noDataIcon from '../../../images/noDataIcon.svg'
import resize from '../../../utils/resize'

const DEFAULT_PAGE_SIZE = 20

const AlfarmClickAdScreen = observer(() => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('campaign')
  const [selected, setSelected] = useState({ campaignId: null, adSetId: null })
  const [isLoading, setIsLoading] = useState(false)
  const [adsData, setAdsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [totalCount, setTotalCount] = useState(0)
  const [toggles, setToggles] = useState({})
  // const [selectedSearchField, setSelectedSearchField] = useState('itemTitle')
  // const [itemQuery, setItemQuery] = useState('')
  const [addAdSetModal, setAddAdSetModal] = useState(false)
  const isToggleLoadingRef = useRef(false)
  const isTableLoadingRef = useRef(false)

  const fetchData = async () => {
    if (isLoading) return
    setIsLoading(true)

    let result
    const params = { size: pageSize, page: currentPage }

    switch (activeTab) {
      case 'campaign':
        result = await backendApis.getAdCampaigns(params)
        if (result?.status === 200) {
          setTotalCount(result?.data?.pagination?.totalCount)
          setAdsData(result?.data?.campaigns)
          const initialToggles = result?.data?.campaigns.reduce(
            (acc, campaign) => {
              acc[campaign._id] = campaign.toggle === 'ON'
              return acc
            },
            {},
          )
          setToggles(initialToggles)
        }
        break

      case 'adSet':
        if (selected?.campaignId) {
          result = await backendApis.getAlfarmClickAdSetSelectedCampaign(
            selected?.campaignId,
            params,
          )
          if (result?.status === 200) {
            setTotalCount(result?.data?.pagination?.totalCount)
            setAdsData(result?.data?.adSets)
            const initialToggles = result?.data?.adSets.reduce((acc, adSet) => {
              acc[adSet._id] = adSet.toggle === 'ON'
              return acc
            }, {})
            setToggles(initialToggles)
          }
        } else {
          result = await backendApis.getAlfarmClickAdSets(params)
          if (result?.status === 200) {
            setTotalCount(result?.data?.pagination?.totalCount)
            setAdsData(result?.data?.adSets)
            const initialToggles = result?.data?.adSets.reduce((acc, adSet) => {
              acc[adSet._id] = adSet.toggle === 'ON'
              return acc
            }, {})
            setToggles(initialToggles)
          }
        }
        break

      case 'adUnit':
        if (selected?.campaignId) {
          result = await backendApis.getAlfarmClickAdUnitsSelectedCampaign(
            selected?.campaignId,
            params,
          )
          if (result?.status === 200) {
            setTotalCount(result?.data?.pagination?.totalCount)
            setAdsData(result?.data?.adUnits)
            const initialToggles = result?.data?.adUnits.reduce(
              (acc, adUnit) => {
                acc[adUnit._id] = adUnit.toggle === 'ON'
                return acc
              },
              {},
            )
            setToggles(initialToggles)
          }
        } else if (selected?.adSetId) {
          result = await backendApis.getAlfarmClickAdUnitsSelectedAdSet(
            selected?.adSetId,
            params,
          )
          if (result?.status === 200) {
            setTotalCount(result?.data?.pagination?.totalCount)
            setAdsData(result?.data?.adUnits)
            const initialToggles = result?.data?.adUnits.reduce(
              (acc, adUnit) => {
                acc[adUnit._id] = adUnit.toggle === 'ON'
                return acc
              },
              {},
            )
            setToggles(initialToggles)
          }
        } else {
          result = await backendApis.getAlfarmClickAdUnits(params)
          if (result?.status === 200) {
            setTotalCount(result?.data?.pagination?.totalCount)
            setAdsData(result?.data?.adUnits)
            const initialToggles = result?.data?.adUnits.reduce(
              (acc, adUnit) => {
                acc[adUnit._id] = adUnit.toggle === 'ON'
                return acc
              },
              {},
            )
            setToggles(initialToggles)
          }
        }
        break

      default:
        break
    }
    setIsLoading(false)
  }

  const handleToggleChange = async (campaignId, params, toggleControlId) => {
    if (isToggleLoadingRef.current) return
    isToggleLoadingRef.current = true

    try {
      const result = await backendApis.updateAdCampaign(campaignId, params)
      if (result?.status === 200) {
        setToggles((prevToggles) => ({
          ...prevToggles,
          [toggleControlId]: !prevToggles[toggleControlId],
        }))
      }
    } catch (error) {
      console.error('Toggle change error:', error)
    }
    isToggleLoadingRef.current = false
  }

  const handleTableChange = (pagination) => {
    if (isTableLoadingRef.current) return
    isTableLoadingRef.current = true

    const start = (pagination.current - 1) * pagination.pageSize
    const end = start + pagination.pageSize
    setAdsData(adsData.slice(start, end))
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)

    isTableLoadingRef.current = false
  }

  const columns = useMemo(() => {
    const campaignColumns = [
      {
        title: '캠페인',
        key: 'id',
        align: 'left',
        width: '120px',
        render: (_, record) => (
          <div>
            <button
              type='button'
              className='font-semibold text-[#007AFF]'
              onClick={() => {
                setSelected({
                  campaignId: record._id,
                  adSetId: null,
                })
                setActiveTab('adSet')
              }}
            >
              {record.name}
            </button>
            {selected?.campaignId === record._id && (
              <Badge
                className='ml-1 rounded-xl'
                text='선택됨'
                color='blue'
                size='sm'
              />
            )}
            <div className='items-start text-xs text-[#9C9DA4]'>
              ID: {record._id}
            </div>
          </div>
        ),
      },
      {
        title: 'ON/OFF',
        key: 'toggle',
        align: 'left',
        width: '80px',
        render: (_, record) => (
          <Switch
            className='bg-gray-200'
            checked={toggles[record._id]}
            onChange={() =>
              handleToggleChange(
                record._id,
                { toggle: !toggles[record._id] ? 'ON' : 'OFF' },
                record._id,
              )
            }
          />
        ),
      },
      {
        title: '캠페인 생성일',
        key: 'createdAt',
        align: 'left',
        width: '120px',
        render: (_, record) => moment(record.createdAt).format('YYYY-MM-DD'),
      },
    ]

    const adSetColumns = [
      {
        title: '광고세트',
        key: 'name',
        align: 'left',
        width: '120px',
        render: (_, record) => (
          <div>
            <div className='flex flex-row items-center justify-between'>
              <div>
                <button
                  type='button'
                  className='font-semibold text-[#007AFF]'
                  onClick={() => {
                    setSelected({
                      campaignId: null,
                      adSetId: record._id,
                    })
                    setActiveTab('adUnit')
                  }}
                >
                  {record.name}
                  {selected?.adSetId === record._id && (
                    <Badge
                      className='ml-1 rounded-xl'
                      text='선택됨'
                      color='blue'
                      size='sm'
                    />
                  )}
                </button>

                <div className='items-start text-xs text-[#9C9DA4]'>
                  ID: {record._id}
                </div>
              </div>

              <button
                type='button'
                onClick={() => {
                  history.push(
                    '/ad/alfarm-click-ad/edit',
                    JSON.parse(
                      JSON.stringify({
                        method: 'EDIT_AD_SET',
                        adSetInfo: record,
                      }),
                    ),
                  )
                }}
                className='px-2 py-2 ml-2 text-sm text-gray-300 rounded-md hover:bg-gray-100'
              >
                <FormOutlined className='w-4 h-4' /> 수정
              </button>
            </div>
          </div>
        ),
      },
      {
        title: 'ON/OFF',
        key: 'toggle',
        align: 'left',
        width: '80px',
        render: (_, record) => (
          <Switch
            className='bg-gray-200'
            checked={toggles[record._id]}
            onChange={() =>
              handleToggleChange(
                record.campaignId,
                {
                  adSets: [
                    {
                      adSetId: record._id,
                      toggle: !toggles[record._id] ? 'ON' : 'OFF',
                    },
                  ],
                },
                record._id,
              )
            }
          />
        ),
      },
      {
        title: '상태',
        key: 'status',
        align: 'left',
        width: '80px',
        render: (_, record) => (
          <div>
            <AdStatus status={record.status} />
          </div>
        ),
      },
      {
        title: '광고 기간',
        key: 'dateRange',
        align: 'left',
        width: '120px',
        render: (_, record) => (
          <div>
            {moment(record.startDate).format('YYYY-MM-DD')} ~{' '}
            {record?.endDate && moment(record.endDate).format('YYYY-MM-DD')}
          </div>
        ),
      },
      {
        title: <div className='text-left'>일 예산</div>,
        key: 'dailyBudget',
        align: 'right',
        width: '120px',
        render: (_, record) =>
          `${Math.floor(record.dailyBudget || 0).toLocaleString()}원`,
      },
      {
        title: <div className='text-left'>입찰가</div>,
        key: 'biddingPrice',
        align: 'right',
        width: '120px',
        render: (_, record) => (
          <div>
            {`${Math.floor(record.biddingPrice || 0).toLocaleString()}원`}
          </div>
        ),
      },
    ]

    const adUnitColumns = [
      {
        title: '상품',
        key: 'itemInfo',
        align: 'left',
        width: '200px',
        render: (_, record) => (
          <div className='flex flex-row flex-1'>
            {record.itemImageUri && record.itemImageUri !== '' ? (
              <img
                alt='itemImage'
                src={resize(record.itemImageUri)}
                className='w-10 h-10 mr-2 rounded-md border border-[#000000F]'
              />
            ) : (
              <div className='w-10 h-10 mr-2 rounded-md border bg-gray-100 border-[#000000F]' />
            )}
            <div className='mr-3'>
              <div className='font-semibold'>{record.itemTitle}</div>
              <div className='text-xs text-[#9C9DA4]'>ID: {record.itemId}</div>
            </div>
          </div>
        ),
      },
      {
        title: 'ON/OFF',
        key: 'toggle',
        align: 'left',
        width: '80px',
        render: (_, record) => (
          <Switch
            className='bg-gray-200'
            checked={toggles[record._id]}
            onChange={() =>
              handleToggleChange(
                record.campaignId,
                {
                  adSets: [
                    {
                      adSetId: record.adSetId,
                      adUnits: [
                        {
                          adUnitId: record._id,
                          toggle: !toggles[record._id] ? 'ON' : 'OFF',
                        },
                      ],
                    },
                  ],
                },
                record._id,
              )
            }
          />
        ),
      },
      {
        title: '상태',
        key: 'status',
        align: 'left',
        width: '80px',
        render: (_, record) => (
          <div>
            <AdStatus status={record.status} />
          </div>
        ),
      },
      {
        title: <div className='text-left'>입찰가</div>,
        key: 'biddingPrice',
        align: 'right',
        width: '120px',
        render: (_, record) => (
          <div>
            {`${Math.floor(record.biddingPrice || 0).toLocaleString()}원`}
          </div>
        ),
      },
    ]

    const analyticsColumns = [
      {
        title: (
          <div className='text-left'>
            클릭수
            <Tooltip text='해당 상품의 광고가 클릭된 횟수입니다.' />
          </div>
        ),
        key: 'totalClickCount',
        align: 'right',
        width: '120px',
        render: (_, record) =>
          `${record.totalClickCount?.toLocaleString() || '-'}회`,
      },
      {
        title: (
          <div className='text-left'>
            판매수
            <Tooltip
              text='해당 상품이 광고로 판매된 횟수입니다.<br/>
              광고 기여도가 있는 판매건만 집계됩니다.'
            />
          </div>
        ),
        key: 'totalOrderCount',
        align: 'right',
        width: '120px',
        render: (_, record) =>
          `${record.totalOrderCount?.toLocaleString() || '-'}회`,
      },
      {
        title: (
          <div className='text-left'>
            총 비용(VAT 포함)
            <Tooltip text='부가세(VAT)가 포함된, 해당 상품의 광고 소진액입니다.' />
          </div>
        ),
        key: 'totalAdCost',
        align: 'right',
        width: '120px',
        render: (_, record) =>
          `${Math.floor(record.totalAdCost || 0).toLocaleString()}원`,
      },
      {
        title: (
          <div className='text-left'>
            ROAS
            <Tooltip
              text='ROAS는 (광고 전환 매출/부가세 제외 광고비)로 계산되며<br/>
                광고가 얼마나 효율적인지 나타내는 지표입니다.<br/>
              (ex. ROAS 600%이면 투입한 광고 비용의 <br/>6배만큼 매출이 발생한 것입니다.)'
            />
          </div>
        ),
        key: 'roas',
        width: '120px',
        align: 'right',
        render: (_, record) =>
          `${Math.floor(record.roas || 0).toLocaleString() || '-'}%`,
      },
      {
        title: (
          <div className='text-left'>
            광고 전환 매출
            <Tooltip text='광고로 발생한 매출액입니다.' />
          </div>
        ),
        key: 'gmv',
        align: 'right',
        width: '120px',
        render: (_, record) =>
          `${Math.floor(record.gmv || 0).toLocaleString()}원`,
      },
    ]

    switch (activeTab) {
      case 'campaign':
        return [...campaignColumns, ...analyticsColumns]
      case 'adSet':
        return [...adSetColumns, ...analyticsColumns]
      case 'adUnit':
        return [...adUnitColumns, ...analyticsColumns]
      default:
        return [...campaignColumns, ...analyticsColumns]
    }
  }, [activeTab, toggles, selected])

  useEffect(() => {
    fetchData()
  }, [activeTab, currentPage, pageSize, selected])

  return (
    <div className='w-full px-10 py-10 mt-10 max-w-[1920px]'>
      {/* 1. title */}
      <div className='mb-5 text-3xl font-bold select-none'>
        올팜 클릭 광고(Beta)
      </div>

      {/* 2. description */}
      <AlfarmClickAdDescription />

      {/* 3. chart */}
      {/* <div className='flex flex-col p-8 mb-3 bg-white rounded-2xl'>
        <div className='flex flex-row items-center mb-5 text-xl font-bold'>
          광고 성과 그래프
        </div>
        {chartData?.length > 0 ? (
          <>
            {chartData?.some((item) => item?.adVisits > 0) && (
              <div>
                <Bar
                  data={chart.data}
                  width={50}
                  height={15}
                  options={chart.options}
                />
              </div>
            )}
          </>
        ) : (
          <div className='flex items-center justify-center mb-5 text-xl'>
            {loadChartData
              ? '최근 2주간 광고 성과 데이터가 없습니다.'
              : '데이터를 불러오는 중입니다...'}
          </div>
        )}
      </div> */}

      {/* 4. table */}
      <div className='flex flex-col p-8 mb-3 bg-white rounded-2xl'>
        <div className='flex flex-row items-center justify-between flex-1 mb-4 '>
          <div className='flex flex-row'>
            <AdCreateButton
              activeTab={activeTab}
              onClickCampaign={() => history.push('/ad/alfarm-click-ad/edit')}
              onClickAdSet={() => {
                if (selected?.campaignId) {
                  // 캠페인 선택한 경우, 광고세트 추가화면으로 바로 연결
                  history.push(
                    '/ad/alfarm-click-ad/edit',
                    JSON.parse(
                      JSON.stringify({
                        campaignId: selected?.campaignId,
                        method: 'NEW_AD_SET',
                      }),
                    ),
                  )
                } else if (adsData.filter((e) => e?.campaignId).length === 0) {
                  // 캠페인, 광고세트가 없는 경우, 캠페인 만들기로 연결
                  history.push('/ad/alfarm-click-ad/edit')
                } else {
                  // 캠페인 선택안한 경우, 모달 표시
                  setAddAdSetModal(true)
                }
              }}
            />
            {(selected?.campaignId || selected?.adSetId) && (
              <button
                type='button'
                className='px-6 py-2.5 ml-2 font-semibold leading-5 text-gray-500 border border-gray-300 rounded-lg hover:bg-gray-100 whitespace-nowrap'
                onClick={() => {
                  setSelected({
                    campaignId: null,
                    adSetId: null,
                  })
                }}
              >
                선택 해제
              </button>
            )}
          </div>
          {/* <div className='flex flex-row'>
            {itemQuery && (
              <Button
                className='w-24 mr-3 text-gray-600 rounded-md hover:bg-gray-200'
                onClick={() => {
                  setItemQuery('')
                  setSelectedSearchField('itemTitle')
                  // fetchSegmentedItemsInfo(true, true)
                }}
                appearance='transparent'
                size='md'
              >
                초기화
              </Button>
            )}

            <select
              value={selectedSearchField}
              className='border-r-0 border-gray-300 select-none rounded-l-md focus:border-blue-500 focus:ring-blue-500'
              onChange={(e) => setSelectedSearchField(e.target.value)}
            >
              <option value='itemTitle'>캠페인명</option>
              <option value='itemId'>캠페인 ID</option>
            </select>
            <Input
              className='text-left border-gray-300 select-none w-60 rounded-r-md focus:border-blue-500 focus:ring-blue-500'
              type='text'
              placeholder={`${
                selectedSearchField === 'itemTitle' ? '캠페인명' : '캠페인 ID'
              } 검색`}
              value={itemQuery}
              onChange={(e) => {
                setItemQuery(e.target.value)
              }}
            />
            <Button
              onClick={() => {}}
              appearance='subBlue'
              size='md'
              className='px-6 py-3 ml-3 leading-5 whitespace-nowrap'
            >
              검색
            </Button>
          </div> */}
        </div>

        <Tabs
          activeKey={activeTab}
          onChange={(key) => {
            setActiveTab(key)
            setCurrentPage(1)
          }}
          size='large'
          className='flex justify-between font-semibold'
          items={[
            {
              label: (
                <div className='px-[8vw]'>
                  <span className='mx-4'>캠페인</span>
                  {selected?.campaignId && (
                    <Badge text='1개 선택됨' color='blue' />
                  )}
                </div>
              ),
              key: 'campaign',
            },
            {
              label: (
                <div className='px-[8vw]'>
                  <span className='mx-4'>
                    광고세트
                    {selected?.campaignId &&
                      !selected?.adSetId &&
                      ' (선택한 캠페인)'}
                  </span>
                  {selected?.adSetId && (
                    <Badge text='1개 선택됨' color='blue' />
                  )}
                </div>
              ),
              key: 'adSet',
            },
            {
              label: (
                <div className='px-[8vw] '>
                  상품
                  {selected?.campaignId && ' (선택한 캠페인)'}
                  {selected?.adSetId && ' (선택한 광고세트)'}
                </div>
              ),
              key: 'adUnit',
            },
          ]}
        />

        {!isLoading && adsData?.length === 0 ? (
          <div className='flex flex-col items-center justify-center py-12'>
            <img
              src={noDataIcon}
              alt='데이터 없음'
              className='w-20 h-20 mb-4'
            />
            <div className='mb-2 text-lg font-bold text-[#464953]'>
              등록된 광고가 없어요
            </div>
            <Button
              onClick={() => history.push('/ad/alfarm-click-ad/edit')}
              appearance='subBlue'
              size='md'
              className='px-6 py-3 ml-3 leading-5 whitespace-nowrap'
            >
              등록하기
            </Button>
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={adsData}
            loading={isLoading}
            rowKey={(adsData) => adsData?._id}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              pageSize,
              total: totalCount,
              showSizeChanger: true,
            }}
          />
        )}
      </div>

      {addAdSetModal && (
        <AddAdSetModal
          showModal={addAdSetModal}
          setShowModal={setAddAdSetModal}
        />
      )}
    </div>
  )
})
export default AlfarmClickAdScreen
