import React, { useEffect, useState } from 'react'
import { Modal, Image, Checkbox, Button } from 'antd'
import Cookies from 'js-cookie'

const ExplainModal = ({
  isVisible,
  onCancel = () => {},
  ignoreCookie = true,
  title = '신선마켓 노출 혜택',
  content = (
    <Image
      width={800}
      preview={false}
      src='https://assets.ilevit.com/03e68b8b-0659-4403-83e1-d23c10e2c310.png'
    />
  ),
  width = 850,
  modalKey = 'modalShown',
}) => {
  const [visible, setVisible] = useState(false)
  const [rememberChoice, setRememberChoice] = useState(false)

  useEffect(() => {
    if (ignoreCookie) {
      setVisible(true)
    } else {
      const modalShown = Cookies.get(modalKey)
      if (!modalShown) {
        setVisible(true)
      }
    }
  }, [ignoreCookie])

  const handleOk = () => {
    if (rememberChoice) {
      Cookies.set(modalKey, 'true', { expires: 1 }) // Expires in 1 day
    }
    onCancel()
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleCheckboxChange = (e) => {
    setRememberChoice(e.target.checked)
  }

  return (
    <Modal
      open={visible && isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      title={title}
      okText='확인'
      width={width}
      okButtonProps={{ className: 'bg-blue-500' }}
      cancelText='취소'
      cancelButtonProps={{ className: 'display-none' }}
      footer={
        <div className='flex justify-between '>
          <Checkbox
            checked={rememberChoice}
            className='items-center justify-center'
            onChange={handleCheckboxChange}
          >
            오늘 하루 다시 보지 않기
          </Checkbox>
          <div>
            <Button onClick={handleOk} type='primary' className='bg-blue-500'>
              확인
            </Button>
          </div>
        </div>
      }
    >
      {content}
    </Modal>
  )
}

export default ExplainModal
