import React from 'react'
import { Card, List, Row, Col, Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'

const CategorySelector = ({
  categoryLevels,
  selectedCategories,
  handleCategorySelect,
  resetCategories,
}) => {
  const handleSelect = async (category, level) => {
    await handleCategorySelect(category, level)
  }

  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='text-sm text-blue-600'>
        선택한 카테고리:
        <span className='font-medium ml-2'>
          {selectedCategories.length > 0 ? (
            selectedCategories.map((cat, index) => (
              <React.Fragment key={cat._id}>
                {cat.name || cat.displayName}
                {index < selectedCategories.length - 1 && ' > '}
              </React.Fragment>
            ))
          ) : (
            <span className='text-gray-500'>없음</span>
          )}
        </span>
        <Button
          type='default'
          size='small'
          onClick={resetCategories}
          className='ml-4 bg-white'
        >
          재선택
        </Button>
      </div>
      {categoryLevels.length <= 4 ? (
        <Row gutter={8}>
          {categoryLevels.map((categories, index) => (
            <Col span={6} key={index?.toString()}>
              <Card className='h-[400px] overflow-y-auto'>
                <List
                  dataSource={categories}
                  renderItem={(category) => (
                    <List.Item
                      className='cursor-pointer hover:bg-gray-50'
                      onClick={() => handleSelect(category, index)}
                    >
                      <div className='flex justify-between items-center w-full'>
                        <span
                          className={
                            selectedCategories[index]?._id === category._id
                              ? 'text-blue-600 font-medium'
                              : ''
                          }
                        >
                          {category.name || category.displayName}
                        </span>
                        {!category.endNode && (
                          <RightOutlined className='text-gray-400' />
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          ))}
          {/* 4칸 미만일 때 빈 칸으로 채우기 */}
          {categoryLevels.length < 4 &&
            Array(4 - categoryLevels.length)
              .fill(null)
              .map((_, index) => (
                <Col span={6} key={`empty-${index?.toString()}`}>
                  <Card className='h-[400px] overflow-y-auto'>
                    <List />
                  </Card>
                </Col>
              ))}
        </Row>
      ) : (
        // 4칸 초과시 스크롤 가능한 레이아웃
        <div className='flex gap-4 overflow-x-auto pb-2'>
          {categoryLevels.map((categories, index) => (
            <div key={index?.toString()} className='min-w-[200px]'>
              <Card className='h-[400px] overflow-y-auto'>
                <List
                  dataSource={categories}
                  renderItem={(category) => (
                    <List.Item
                      className='cursor-pointer px-4 hover:bg-gray-50'
                      onClick={() => handleSelect(category, index)}
                    >
                      <div className='flex justify-between items-center w-full'>
                        <span
                          className={
                            selectedCategories[index]?._id === category._id
                              ? 'text-blue-600 font-medium'
                              : ''
                          }
                        >
                          {category.name || category.displayName}
                        </span>
                        {!category.endNode && (
                          <RightOutlined className='text-gray-400' />
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CategorySelector
