import React from 'react'
import Menus from './Menus'
import SellerStore from '../stores/SellerStore'
import {
  FcOnlineSupport as CsIcon,
  FcPaid as ProductIcon,
  FcDocument as ReviewIcon,
  FcAutomotive as SpeedIcon,
  FcUndo as RefundIcon,
} from 'react-icons/fc'
import Ping from './atoms/ping'
import { observer } from 'mobx-react-lite'
import User from '../images/user.png'
import { Link, useHistory } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import Button from './atoms/button'
import AuthStore from '../stores/AuthStore'

const Sidebar = observer(() => {
  const history = useHistory()
  const shippingScore = SellerStore.sellerScores.shippingScore
  const cancelScore = SellerStore.sellerScores.cancelScore
  const customerServiceScore = SellerStore.sellerScores.customerServiceScore
  const profile = 'profile image'
  function getTextColor(score, good, normal) {
    if (score > good) {
      return ''
    }
    if (score > normal) {
      return 'text-yellow-500'
    }
    return 'text-red-500'
  }

  return (
    <div>
      <div className='mb-40 overflow-y-auto text-white bg-dashboard-sidebar no-scrollbar '>
        <nav className='flex flex-col h-screen select-none'>
          <div className='flex flex-col items-center justify-center mb-10 text-xl font-bold'>
            <div className='pt-10'>
              <Tooltip
                arrow='true'
                theme='light'
                title='🏠 홈 바로가기'
                animation='fade'
                position='bottom'
              >
                <Link exact to='/'>
                  <img
                    alt={profile}
                    src={SellerStore.sellerInfo.sellerImageUri || User}
                    className='w-20 h-20 rounded-full shadow-md'
                  />
                </Link>
              </Tooltip>
            </div>
            <div className='pt-5'>
              <h1>{SellerStore.sellerInfo.sellerName}</h1>
            </div>
            <div />
          </div>
          {/* 매력도/만족도 정보 */}
          <div className='flex flex-col pl-5 mb-10 justify-left text-md'>
            <div className='flex flex-row items-center mb-3'>
              <div>
                <ProductIcon className='mr-2' />
              </div>
              <h3 className='pr-2'>상품 매력도</h3>
              <span>
                {SellerStore.sellerScores?.itemScores?.totalItemScore || 200}
                /300
              </span>
            </div>

            <div className='flex flex-row items-center mb-3'>
              <div>
                <ReviewIcon className='mr-2' />
              </div>
              <h3 className='pr-2'>상품 만족도</h3>
              {SellerStore.sellerScores.reviewScore || 80}
              /100
            </div>
            <div>
              <div className='flex flex-row items-center mb-2'>
                <div>
                  <SpeedIcon className='mr-2' />
                </div>
                <h3 className='pr-2'>배송 만족도</h3>
                <span
                  className={`${getTextColor(shippingScore || 80, 89, 79)}`}
                >
                  {shippingScore || 80}
                </span>
                /100
                {shippingScore <= 80 ? <Ping /> : ''}
              </div>
              <div className='text-sm'>
                {SellerStore.sellerScores?.shippingDetailStats
                  ?.correctShippingRank >= 0 &&
                  SellerStore.sellerScores?.shippingDetailStats
                    ?.correctShippingRank <= 1 && (
                    <div className='flex flex-row'>
                      <div className='mb-3 ml-6 mr-2'>ㄴ 정시 출고완료</div>
                      {SellerStore.sellerScores?.shippingDetailStats
                        ?.correctShippingRank === 0 ? (
                        <div className='font-bold text-red-500'>주의</div>
                      ) : (
                        <div className='font-bold text-yellow-500'>부족</div>
                      )}
                    </div>
                  )}
                {SellerStore.sellerScores?.shippingDetailStats
                  ?.fastShippingRank >= 0 &&
                  SellerStore.sellerScores?.shippingDetailStats
                    ?.fastShippingRank <= 1 && (
                    <div className='flex flex-row'>
                      <div className='mb-3 ml-6 mr-2'>
                        ㄴ 1 영업일 이내 출고
                      </div>
                      {SellerStore.sellerScores?.shippingDetailStats
                        ?.fastShippingRank === 0 ? (
                        <div className='font-bold text-yellow-500'>+ 0점</div>
                      ) : (
                        <div className='font-bold text-yellow-500'>+ 5점</div>
                      )}
                    </div>
                  )}
                {SellerStore.sellerScores?.shippingDetailStats
                  ?.shippingNoticeRank >= 0 &&
                  SellerStore.sellerScores?.shippingDetailStats
                    ?.shippingNoticeRank <= 1 && (
                    <div className='mb-3 ml-6'>
                      <div className='flex flex-row mb-1'>
                        <div className='mr-2'>ㄴ 배송 시간 안내</div>
                        {SellerStore.sellerScores?.shippingDetailStats
                          ?.shippingNoticeRank === 0 ? (
                          <div className='font-bold text-yellow-500'>+ 0점</div>
                        ) : (
                          <div className='font-bold text-yellow-500'>+ 3점</div>
                        )}
                      </div>
                      <Button
                        type='button'
                        size='sm'
                        onClick={() => {
                          history.push('/shippings')
                        }}
                      >
                        배송 만족도 올리기
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            <div className='flex flex-row items-center mb-3'>
              <div>
                <RefundIcon className='mr-2' />
              </div>
              <h3 className='pr-2 '>환불 만족도</h3>
              <span className={`${getTextColor(cancelScore || 80, 89, 79)}`}>
                {cancelScore || 80}
              </span>
              /100
              {cancelScore < 80 ? <Ping /> : ''}
            </div>
            <div className='flex flex-row items-center mb-3'>
              <div>
                <CsIcon className='mr-2' />
              </div>
              <h3 className='pr-2'>CS 만족도</h3>
              <span
                className={`${getTextColor(
                  customerServiceScore || 80,
                  89,
                  79,
                )}`}
              >
                {customerServiceScore || 80}
              </span>
              /100
              {customerServiceScore < 80 ? <Ping /> : ''}
            </div>
          </div>
          {/* Menus 목록 */}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele, idx) => (
            <Menus arrayNum={ele} key={ele?.toString() + idx?.toString()} />
          ))}
          <div className='flex flex-col justify-end flex-1 pb-5 text-gray-400'>
            <Link exact to='/seller-support' key='판매자지원센터 문의하기'>
              <div className='px-5 py-3 hover:bg-gray-600'>
                올웨이즈 개발팀 건의
              </div>
            </Link>
            <button
              type='button'
              className='px-5 py-3 text-left hover:bg-gray-600'
              onClick={() =>
                window.open(
                  'https://alwayz-seller.gitbook.io/sellerguide/dmhN91nCiBbbGpstPtx6/',
                  '_blank',
                )
              }
            >
              <>
                <sup className='text-[11px] text-yellow-400'>NEW</sup>{' '}
                판매자센터 가이드 ↗
              </>
            </button>
            <button
              type='button'
              className='px-5 py-3 text-left hover:bg-gray-600'
              onClick={() =>
                window.open(
                  'https://levitinc.notion.site/ee08e47ee6934a59b8f97176afd4bfa2',
                  '_blank',
                )
              }
            >
              광고 가이드 ↗
            </button>
            <button
              type='button'
              className='px-5 py-3 text-left hover:bg-gray-600'
              onClick={() =>
                window.open(
                  'https://levitinc.notion.site/0bf4d3005b40439db59ad3b32e3d191f',
                  '_blank',
                )
              }
            >
              상품 등록 정책 ↗
            </button>
            <div className='flex flex-row justify-between px-5 pt-5 text-gray-500'>
              <button
                type='button'
                className='text-xs'
                onClick={() =>
                  window.open(
                    'https://placid-hoverfly-3d1.notion.site/9a0d458e0a3e48baa216654a1abfcc59',
                    '_blank',
                  )
                }
              >
                이용약관
              </button>
              <button
                type='button'
                className='text-xs'
                onClick={() =>
                  window.open(
                    'https://levitinc.notion.site/80a85d0e23904e159b47c8cddbee2dac',
                    '_blank',
                  )
                }
              >
                개인정보 수집 및 이용동의
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
})

export default Sidebar
