import moment from 'moment'

export const getRoundedNow = (minutesAhead = 30, roundToMinutes = 30) => {
  const now = moment().add(minutesAhead, 'minutes').startOf('minute')
  return now
    .minute(Math.ceil(now.minute() / roundToMinutes) * roundToMinutes)
    .second(0)
}

export const isDateDisabled = (date, appliedTime, roundedNow) => {
  const targetDate = moment(date).startOf('day')
  if (targetDate.isBefore(roundedNow, 'day')) return true

  return appliedTime.some(({ startDateTime, endDateTime }) => {
    const start = moment(startDateTime).startOf('day')
    const end = moment(endDateTime).startOf('day')
    return targetDate.isBetween(start, end, 'day', '[]')
  })
}

export const getDisabledTimeRanges = (date, appliedTime, roundedNow) => {
  const targetDate = moment(date)
  const disabledRanges = []

  if (targetDate.isSame(roundedNow, 'day')) {
    disabledRanges.push({
      start: moment(targetDate).startOf('day'),
      end: roundedNow,
    })
  }

  appliedTime.forEach(({ startDateTime, endDateTime }) => {
    const start = moment(startDateTime)
    const end = moment(endDateTime)
    if (targetDate.isSame(start, 'day') || targetDate.isSame(end, 'day')) {
      disabledRanges.push({ start, end })
    }
  })

  return disabledRanges
}

export const getDisabledTimes = (current, appliedTime, options = {}) => {
  const { minutesAhead = 30, roundToMinutes = 30 } = options
  if (!current) return {}

  const roundedNow = getRoundedNow(minutesAhead, roundToMinutes)
  const disabledRanges = getDisabledTimeRanges(current, appliedTime, roundedNow)

  const disabledHours = new Set()
  const disabledMinutes = new Map()

  disabledRanges.forEach(({ start, end }) => {
    const startHour = start.hour()
    const endHour = end.hour()
    for (let hour = startHour; hour <= endHour; hour += 1) {
      disabledHours.add(hour)
      if (hour === startHour || hour === endHour) {
        const minutesForHour = disabledMinutes.get(hour) || new Set()
        for (let minute = 0; minute < 60; minute += roundToMinutes) {
          if (
            (hour === startHour && minute < start.minute()) ||
            (hour === endHour && minute <= end.minute())
          ) {
            minutesForHour.add(minute)
          }
        }
        disabledMinutes.set(hour, minutesForHour)
      }
    }
  })

  const isFullyDisabled = disabledHours.size === 24

  return {
    disabledHours: () => Array.from(disabledHours),
    disabledMinutes: (selectedHour) => {
      if (isFullyDisabled) {
        return Array.from(
          { length: 60 / roundToMinutes },
          (_, i) => i * roundToMinutes,
        )
      }
      return Array.from(disabledMinutes.get(selectedHour) || [])
    },
  }
}
