import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Pagination, Navigation, Autoplay } from 'swiper'
import Button from '../atoms/button'
import Log from '../../utils/log'

export default function Slider({ banners }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation
        modules={[Pagination, Navigation, Autoplay]}
        className='rounded-lg shadow-md select-none'
      >
        {banners.map((banner) => (
          <SwiperSlide key={banner._id}>
            <div className='flex items-center justify-center w-full text-lg text-black bg-white h-60 '>
              <Button
                size='none'
                className='w-full'
                appearance='transparent'
                onClick={() => {
                  Log.event(banner.name, banner.name, {
                    click: banner.name,
                  })
                  const newWindow = window.open(banner.externalLink, '_blank')
                  setTimeout(() => {
                    newWindow.postMessage('ThisIsfromSEJINIUS', window.origin)
                  }, 1000)
                }}
              >
                <img alt='banner' src={banner.image} />
              </Button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
