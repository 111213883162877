import React, { useMemo } from 'react'
import { Button, Select, InputNumber, Radio } from 'antd'
import commaNumber from 'comma-number'
import useIsFreeCpmSeller from '../hooks/useIsFreeCpmSeller'

const { Option } = Select

const Stage3BidSettings = ({
  dispatch,
  bidPrice,
  adQuantity,
  adPaymentAmount,
  remainingCredit,
  remainingCreditAfterAd,
  setIsConfirmModalVisible,
  handlePrevStage,
  isRegistering,
  adType,
  selectedItems,
}) => {
  const isFreeCpmTargetSeller = useIsFreeCpmSeller()

  const isFreeAd = adType === 'free'

  const onChange = (e) => {
    dispatch({
      type: 'SET_AD_TYPE',
      payload: e.target.value,
    })
  }

  const MIN_BID_PRICE = 170
  const MAX_BID_PRICE = isFreeAd ? 200 : 1000
  const STEP_BID_PRICE = 10
  // const MIN_AD_BUDGET = 300000
  const MIN_AD_QUANTITY = isFreeAd ? 250000 : 1500000
  // const MAX_AD_QUANTITY = 8000000
  const STEP_AD_QUANTITY = 1500000

  const initialAdQuantity = adQuantity

  // 추가 노출 수량 계산
  const additionalExposurePercentage = useMemo(() => {
    // 3개는 기본, 4개부터 +3%씩, 최대 15%
    const additionalItems = Math.max(0, selectedItems.length - 3)
    return Math.min(additionalItems * 3, 15) // 최대 15%
  }, [selectedItems])

  // 총 노출 수량 계산
  const totalExposureQuantity = useMemo(() => {
    if (additionalExposurePercentage === 0) return adQuantity
    return Math.round(adQuantity * (1 + additionalExposurePercentage / 100))
  }, [adQuantity, additionalExposurePercentage])

  // 추가 노출 수량
  const bonusExposureQuantity = totalExposureQuantity - adQuantity

  return (
    <div className='mb-20 text-slate-700'>
      <div className='flex flex-col items-start flex-1 p-12 mb-5 bg-white shadow-lg rounded-xl'>
        <div className='flex flex-col flex-1 w-full'>
          <div className='mb-12 text-lg font-bold'>
            <p>CPM 광고는 경매 방식으로 진행돼요</p>
            <p>입찰가와 상품 매력도가 함께 반영되어 노출 우선순위가 결정돼요</p>
            {isFreeCpmTargetSeller && (
              <div className='mt-4'>
                <div>
                  <Radio.Group onChange={onChange} value={adType}>
                    <Radio value='paid'>기본 광고</Radio>
                    <Radio value='free'>무료 광고 이벤트</Radio>
                  </Radio.Group>
                </div>
                <div className='mt-2 text-sm font-bold text-slate-600'>
                  * 무료 광고 이벤트는 한 번만 참여 가능하며, 예고 없이 종료될
                  수 있습니다.
                </div>
                <div className='mt-2 text-sm text-slate-600'>
                  * 광고 진행 중 취소하시면 재신청이 불가능합니다.
                </div>
              </div>
            )}
          </div>
          <div className='flex flex-row flex-1 w-full'>
            <div className='grid w-full grid-cols-2 gap-8'>
              <div className='flex flex-col flex-1 w-full'>
                {/* 광고 수량 선택 */}
                <div className='flex flex-col items-start mb-8'>
                  <div className='flex flex-row items-center flex-1 mb-4 text-lg font-bold text-blue-500'>
                    <span className='flex items-center justify-center w-8 h-8 mr-2 bg-blue-100 rounded-full'>
                      1
                    </span>
                    총 광고 수량을 선택해주세요
                  </div>
                  <div className='ml-10'>
                    <div className='flex items-center'>
                      <Select
                        className='w-[200px]'
                        size='large'
                        value={initialAdQuantity}
                        onChange={(value) =>
                          dispatch({
                            type: 'SET_AD_QUANTITY',
                            payload: value,
                          })
                        }
                        disabled={isRegistering || isFreeAd}
                      >
                        {Array.from(
                          { length: 8 },
                          (_, index) => (index + 1) * STEP_AD_QUANTITY,
                        ).map((value) => (
                          <Option key={value} value={value}>
                            {value.toLocaleString()} 회
                          </Option>
                        ))}
                      </Select>

                      {additionalExposurePercentage > 0 && (
                        <div className='ml-4 text-green-600'>
                          <span className='font-bold'>
                            +{bonusExposureQuantity.toLocaleString()}회
                          </span>
                          <span className='ml-1'>
                            (상품 {selectedItems.length}개 선택 혜택: +
                            {additionalExposurePercentage}%)
                          </span>
                        </div>
                      )}
                    </div>

                    {additionalExposurePercentage > 0 && (
                      <div className='mt-2 text-sm font-bold text-green-600'>
                        총 노출 수량: {totalExposureQuantity.toLocaleString()}회
                      </div>
                    )}

                    <div className='mt-2 text-sm font-bold text-slate-600'>
                      *광고 수량은 신청한 광고 제품들의 총 노출 횟수를 의미해요
                    </div>
                    <div className='mt-2 text-sm text-slate-600'>
                      *상품 1개가 1번 노출될 때마다 1회씩 차감돼요
                    </div>
                    <div className='mt-2 text-sm text-slate-600'>
                      *고객 별로 상품이 개별 맞춤되기 때문에 상품마다 노출 횟수
                      차이가 발생할 수 있어요
                    </div>
                    <div className='mt-2 text-sm text-slate-600'>
                      *최소 신청 수량은 {MIN_AD_QUANTITY.toLocaleString()}개
                      입니다.
                    </div>
                  </div>
                </div>
                <div className='flex flex-row items-center flex-1 mb-4 text-lg font-bold text-blue-500'>
                  <span className='flex items-center justify-center w-8 h-8 mr-2 bg-blue-100 rounded-full'>
                    2
                  </span>
                  입찰가를 선택해주세요
                </div>
                <div className='ml-10'>
                  <div>
                    <InputNumber
                      className='w-[200px]'
                      size='large'
                      value={bidPrice * 1000}
                      onChange={(value) => {
                        const roundedValue =
                          Math.round(value / STEP_BID_PRICE) * STEP_BID_PRICE
                        dispatch({
                          type: 'SET_BID_PRICE',
                          payload: roundedValue / 1000,
                        })
                      }}
                      disabled={isRegistering || isFreeAd}
                      min={MIN_BID_PRICE}
                      max={MAX_BID_PRICE}
                      step={STEP_BID_PRICE}
                      precision={0}
                      addonAfter='원/1000회'
                    />
                  </div>
                  <div className='mt-2 text-sm'>
                    <p className='font-bold text-red-600'>
                      *최소 입찰가는 {MIN_BID_PRICE}원/1000회이며, 최대 입찰가는{' '}
                      {MAX_BID_PRICE}원/1000회입니다.
                    </p>
                    <p className='text-sm text-slate-600'>
                      *{STEP_BID_PRICE}원/1000회 단위로 입찰가를 조정할 수
                      있습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-end flex-1 w-full'>
                <div className='grid w-full h-48 grid-cols-2 gap-12 p-8 border border-gray-300 rounded-xl'>
                  <div className='flex flex-col items-end justify-center'>
                    <div className='text-lg text-gray-600'>
                      예상 광고 결제 금액
                    </div>
                    <div className='text-lg text-gray-600'>내 잔여 크레딧</div>
                    <div className='text-lg text-gray-600'>
                      신청 후 남은 크레딧
                    </div>
                  </div>
                  <div className='flex flex-col items-end justify-center text-lg font-bold'>
                    <div className='text-blue-500'>
                      {commaNumber(Math.floor(adPaymentAmount))}원
                    </div>
                    <div>{commaNumber(Math.floor(remainingCredit))}원</div>
                    <div
                      className={
                        remainingCreditAfterAd < 0 ? 'text-red-500' : ''
                      }
                    >
                      {commaNumber(Math.floor(remainingCreditAfterAd))}원
                    </div>
                  </div>
                </div>

                {remainingCreditAfterAd < 0 && (
                  <div className='mt-4 text-red-500'>
                    잔여 크레딧이 부족합니다. 크레딧을 충전하신 후 다시
                    시도해주세요.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-start flex-1 p-12 mb-5 bg-white shadow-lg rounded-xl'>
        <div className='text-lg font-bold'>유의 사항</div>
        <div className='w-[50%]'>
          <p>광고는 신청 후 검수를 거쳐 통과돼요.</p>
          <p>광고 수량이 모두 노출되지 않을 경우 남은 크레딧은 환불돼요. </p>
          <p>
            아래와 같은 상품은 검수 과정에서 반려되거나, 추후 광고 진행이 제재될
            수 있어요.
          </p>
          <p className='mt-2'>* 과장 / 과대 광고, 성인용품 등</p>
        </div>
      </div>
      <div className='flex justify-end w-full gap-5 mt-4'>
        <Button
          className='bg-white w-[150px]'
          size='large'
          onClick={() => handlePrevStage(2)}
          disabled={isRegistering}
        >
          이전
        </Button>
        <Button
          type='primary'
          size='large'
          className='w-[150px]'
          onClick={() => setIsConfirmModalVisible(true)}
          disabled={remainingCreditAfterAd < 0 || isRegistering}
          loading={isRegistering}
        >
          {isRegistering ? '등록 중...' : '광고 등록'}
        </Button>
      </div>
    </div>
  )
}

export default Stage3BidSettings
