import { ExclamationCircleTwoTone } from '@ant-design/icons'

const AutoBiddingNotice = () => {
  return (
    <div className='flex items-start gap-2 p-4 bg-[#F6F6FA] rounded-lg'>
      <ExclamationCircleTwoTone className='text-blue-500 text-xl mt-0.5' />
      <div>
        <div className='font-bold text-gray-900 mb-1'>
          자동 입찰 기능은 최적의 가격으로 입찰을 진행합니다.
        </div>
        <div className='text-gray-600 text-sm'>
          입력해주신 최저 입찰가를 기준으로 가격을 낮췄을 때 최상위 노출 확보가
          가능할 경우 자동으로 가격을 낮춥니다.
        </div>
      </div>
    </div>
  )
}

export default AutoBiddingNotice
