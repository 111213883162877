import React from 'react'

const RowComponent = ({ title, children, className }) => {
  return (
    <div className={`${className} flex flex-row max-w-[660px] justify-start`}>
      <div className='text-lg font-bold min-w-[120px]'>{title}</div>
      <div className='w-full'>{children}</div>
    </div>
  )
}

export default RowComponent
