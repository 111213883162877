import { useEffect, useState } from 'react'
import backendApis from '../../../utils/backendApis'
import formatKotlinDateTimeToDate from '../../../utils/formatKotlinDateTimeToDate'
import moment from 'moment'
import { Button, message, Modal } from 'antd'

const MOCK_BIDDINGS = [
  {
    id: 1,
    title: '상품명이 들어갑니다 상품명이 너무 길면 잘립니다...',
    status: 'ONGOING',
    displayStatus: '상시 노출중',
    stockStatus: '재고량 : 기존 상품 재고',
    biddingType: '고정가 입찰',
    price: 50000,
  },
  {
    id: 2,
    title: '상품명이 들어갑니다 상품명이 너무 길면 잘립니다...',
    status: 'RESERVED',
    displayStatus: '상시 노출중',
    stockStatus: '재고량 : 기존 상품 재고',
    biddingType: '고정가 입찰',
    price: 35000,
  },
  {
    id: 3,
    title: '상품명이 들어갑니다 상품명이 너무 길면 잘립니다...',
    status: 'RESERVED',
    displayStatus: '상시 노출중',
    stockStatus: '재고량 : 기존 상품 재고',
    biddingType: '고정가 입찰',
    price: 35000,
  },
  {
    id: 4,
    title: '상품명이 들어갑니다 상품명이 너무 길면 잘립니다...',
    status: 'RESERVED',
    displayStatus: '상시 노출중',
    stockStatus: '재고량 : 기존 상품 재고',
    biddingType: '고정가 입찰',
    price: 35000,
  },
]

const TABS = [
  { key: 'all', label: '전체' },
  { key: 'ongoing', label: '진행중' },
  { key: 'reserved', label: '예약' },
]

const BiddingStatusSection = ({ skuId }) => {
  const [biddings, setBiddings] = useState([])
  const [reloadToggle, setReloadToggle] = useState(false)
  useEffect(() => {
    const fetchBiddings = async () => {
      const res = await backendApis.getBiddingStatusBySkuId({
        skuId,
      })
      if (res) {
        setBiddings(res)
      }
    }
    if (skuId) {
      fetchBiddings()
    }
  }, [skuId, reloadToggle])
  const [activeTab, setActiveTab] = useState('all')

  const isBiddingActive = (bidding) => {
    console.log(bidding, 'bidding')
    const nowMoment = moment()
    const startMoment = moment(bidding?.bidding_start)
    const endMoment = moment(bidding?.bidding_end)
    // console.log(startMoment, endMoment, bidding, '%%%%%ß')

    return startMoment.isBefore(nowMoment) && endMoment.isAfter(nowMoment)
  }

  const filteredBiddings = biddings.filter((bidding) => {
    if (activeTab === 'all') return true
    if (activeTab === 'ongoing') return isBiddingActive(bidding)
    if (activeTab === 'reserved') return !isBiddingActive(bidding)
    return false
  })

  const convertOnGoingStatus = (status) => {
    if (status === 'spot') return '상시 노출중'
    if (status === 'scheduled') return '예약 노출중'
    return '전체'
  }

  const convertBiddingType = (type) => {
    if (type === 'manual') return '고정가 입찰'
    if (type === 'auto') return '자동 입찰'
    return '전체'
  }

  const formatDate = (date) => {
    const momentDate = moment(date)
    return momentDate.format('YYYY-MM-DD HH:mm')
  }

  const handleDelete = ({ biddingId }) => {
    Modal.confirm({
      title: '입찰 삭제',
      content: '정말로 이 입찰을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk: async () => {
        try {
          const res = await backendApis.deleteSkuCatalogBidding({ biddingId })
          if (res?.status === 200) {
            message.success('입찰 삭제 완료')
          }
          setReloadToggle(!reloadToggle)
        } catch (error) {
          console.error('입찰 삭제 중 오류 발생:', error)
        }
      },
    })
  }

  return (
    <div className='flex flex-row flex-1 w-full gap-4 h-[330px]'>
      <div className='p-4 rounded-lg w-full bg-[#F6F6FA]'>
        <div className='flex flex-col w-full'>
          <div className='flex gap-2 mb-4'>
            {TABS.map((tab) => (
              <button
                key={tab.key}
                type='button'
                onClick={() => setActiveTab(tab.key)}
                className={`px-6 py-1 rounded-lg text-sm font-medium transition-colors
              ${
                activeTab === tab.key
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-gray-600'
              }`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <div className='flex flex-col gap-4 h-[250px] pr-2 overflow-y-auto'>
            {filteredBiddings.map((bidding) => (
              <div
                key={bidding.id}
                className='p-4 bg-white rounded-lg shadow-sm border border-gray-100'
              >
                <div className='flex gap-2 mb-2'>
                  <span className='px-3 py-1 bg-gray-100 text-gray-600 text-xs rounded-full'>
                    {convertOnGoingStatus(bidding.bidding_schedule_type)}
                  </span>
                  <span className='px-3 py-1 bg-gray-100 text-gray-600 text-xs rounded-full'>
                    {bidding.bidding_stock_type === 'origin'
                      ? '재고량: 기존 상품 재고'
                      : bidding.bidding_stock}
                  </span>
                </div>
                <div className='flex flex-col justify-between'>
                  {bidding.bidding_schedule_type !== 'spot' && (
                    <>
                      <span className='text-gray-600'>노출 시간:</span>
                      <span className='text-gray-600'>
                        {formatDate(bidding?.bidding_start)} ~
                      </span>
                      <span className='text-gray-600'>
                        {formatDate(bidding?.bidding_end)}
                      </span>
                    </>
                  )}
                  <span className='text-gray-600'>
                    입찰 유형: {convertBiddingType(bidding.bidding_type)}
                  </span>
                  <span className='text-blue-500 font-bold'>
                    입찰가: {bidding.live_bidding_price?.toLocaleString()}원
                  </span>
                </div>
                <Button
                  className='flex flex-1 w-full mt-3'
                  onClick={() => handleDelete({ biddingId: bidding.id })}
                  // danger
                >
                  삭제
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BiddingStatusSection
