import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import Button from '../components/atoms/button'
import { Tooltip } from 'react-tippy'
import commaNumber from 'comma-number'
import Tutorial4 from '../videos/tutorial4.mp4'
import TutorialModal from '../components/atoms/tutorialModal'
import Log from '../utils/log'

const SellerRankScreen = observer(() => {
  const history = useHistory()
  const [TimeDeal, setTimeDeal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [itemInfo, setItemInfo] = useState({})
  const SellerGmv = SellerStore?.sellerGmvForRank || 0
  const SellerOrder = SellerStore?.sellerTotalOrderForRank || 0
  const SellerRank = SellerStore.sellerRank
  const [sellerRankName, setSellerRankName] = useState()
  const [sellerRankImg, setSellerRankImg] = useState()
  const [itemScoreGoal, setItemScoreGoal] = useState()
  const [gmvGoal, setGmvGoal] = useState()
  const [orderGoal, setOrderGoal] = useState()
  const itemScore = SellerStore.sellerScoreForRank?.itemScore || 0
  const reviewScore = SellerStore.sellerScoreForRank?.reviewScore || 0
  const shippingScore = SellerStore.sellerScoreForRank?.shippingScore || 0
  const cancelScore = SellerStore.sellerScoreForRank?.cancelScore || 0
  const customerServiceScore =
    SellerStore.sellerScoreForRank?.customerServiceScore || 0

  const orderForGoodService = SellerStore?.orderForGoodService || 0

  const rankIdentifier = () => {
    if (SellerRank === 'rookie') {
      setSellerRankName('루키 등급')
    } else if (SellerRank === 'silver') {
      setSellerRankName('실버 등급')
    } else if (SellerRank === 'gold') {
      setSellerRankName('골드 등급')
    } else if (SellerRank === 'platinum') {
      setSellerRankName('플래티넘 등급')
    } else if (SellerRank === 'diamond') {
      setSellerRankName('다이아 등급')
    } else {
      setSellerRankName('루키 등급')
    }
  }

  const sellerItemScoreGoal = () => {
    if (SellerRank === 'rookie') {
      setItemScoreGoal(180)
    } else if (SellerRank === 'silver') {
      setItemScoreGoal(200)
    } else if (SellerRank === 'gold') {
      setItemScoreGoal(220)
    } else if (SellerRank === 'platinum') {
      setItemScoreGoal(230)
    } else if (SellerRank === 'diamond') {
      setItemScoreGoal(230)
    } else {
      setItemScoreGoal(180)
    }
  }

  const sellerGmvGoal = () => {
    if (SellerRank === 'rookie') {
      setGmvGoal(2000000)
    } else if (SellerRank === 'silver') {
      setGmvGoal(8000000)
    } else if (SellerRank === 'gold') {
      setGmvGoal(40000000)
    } else if (SellerRank === 'platinum') {
      setGmvGoal(600000000)
    } else if (SellerRank === 'diamond') {
      setGmvGoal(600000000)
    } else {
      setGmvGoal(2000000)
    }
  }

  const sellerOrderRank = () => {
    if (SellerRank === 'rookie') {
      setOrderGoal(100)
    } else if (SellerRank === 'silver') {
      setOrderGoal(300)
    } else if (SellerRank === 'gold') {
      setOrderGoal(500)
    } else if (SellerRank === 'platinum') {
      setOrderGoal(2000)
    } else if (SellerRank === 'diamond') {
      setOrderGoal(2000)
    } else {
      setOrderGoal(100)
    }
  }

  const rankImg = () => {
    if (SellerRank === 'rookie') {
      setSellerRankImg(
        'https://d22yqrpf3lokox.cloudfront.net/4244263e-86b5-4d6f-87a7-0eed1b9344e8.png',
      )
    } else if (SellerRank === 'silver') {
      setSellerRankImg(
        'https://d22yqrpf3lokox.cloudfront.net/b9c7e639-cbb2-47c0-a272-fc7896bc15cf.png',
      )
    } else if (SellerRank === 'gold') {
      setSellerRankImg(
        'https://d22yqrpf3lokox.cloudfront.net/94801c2d-3e9e-4460-8e1d-cd7486d3b38e.png',
      )
    } else if (SellerRank === 'platinum') {
      setSellerRankImg(
        'https://d22yqrpf3lokox.cloudfront.net/9557b144-2069-46a1-ba86-06941fa42975.png',
      )
    } else if (SellerRank === 'diamond') {
      setSellerRankImg(
        'https://d22yqrpf3lokox.cloudfront.net/c8eec0bd-f402-46bb-915c-024ede4d0b4c.png',
      )
    } else {
      setSellerRankImg(
        'https://d22yqrpf3lokox.cloudfront.net/4244263e-86b5-4d6f-87a7-0eed1b9344e8.png',
      )
    }
  }

  useEffect(() => {
    const loadSellerRankInfo = async () => {
      SellerStore.setIsLoading(true)
      await Promise.all([
        SellerStore.loadSellerTotalOrderForRank(SellerStore.sellerInfo._id),
        SellerStore.loadSellerGmvForRank(SellerStore.sellerInfo._id),
        SellerStore.loadSellerRank(SellerStore.sellerInfo._id),
        SellerStore.loadSellerScoreForRank(SellerStore.sellerInfo._id),
      ])
      SellerStore.setIsLoading(false)
    }

    loadSellerRankInfo()
  }, [])

  useEffect(() => {
    rankIdentifier()
    sellerItemScoreGoal()
    rankImg()
    sellerGmvGoal()
    sellerOrderRank()
  }, [SellerStore.sellerRank])


  // sellerType()
  // {
  //   if (SellerRank === rookie) {
  //     sellerType = 500
  //   } else if (SellerRank === silver) {
  //     sellerType = 550
  //   } else if (SellerRank === gold) {
  //     sellerType = 650
  //   } else if (SellerRank === platium) {
  //     sellerType = 700
  //   } else if (SellerRank === diamond) {
  //     sellerType = 750
  //   } else {
  //     sellerType = 400
  //   }
  // }

  function getBarColor(score, good, normal) {
    if (score > good) {
      return 'bg-sub-500'
    }
    if (score >= normal) {
      return 'bg-sub-400'
    }
    return 'bg-sub-300'
  }

  return (
    <div className='page max-w-7xl'>
      <div className='items-center justify-center '>
        <h1 className='mb-10 text-4xl font-bold'>판매자 등급 관리</h1>
      </div>
      {/* <Link to='/timedeal-register'> */}
      <div className='mb-10' />
      <div className='flex flex-row items-center justify-between mb-5'>
        <div className='flex flex-row mb-5 ml-2 text-xl font-bond' />
        {/* <div>
          <a
            href='https://drive.google.com/u/0/uc?id=1AH0tRDch55OAgOr6nTlLosrTD3pSLePz&export=download'
            target='_blank'
            rel='noreferrer'
          >
            <button
              className='flex flex-row p-3 font-bold text-white rounded-md bg-theme-500 hover:bg-sub-500'
              type='button'
            >
              등급별 혜택 다운로드
            </button>
          </a>
        </div> */}
      </div>
      <div>
        <div className='w-full px-10 py-10 mr-10 border-2 rounded-md'>
          <div className='justify-between'>
            <div className='text-2xl font-bold'>
              {sellerRankName}
              <img
                src={sellerRankImg}
                alt={sellerRankImg}
                className='inline-block object-contain relative bottom-0.5 w-10 h-10'
              />
            </div>
          </div>
          <div className='mb-10 text-s text-sub-400'>
            등급은 매월 1일 업데이트 됩니다
          </div>
          {/* <div className='flex flex-row text-xl'>
            <span className='text-xl '>{'총 상품 노출 점수 '} </span>
            <span className='text-xl text-red-500'>
              {Math.floor(totalscore) || 0}
            </span>{' '}
            점{' '}
          </div>
          <div style={{ height: 10 }} /> */}
          {/* <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`총 상품 노출 점수
               ${Math.floor(totalscore || 400)}/ ${Math.floor(
                totalItemScoreGoal,
              )}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      totalscore,
                      totalItemScoreGoal * 0.8,
                      totalItemScoreGoal * 0.7,
                    )}`}
                    style={{
                      width: `${Math.min(
                        Math.floor(totalscore / (totalItemScoreGoal / 100)),
                        100,
                      )}%`,
                    }}
                  />
                </div>
                {Math.min(
                  Math.floor(totalscore / (totalItemScoreGoal / 100)),
                  100,
                ) === 100 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      className='relative inline-block w-9 h-9 bottom-3'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    등급업까지{' '}
                    {100 - Math.floor(totalscore / (totalItemScoreGoal / 100))}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div> */}

          {/* <div style={{ height: 10 }} /> */}
          <div className='text-xl'>
            <span className='text-xl left-full'>상품 매력도 점수 </span>
            <span className='text-xl text-red-500 right-full'>
              {Math.floor(itemScore)}
            </span>
            점
          </div>
          <div style={{ height: 10 }} />
          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`상품매력도 
              
              ${Math.floor(itemScore || 180)}/ ${Math.floor(itemScoreGoal)}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      itemScore,
                      itemScoreGoal * 0.8,
                      itemScoreGoal * 0.7,
                    )}`}
                    style={{
                      width: `${Math.min(
                        Math.floor(itemScore / (itemScoreGoal / 100)),
                        100,
                      )}%`,
                    }}
                  />
                </div>
                {Math.min(
                  Math.floor(itemScore / (itemScoreGoal / 100)),
                  100,
                ) === 100 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png
                      '
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png
                      '
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    등급업까지{' '}
                    {100 - Math.floor(itemScore / (itemScoreGoal / 100))}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
          <div style={{ height: 7 }} />

          <div className='text-xl'>
            <span className='text-xl left-full'>판매금액 </span>
            <span className='text-xl text-red-500'>
              {commaNumber(SellerGmv)}
            </span>{' '}
            원
          </div>
          <div style={{ height: 10 }} />

          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`판매금액 ${commaNumber(SellerGmv) || 0}/${commaNumber(
                gmvGoal,
              )}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      SellerGmv,
                      gmvGoal * 0.8,
                      gmvGoal * 0.7,
                    )}`}
                    style={{
                      width: `${Math.min(
                        Math.floor(SellerGmv / (gmvGoal / 100)),
                        100,
                      )}%`,
                    }}
                  />
                </div>
                {Math.min(Math.floor(SellerGmv / (gmvGoal / 100)), 100) ===
                  100 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png
                      '
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png
                      '
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    등급업까지 {100 - Math.floor(SellerGmv / (gmvGoal / 100))}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
          <div style={{ height: 7 }} />

          <div className='text-xl'>
            <span className='text-xl left-full'>판매건수 </span>
            <span className='text-xl text-red-500'>
              {commaNumber(SellerOrder)}
            </span>{' '}
            건
          </div>

          <div style={{ height: 10 }} />

          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`판매건수 ${commaNumber(SellerOrder) || 0}/${commaNumber(
                orderGoal,
              )}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row '>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      SellerOrder,
                      orderGoal * 0.8,
                      orderGoal * 0.7,
                    )}`}
                    style={{
                      width: `${Math.min(
                        Math.floor(SellerOrder / (orderGoal / 100)),
                        100,
                      )}%`,
                    }}
                  />
                </div>
                {Math.min(Math.floor(SellerOrder / (orderGoal / 100)), 100) ===
                  100 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png
                      '
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png
                      '
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    등급업까지{' '}
                    {100 - Math.floor(SellerOrder / (orderGoal / 100))}%!
                  </div>
                )}
                <div />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='px-10 py-10 mt-10 border-2 rounded-md'>
        <div className='flex flex-row justify-between '>
          <div className='mb-10 text-2xl font-bold '>등급 올리기</div>
        </div>
        <div style={{ height: 12 }} />
        <table>
          <tr>
            <th scope='col' colSpan={1} className='pb-3 text-xl text-center'>
              추천 순위
            </th>
            <th scope='col' colSpan={1} className='pb-3 text-xl text-center'>
              추천 이유
            </th>
            <th scope='col' colSpan={1} className='pb-3 text-xl text-center'>
              등급 올리기
            </th>
          </tr>
          <tbody className='items-center mt-3'>
            <tr>
              <td className='text-lg text-center'>1순위</td>
              <td className='text-lg text-center'>
                핵심 노출되는 상품은 인터넷 최저가 대비(동일 품질) 25% 이상
                저렴합니다
                <br />
                올웨이즈 최소 가격 기준을 확인하고 가격을 설정해주세요
              </td>
              <td className='text-center'>
                <TutorialModal
                  modalTitle='개인 /팀구매가'
                  videoSource={Tutorial4}
                >
                  <div className='w-48 px-3 py-2 mr-2 text-base font-bold text-white border-b-2 border-r-2 rounded-lg shadow-md border-positive-700 bg-gradient-to-r from-positive-400 to-positive-600 hover:bg-gradient-to-r hover:from-positive-500 hover:to-positive-800'>
                    올웨이즈 가격 기준 확인
                  </div>
                </TutorialModal>
              </td>
            </tr>
            <tr>
              <td className='text-lg text-center'>2순위</td>
              <td className='text-lg text-center'>
                올웨이즈 쿠폰 중 가장 효율이 높은 쿠폰입니다
                <br /> 합배송이 가능하신 판매자에게 유용하니 적극 이용해보세요!
              </td>
              <td className='text-center'>
                {/* <Link to */}
                <Button
                  className='w-48 px-3 py-2 mt-3 mb-3 text-base font-bold text-white bg-gray-500 rounded-md w-1/7'
                  type='button'
                  href='/deals'
                  onClick={async () => {
                    if (SellerStore.loadSellerInfo())
                      Log.event(
                        'bySellerCouponInSellerRank',
                        'bySellerCouponInSellerRank',
                        {
                          click: 'bySellerCouponInSellerRank',
                        },
                      )
                  }}
                >
                  추가 구매 쿠폰 신청
                </Button>
                {/* </Link> */}
              </td>
            </tr>
            <tr>
              <td className='text-lg text-center'>3순위</td>
              <td className='text-lg text-center'>
                후기 10개 이상부터 상품 매력도와 매출이 급격히 올라갑니다
                <br /> 후기 이벤트를 통해 아이템의 신뢰도를 올려주세요
              </td>
              <td className='text-center'>
                {/* <Link to */}
                <Button
                  className='w-48 px-3 py-2 mt-3 mb-3 text-base font-bold text-white bg-gray-500 rounded-md w-1/7'
                  type='button'
                  href='/review-event'
                  onClick={async () => {
                    if (SellerStore.loadSellerInfo())
                      Log.event(
                        'ReviewEventSystemInRank',
                        'ReviewEventSystemInRank',
                        {
                          click: 'ReviewEventSystemInRank',
                        },
                      )
                  }}
                >
                  상품 후기 체험단 신청
                </Button>
                {/* </Link> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className='w-full px-10 py-10 mt-10 mr-10 border-2 rounded-md'>
          <div className='justify-between'>
            <div className='text-2xl font-bold'>
              굿 서비스{' '}
              <img
                src='https://d22yqrpf3lokox.cloudfront.net/c65c5c6c-f471-436e-9d2d-8af8b5bfcfeb.png'
                alt='https://d22yqrpf3lokox.cloudfront.net/c65c5c6c-f471-436e-9d2d-8af8b5bfcfeb.png'
                className='inline-block object-contain relative bottom-2.5 w-10 h-10'
              />
            </div>
          </div>
          <div className='mb-10 text-s text-sub-400'>
            판매활동에 대한 아래 서비스 조건을 모두 만족하는 판매자님께
            부여됩니다.
            <br />
            사용자들이 믿고 구매할 수 있도록 올웨이즈 판매자 정보 영역에
            아이콘이 표기됩니다.
          </div>

          <div className='text-xl'>
            <span className='text-xl '>상품 만족도 </span>
            <span className='text-xl text-red-500'>
              {Math.floor(reviewScore) || 0}
            </span>
            점
          </div>
          <div style={{ height: 10 }} />
          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`상품 만족도 
                 ${Math.floor(reviewScore || 70)}/ ${Math.floor(80)}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      85,
                      80,
                      75,
                    )}`}
                    style={{
                      width: `${Math.min(Math.floor(reviewScore / 0.8), 100)}%`,
                    }}
                  />
                </div>
                {Math.max(reviewScore / 80, 1) > 1 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    달성까지{' '}
                    {reviewScore !== 0
                      ? 100 - Math.floor(100 * (reviewScore / 80))
                      : 100}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div>

          {/* <div style={{ height: 7 }} /> */}
          <div className='mt-2 text-xl'>
            <span className='text-xl '>배송 만족도 </span>
            <span className='text-xl text-red-500'>
              {Math.floor(shippingScore) || 0}
            </span>{' '}
            점{' '}
          </div>
          <div style={{ height: 10 }} />
          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`배송 만족도
               ${Math.floor(shippingScore || 75)}/ ${Math.floor(90)}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      90,
                      85,
                      80,
                    )}`}
                    style={{
                      width: `${Math.min(
                        Math.floor(shippingScore / 0.9),
                        100,
                      )}%`,
                    }}
                  />
                </div>
                {Math.max(shippingScore / 90, 1) > 1 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    달성까지{' '}
                    {shippingScore !== 0
                      ? 100 - Math.floor(100 * (shippingScore / 90))
                      : 100}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
          {/* <div style={{ height: 7 }} /> */}

          <div className='mt-2 text-xl'>
            <span className='text-xl '>취소/환불 만족도 </span>
            <span className='text-xl text-red-500'>
              {Math.floor(cancelScore) || 0}
            </span>{' '}
            점{' '}
          </div>
          <div style={{ height: 10 }} />
          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`취소/환불 만족도
               ${Math.floor(cancelScore || 75)}/ ${Math.floor(90)}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      90,
                      85,
                      80,
                    )}`}
                    style={{
                      width: `${Math.min(Math.floor(cancelScore / 0.9), 100)}%`,
                    }}
                  />
                </div>
                {Math.max(cancelScore / 90, 1) > 1 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    달성까지{' '}
                    {cancelScore !== 0
                      ? 100 - Math.floor(100 * (cancelScore / 90))
                      : 100}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
          {/* <div style={{ height: 7 }} /> */}
          <div className='mt-2 text-xl'>
            <span className='text-xl '>CS 만족도 </span>
            <span className='text-xl text-red-500'>
              {Math.floor(customerServiceScore) || 0}
            </span>{' '}
            점{' '}
          </div>
          <div style={{ height: 10 }} />
          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`CS 만족도
               ${Math.floor(customerServiceScore || 75)}/ ${Math.floor(90)}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      90,
                      85,
                      80,
                    )}`}
                    style={{
                      width: `${Math.min(
                        Math.floor(customerServiceScore / 0.9),
                        100,
                      )}%`,
                    }}
                  />
                </div>

                {Math.max(customerServiceScore / 90, 1) > 1 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    달성까지{' '}
                    {customerServiceScore !== 0
                      ? 100 - Math.floor(100 * (customerServiceScore / 90))
                      : 100}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div>

          {/* <div style={{ height: 7 }} /> */}
          <div className='mt-2 text-xl'>
            <span className='text-xl '>판매건수 </span>
            <span className='text-xl text-red-500'>
              {commaNumber(orderForGoodService)}
            </span>{' '}
            건{' '}
          </div>
          <div style={{ height: 10 }} />
          <div>
            <Tooltip
              arrow='true'
              theme='dark'
              title={`판매건수 ${commaNumber(orderForGoodService) || 0}/${20}`}
              animation='fade'
              position='top'
              className='w-2/3'
            >
              <div className='flex flex-row'>
                <div className='w-2/3 h-6 bg-gray-200 rounded-md'>
                  <div
                    className={`py-0.5 h-6 rounded-md text-xs text-center text-white ${getBarColor(
                      17,
                      14,
                      10,
                    )}`}
                    style={{
                      width: `${Math.min(
                        Math.floor(orderForGoodService / 0.2),
                        100,
                      )}%`,
                    }}
                  />
                </div>
                {Math.max(orderForGoodService / 20, 1) > 1 ? (
                  <div className='ml-3'>
                    <img
                      src='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      alt='https://d22yqrpf3lokox.cloudfront.net/9244144e-4117-480d-aad4-4691f6b11063.png'
                      className='relative inline-block w-8 h-8 bottom-2'
                    />
                  </div>
                ) : (
                  <div className='ml-3'>
                    달성까지{' '}
                    {orderForGoodService !== 0
                      ? 100 - Math.floor(100 * (orderForGoodService / 20))
                      : 100}
                    %!
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
          <li className='mt-5 text-s'>산정 기준 : 최근 1개월 데이터</li>
          <li className='text-s'>굿 서비스 업데이트 주기 : 매월 1일</li>
        </div>
      </div>
      <div className='w-full px-10 py-10 mt-10 border-2 rounded-md'>
        <div className='flex flex-row justify-between w-full'>
          <div className='mb-5 text-2xl font-bold '>등급 산정 기준 안내</div>
        </div>
        <div className='h-10 text-base' />
        <table>
          <thead>
            <tr>
              <th scope='col' colSpan={2} className='text-base text-center '>
                등급표기
              </th>
              <th scope='col' colSpan={3} className='text-base text-center '>
                필수조건
              </th>
            </tr>
            <tr>
              <th className='w-20 text-base text-center'>등급명</th>
              <th className='text-base text-center w-25'>아이콘 노출</th>
              <th className='text-base text-center'>판매자 점수</th>
              <th className='text-base text-center'>판매건수</th>
              <th className='text-base text-center'>판매금액</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-base text-center'>다이아</td>
              <td className='text-center'>
                <img
                  src='https://d22yqrpf3lokox.cloudfront.net/c8eec0bd-f402-46bb-915c-024ede4d0b4c.png'
                  alt='https://d22yqrpf3lokox.cloudfront.net/c8eec0bd-f402-46bb-915c-024ede4d0b4c.png'
                  className='inline-block'
                />
              </td>
              <td className='text-base text-center'>
                상품 매력도: 230점 이상
                <br />굿 서비스 달성
              </td>
              <td className='text-base text-center'>2,000건 이상</td>
              <td className='text-base text-center'>6억원 이상</td>
            </tr>
            <tr>
              <td className='text-base text-center'>플래티넘</td>
              <td className='text-center'>
                <img
                  src='https://d22yqrpf3lokox.cloudfront.net/9557b144-2069-46a1-ba86-06941fa42975.png'
                  alt='https://d22yqrpf3lokox.cloudfront.net/9557b144-2069-46a1-ba86-06941fa42975.png'
                  className='inline-block'
                />
              </td>
              <td className='text-base text-center'>
                상품 매력도: 220점 이상
                <br />굿 서비스 달성
              </td>
              <td className='text-base text-center'>500건 이상</td>
              <td className='text-base text-center'>4천만 이상</td>
            </tr>
            <tr>
              <td className='text-base text-center'>골드</td>
              <td className='text-center'>
                <img
                  src='https://d22yqrpf3lokox.cloudfront.net/94801c2d-3e9e-4460-8e1d-cd7486d3b38e.png'
                  alt='https://d22yqrpf3lokox.cloudfront.net/94801c2d-3e9e-4460-8e1d-cd7486d3b38e.png'
                  className='inline-block'
                />
              </td>
              <td className='text-base text-center'>상품 매력도: 200점 이상</td>
              <td className='text-base text-center'>300건 이상</td>
              <td className='text-base text-center'>800만원 이상</td>
            </tr>
            <tr>
              <td className='text-base text-center'>실버</td>
              <td className='text-center'>
                <img
                  src='https://d22yqrpf3lokox.cloudfront.net/b9c7e639-cbb2-47c0-a272-fc7896bc15cf.png'
                  alt='https://d22yqrpf3lokox.cloudfront.net/b9c7e639-cbb2-47c0-a272-fc7896bc15cf.png'
                  className='inline-block'
                />
              </td>
              <td className='text-base text-center'>상품 매력도: 180점 이상</td>
              <td className='text-base text-center'>100건 이상</td>
              <td className='text-base text-center'>200만원 이상</td>
            </tr>
            <tr>
              <td className='text-base text-center'>루키</td>
              <td className='text-center'>
                <img
                  src='https://d22yqrpf3lokox.cloudfront.net/4244263e-86b5-4d6f-87a7-0eed1b9344e8.png'
                  alt='https://d22yqrpf3lokox.cloudfront.net/4244263e-86b5-4d6f-87a7-0eed1b9344e8.png'
                  className='inline-block'
                />
              </td>
              <td className='text-base text-center'>
                상품 매력도 : 180점 미만
              </td>
              <td className='text-base text-center'>100건 미만</td>
              <td className='text-base text-center'>200만원 미만</td>
            </tr>
          </tbody>
        </table>
        <div style={{ height: 10 }} />
        <li className='mt-5 text-s'>
          산정 기준 : 최근 3개월 누적 데이터(상품 매력도, 굿 서비스는 최근 1개월
          누적 데이터), 구매확정 기준(부정거래, 직권취소 및 제외)
        </li>
        <li className='text-s'>
          등급 업데이트 주기 : 매월 1일 (예) 10월 등급 산정 기준: 7월~9월 총
          3개월 누적 데이터 (월:1일~말일)
        </li>
        <li className='text-s'>
          플래티넘과 다이아 등급은 거래규모 및 굿 서비스 조건까지 충족시
          부여되며, 굿 서비스 조건 불충족시 골드로 부여됩니다
        </li>
        <li className='text-s'>
          골드 등급 이상부터는 아이콘이 앱 내 셀러페이지에 노출됩니다
        </li>
      </div>

      <div style={{ height: 30 }} />
      <div />
    </div>
  )
})

export default SellerRankScreen
