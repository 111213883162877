import React from 'react'
import { FcIdea, FcHighPriority } from 'react-icons/fc'

const AlfarmClickAdDescription = () => {
  return (
    <div className='flex flex-col rounded-2xl p-8 bg-white min-w-[405px] mb-3 '>
      <div className='flex items-center mb-2 text-[22px] font-bold'>
        <div className='mr-2 text-[#007AFF]'>All</div> 모든 등급의 상품 신청
        가능합니다.
      </div>
      <div className='text-[#71737C]'>
        올팜의 핵심 매대에서 100만 고객에게 상품을 노출시키고 판매량을
        올려보세요!
      </div>
      <div className='flex flex-col flex-1 p-5 mt-4 bg-[#F7FBFF] rounded-xl'>
        <span className='flex flex-row items-center flex-1 mb-1 font-bold text-[#007AFF]'>
          <FcIdea className='mr-2' />
          올팜 클릭 광고란?
        </span>
        <ul className='mx-4 list-disc'>
          <li>
            올팜 클릭 광고는 올팜의 핵심 매대의 최상단에 상품을 노출하는
            광고입니다.
          </li>
          <li>
            광고 입찰가는 <span className='font-bold text-blue-500'>100원</span>
            부터이며, 입찰에 성공하면{' '}
            <span className='font-bold text-blue-500'>
              {/* 실제 입찰가보다 저렴한 차순위 입찰가 */}
              입찰가
            </span>
            를 기준으로 차감됩니다. <br />
          </li>
          {/* <li>
            만약 차순위 상품보다 클릭률이 좋은 상품일 경우,
            <span className='font-bold text-blue-500'>
              입찰가는 최대 20%까지 할인
            </span>
            됩니다.
            <br />
            <span className='text-gray-400'>
              (단, 차순위 상품보다 클릭률이 떨어지는 경우, 입찰가는 0~20% 더
              부과될 수 있습니다.)
            </span>
          </li> */}
          <li>
            CPC 과금방식으로 노출수와 상관없이 실제 광고를 클릭했을 때만
            광고비가 차감됩니다.
          </li>
          {/* <div className='mt-2 text-gray-500'>
            [입찰가 계산 예시] <br />
            - A상품: 입찰가 500원, 클릭률 2%
            <br />
            - B상품: 입찰가 100원, 클릭률 1%
            <br />
            A상품이 광고로 노출되며(개인화된 추천에 따라 위치가 변경될 수 있음),
            광고비는 차순위 입찰가인 100원을 기준으로 20% 할인되어 차감됩니다.{' '}
            <br /> ⇒ 차감되는 금액 : 100원 x (1 - 0.2) = 80원
          </div> */}
        </ul>
        <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold text-[#007AFF]'>
          <FcHighPriority className='mr-2' />
          주의사항
        </span>
        <ul className='mx-4 list-disc'>
          <li>
            해외 사업자 쇼핑몰 상품, 성인용 상품 등은 광고 신청후 노출이 제한될
            수 있습니다.
          </li>
          <li>
            오픈 마켓 최저가보다 비싼 상품은 광고 신청후 노출이 제한될 수
            있습니다. (*최저가 정보가 없는 경우 제외)
          </li>
        </ul>
      </div>
      {/* <div className='flex justify-start mt-6'>
              <button
                type='button'
                className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100'
                onClick={() =>
                  window.open(
                    'https://drive.google.com/file/d/1HGF6JxDRekfMUKxfFdcknPs1HpDjAVHi/view?usp=sharing',
                    '_blank',
                  )
                }
              >
                광고 소개서 ↗
              </button>
            </div> */}
    </div>
  )
}

export default AlfarmClickAdDescription
