import React from 'react'
import resize from '../../../../utils/resize'
import { Checkbox } from 'antd'

const CandidateItemCard = ({ itemInfo, isSelected, onSelect }) => {
  const { _id, mainImageUris, itemTitle, teamPurchasePrice } = itemInfo
  const itemImage = mainImageUris[0]

  return (
    <button
      key={_id}
      type='button'
      className={`${
        isSelected ? 'border border-[#BCBDC3]' : 'border border-white'
      } flex flex-row items-center w-full p-2.5 mb-2 rounded-xl hover:bg-gray-100`}
      onClick={onSelect}
    >
      <div className='flex flex-row items-start ml-2'>
        <Checkbox checked={isSelected} className='mt-1 -mr-6' />
        {itemImage && itemImage !== '' ? (
          <img
            alt='itemImage'
            src={resize(itemImage)}
            className='w-[100px] h-[100px] mr-3 rounded-lg border border-[#000000F]'
          />
        ) : (
          <div className='w-[84px] h-[84px] mr-3 rounded-lg border bg-gray-100 border-[#000000F]' />
        )}
      </div>
      <div className='flex flex-col text-left'>
        <div className='mb-0.5 text-base font-semibold'>{itemTitle}</div>
        <div className='mb-0.5 text-lg font-bold'>
          {teamPurchasePrice?.toLocaleString()}원
        </div>
        <div className='text-base text-[#9C9DA4] whitespace-nowrap'>{_id}</div>
      </div>
    </button>
  )
}

export default CandidateItemCard
