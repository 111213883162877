import { Modal, Button, message, Spin } from 'antd'
import React, { useEffect, useState } from 'react'

import SkuCatalogMatchingSection from './skuCatalogMatchingSection'
import SkuCatalogBiddingSection from './SkuCatalogBiddingSection'
import commaNumber from 'comma-number'
import backendApis from '../../utils/backendApis'

const snakeToCamel = (obj) => {
  if (typeof obj !== 'object' || obj === null) return obj

  if (Array.isArray(obj)) {
    return obj.map((item) => snakeToCamel(item))
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/_([a-z])/g, (_, letter) =>
      letter.toUpperCase(),
    )
    const value = obj[key]
    acc[camelKey] = snakeToCamel(value)
    return acc
  }, {})
}

const SkuCatalogBiddingModal = ({
  open,
  onClose,
  skuCatalogId,
  skuCatalogData = {},
  initialStage = 1,
  onSubmit,
}) => {
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [selectedOptionId, setSelectedOptionId] = useState(null)
  const [selectedItemTitle, setSelectedItemTitle] = useState(null)
  const [selectedOptionTitle, setSelectedOptionTitle] = useState(null)
  const [currentOptionPrice, setCurrentOptionPrice] = useState(null)
  const [stage, setStage] = useState(initialStage)
  const [isLoading, setIsLoading] = useState(false)
  const [skuId, setSkuId] = useState(null)
  const parsedSkuCatalogData = snakeToCamel(skuCatalogData)
  const lowestPrice = parsedSkuCatalogData.price

  const handleNext = () => {
    setStage(2)
  }

  const handlePrev = () => {
    setStage(1)
  }

  const reset = () => {
    setSelectedItemId(null)
    setSelectedOptionId(null)
    setSelectedItemTitle(null)
    setSelectedOptionTitle(null)
    setStage(initialStage)
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    setIsLoading(true)
    if (initialStage === 2) {
      const fetchSellerBiddingInfo = async () => {
        const res = await backendApis.getSellerBiddingInfo({
          skuCatalogId,
        })
        if (res.status === 200) {
          if (res.data.itemData) {
            setSelectedItemTitle(res.data.itemData.itemTitle)
            setSelectedOptionTitle(res.data.itemData.optionTitle)
            setSelectedItemId(res.data.itemData.itemId)
            setSelectedOptionId(res.data.itemData.optionId)
            setCurrentOptionPrice(res.data.itemData.skuTeamPurchasePrice)
            setSkuId(res.data.itemData.skuId)
          }
        }
      }
      fetchSellerBiddingInfo()
    }
    setIsLoading(false)
  }, [initialStage])

  if (isLoading) {
    return (
      <div className='fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center flex-col'>
        <div className='text-white mb-2'>입찰 정보를 불러오는 중입니다</div>
        <Spin size='large' />
      </div>
    )
  }

  const renderStage = (stage) => {
    switch (stage) {
      case 1:
        return (
          <SkuCatalogMatchingSection
            skuCatalogData={parsedSkuCatalogData}
            onSelectItemId={({ itemId, itemTitle }) => {
              setSelectedItemId(itemId)
              setSelectedItemTitle(itemTitle)
            }}
            onSelectOptionId={({
              optionId,
              selectedOptionTitle,
              currentOptionPrice,
            }) => {
              setSelectedOptionId(optionId)
              setSelectedOptionTitle(selectedOptionTitle)
              setCurrentOptionPrice(currentOptionPrice)
            }}
            onNext={handleNext}
          />
        )
      case 2:
        return (
          <SkuCatalogBiddingSection
            skuId={skuId}
            skuCatalogData={parsedSkuCatalogData}
            selectedItemTitle={selectedItemTitle}
            selectedOptionTitle={selectedOptionTitle}
            currentOptionPrice={currentOptionPrice}
            selectedItemId={selectedItemId}
            selectedOptionId={selectedOptionId}
            initialStage={initialStage}
            onPrev={handlePrev}
            onClose={handleClose}
            onSubmit={onSubmit}
          />
        )
      default:
        return null
    }
  }

  return (
    <Modal
      title={
        <div className='flex text-lg font-bold items-center justify-start'>
          <span className='mr-4'>
            {stage === 1 ? '상품 매칭 설정하기' : '입찰 설정하기'}
          </span>
          <div className='gap-2 bg-slate-100 rounded-lg p-2 text-sm'>
            <span className='mr-2'>실시간 최저가</span>
            <span className='text-blue-500'>{commaNumber(lowestPrice)}원</span>
          </div>
        </div>
      }
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      destroyOnClose
      footer={null}
      width={1000}
      centered
      style={{ top: 0 }}
    >
      {renderStage(stage)}
    </Modal>
  )
}

export default SkuCatalogBiddingModal
