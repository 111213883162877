import { Image, Button } from 'antd'
import { StarFilled } from '@ant-design/icons'
import commaNumber from 'comma-number'
import BadgeGenerator from '../../utils/badgeGenerator'

const textByInspectionStatus = (inspectionStatus) => {
  if (inspectionStatus === 'pending') {
    return '검수중'
  }
  if (inspectionStatus === 'completed') {
    return '입찰설정'
  }
  return '입찰하기'
}

const getExternalPopularityScoreBadge = (externalPopularityScore) => {
  if (externalPopularityScore >= 100) {
    return {
      text: 'HOT',
      className: 'bg-red-500',
    }
  }
  if (externalPopularityScore >= 50) {
    return {
      text: 'MID',
      className: 'bg-orange-500',
    }
  }
  return {
    text: 'LOW',
    className: 'bg-green-500',
  }
}

const getInspectionStatusBadge = (inspectionStatus) => {
  if (inspectionStatus === 'pending') {
    return {
      text: '검수중',
      className: 'bg-yellow-500',
    }
  }
}

const SkuCatalogCard = ({
  title,
  mainImageUris,
  lowestPrice,
  reviewCount,
  reviewScore,
  externalPopularityScore,
  inspectionStatus,
  biddingCount,
  onDrawerOpen,
  onModalOpen,
}) => {
  const competitionBadgeOption = BadgeGenerator.getCompetitionBadge({
    compRatio: biddingCount,
  })
  const inspectionBadgeOption = BadgeGenerator.getInspectionBadge({
    inspectionStatus,
  })
  const externalPopularityScoreBadge = getExternalPopularityScoreBadge(
    externalPopularityScore,
  )
  const lowestPriceCondition = Boolean(lowestPrice && lowestPrice !== 0)

  return (
    <div className='w-[240px] p-0 cursor-pointer'>
      <div className='space-y-3'>
        {/* 이미지 */}
        <div
          className={`z-10 top-0 right-0 px-2 py-0.5 items-center justify-center flex text-xs text-white rounded-full ${inspectionBadgeOption.className}`}
        >
          {inspectionBadgeOption.text}
        </div>
        <div className='relative aspect-square rounded-lg overflow-hidden'>
          <Image
            src={mainImageUris[0]}
            alt={title}
            className='object-cover w-full h-full'
            preview={false}
            width='100%'
            height='100%'
            style={{
              aspectRatio: '1/1',
            }}
          />
        </div>

        {/* 상품 정보 */}
        <div className='space-y-2'>
          {/* 제목 */}
          <div className='text-md text-gray-800 font-bold line-clamp-2 h-[44px]'>
            {title}
          </div>
          <div className='flex flex-col flex-1 h-full '>
            {/* 가격 */}
            <div className='text-slate-500 h-[28px] text-sm'>
              {lowestPriceCondition &&
                `실시간 최저가 ${commaNumber(lowestPrice)}원`}
            </div>

            {/* 노출량 */}
            <button
              type='button'
              className='w-full p-3 rounded-xl border h-full border-gray-200 text-left'
              onClick={onDrawerOpen}
              aria-label='일일 노출량 상세보기'
            >
              <div className='flex flex-row flex-1 w-full items-center'>
                <div>
                  <div>
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500 font-bold'>
                        노출우선순위
                      </span>
                      <span
                        className={`ml-2 px-2 py-0.5 text-xs text-white rounded-full ${externalPopularityScoreBadge.className}`}
                      >
                        {externalPopularityScoreBadge.text}
                      </span>
                    </div>
                  </div>
                  {/* 경쟁 강도 */}
                  <div
                    aria-hidden='true'
                    onClick={onDrawerOpen}
                    className='flex mt-1 rounded-lg justify-between items-center'
                  >
                    <div className='flex items-center'>
                      <span className='text-sm text-gray-500 font-bold'>
                        경쟁 강도
                      </span>
                      <span
                        className={`ml-2 px-2 py-0.5 text-xs text-white rounded-full ${competitionBadgeOption.className}`}
                      >
                        {competitionBadgeOption.text}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='text-gray-700 flex flex-1 flex-col h-full items-end'>
                  ›
                </div>
              </div>
            </button>

            {/* 리뷰 정보 */}
            <div className='flex items-center ml-1 gap-1 mt-2 text-sm text-gray-500'>
              {reviewCount > 0 ? (
                <div className='h-4 flex flex-row items-center gap-1'>
                  <div className='flex items-center gap-0.5'>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <StarFilled
                        size={24}
                        key={star}
                        className={
                          star <= Math.floor(reviewScore)
                            ? 'text-yellow-400'
                            : 'text-gray-200'
                        }
                      />
                    ))}
                  </div>
                  <span>({reviewCount.toLocaleString()})</span>
                </div>
              ) : (
                <div className='flex text-slate-400 items-center h-4 gap-0.5'>
                  연동 리뷰 없음
                </div>
              )}
            </div>

            {/* 입찰하기 버튼 */}

            <Button
              type='default'
              disabled={inspectionStatus === 'pending'}
              className='w-full h-10 font-bold mt-2 bg-blue-50 border-blue-500 text-blue-500'
              size='large'
              onClick={onModalOpen}
            >
              {textByInspectionStatus(inspectionStatus)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkuCatalogCard
