import React from 'react'
import { Card, Col, Table as AntTable, Typography, Button } from 'antd'
import styled from 'styled-components'
import itemInfoParser from '../../../utils/itemInfoParser'

const { Text } = Typography

const Table = styled(AntTable)`
  width: 100%;
  border-spacing: 0;
  border: 0px solid black;

  thead {
    background-color: #edf0f5;
    font-size: 16px;
  }

  tbody {
    font-size: 14px;
    text-align: center;
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 0px solid black;
    border-right: 0px solid black;
  }
`

function OptionCard({ optionsInfo, selectedOptionId, onSelect = () => {} }) {
  const parsedOptionsInfo = itemInfoParser.optionsInfoParser(optionsInfo)
  const columns = [
    {
      title: '옵션',
      dataIndex: 'optionDetails',
      key: 'optionDetails',
      render: (text, option) => (
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ marginBottom: '4px' }} className='whitespace-normal'>
            {option.option1}
          </Text>
          <Text>{option.option2}</Text>
        </Col>
      ),
    },
    {
      title: '팀구매가',
      dataIndex: 'teamPurchasePrice',
      key: 'teamPurchasePrice',
      render: (text) => (
        <Text style={{ whiteSpace: 'nowrap' }}>
          {`${text.toLocaleString()}원`}
        </Text>
      ),
    },
    {
      title: '옵션ID',
      dataIndex: '_id',
      key: '_id',
      render: (text) => (
        <Text type='secondary' style={{ color: '#8c8c8c' }}>
          {text}
        </Text>
      ),
    },
    {
      title: '',
      key: 'select',
      render: (text, option) => (
        <Button
          type='primary'
          size='small'
          style={{
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
            color: '#fff',
          }}
          onClick={() => onSelect(option._id)}
        >
          선택
        </Button>
      ),
    },
  ]

  return (
    <Card
      style={{
        width: '100%',
      }}
    >
      <Table
        columns={columns}
        dataSource={parsedOptionsInfo}
        rowKey={(option) => option.optionId}
        rowClassName={(option) =>
          selectedOptionId?.toString() === option?._id?.toString()
            ? 'ant-table-row-selected'
            : ''
        }
        pagination={false}
        size='small'
      />
    </Card>
  )
}

export default OptionCard
